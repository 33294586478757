import React, { useRef } from "react";
import { Helmet } from "react-helmet";
import "../../Assets/css/careerDetails.css";
import { Container, Row, Col, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import { useParams } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";

const CareerDetails = () => {
  const { position } = useParams();
  const captchaRef = useRef(null);
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [file, setFile] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  const [careerlist, setCareerlist] = React.useState([]);
  React.useEffect(() => {
    GetCareerlist(position);
  }, []);
  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const GetCareerlist = (id) => {
    axios
      .get(`${baseURL}/career/career_detail?id=${id}`)
      .then((career) => {
        if (career.data.status == 1) {
          setCareerlist(career.data.data);
        } else {
          swal(career.data.message, { icon: "warning" });
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
        }
      });
  };
  const SubmitApplication = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    setLoad(true);
    const formData = new FormData();
    formData.append("full_name", `${fname} ${lname}`);
    formData.append("email", email);
    formData.append("phone", `+${phone}`);
    formData.append("cover_letter", message);
    formData.append("job_id", String(localStorage.getItem("Job_id")));
    formData.append("file", file);
    axios
      .post(`${baseURL}/career/send_job_request`, formData, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          swal(res.data.message, { icon: "success" });
          setFname("");
          setLname("");
          setEmail("");
          setPhone("");
          setMessage("");
          setFile("");
          document.getElementById("myInputFileID").value = null;
          setLoad(false);
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
          setLoad(false);
        }
      });
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`Belgium Webnet - ${careerlist[1]}`}</title>
        <meta name="description" content=""></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_careersdetails_section">
        <div className="bw_all_banner">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1 className="bw_banner_heading  bw_banner_careerdetails_heading">
                  {careerlist[1]}
                </h1>
                <p className="bw_banner_sub_heading">
                Become a part of Belgium Webnet where work and fun go hand in hand.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_carrersDetails_contain_box">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <p dangerouslySetInnerHTML={{ __html: careerlist[2] }} />
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_careerDetails_form_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <div
                  className="bw_contact_form_outer_box mb-5"
                >
                  <h2>Apply For This Position</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitApplication}
                    >
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={fname}
                              onChange={(e) => setFname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lname}
                              onChange={(e) => setLname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email address*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number*</Form.Label>
                            <PhoneInput
                            disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={(e) => setPhone(e)}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Cover Letter *</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Message*"
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Upload CV/Resume *</Form.Label>
                            <Form.Control
                              id="myInputFileID"
                              type="file"
                              onChange={(e) => setFile(e.target.files[0])}
                            />
                            <Form.Text id="passwordHelpBlock" muted>
                              Allowed Type(s): .pdf, .doc, .docx
                            </Form.Text>
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}>
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(CareerDetails);
