

import React from "react";
import "../../Assets/css/services.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Image } from "react-bootstrap";
import "react-phone-input-2/lib/style.css";
import EmialMarketImg from "../../Assets/images/email-marketing-img/emial-market-img.png";


const EmailMarketingStaticPage = () => {



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                Trusted Email Marketing Company For Targeted Campaign Success
                </title>
                <meta
                    name="description"
                    content="Get into your customer's inboxes with details about sales & promotions of your jewelry business with an email marketing company like us - Belgium WebNet."
                ></meta>
                <meta name="keywords" content="email marketing campany "></meta>
            </Helmet>
            <section className="bw_services_section_main">
                <div className="bw_all_banner bw_banner_pricing">
                    <Container className="bw_custome_container">
                        <Row className="w-100 m-auto">
                            <Col>
                                <h1 className="bw_banner_heading">Email Marketing</h1>
                                <p className="bw_banner_sub_heading">
                                    Reconnect with your existing customers and attract new ones with our email marketing services.
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="bw_sercices_image_section bw_shopify_development_section">
                    <Container className="bw_custome_container">
                        <Row className="w-100 m-auto">
                            <Col sm={12} md={6} lg={6} className="bw_sevices_left_text_box">
                                <div
                                    className="text-left"
                                >
                                    <h2>Email Marketing</h2>
                                    <p>
                                        Email marketing has emerged as the most effective digital marketing tactic for generating
                                        leads and converting them into potential customers. For jewelers, it presents an opportunity
                                        to connect with their customers and promote their services and products repeatedly.
                                    </p>
                                    <p>In today's world, email has become an indispensable part of our daily lives. Its widespread
                                        usage and increasing global relevance have made email marketing more critical than ever
                                        before. Jewelers can leverage this medium not just to boost sales but also to gain recognition
                                        across the industry. Through giveaways, discounts, customer surveys, event invitations, and
                                        more, email marketing can be a key driver of success.</p>
                                    <p>Belgium WebNet's email marketing service empowers you to create and send professional-
                                        looking emails that engage your subscribers and encourage recurring business.</p>
                                    <p>Whether you aim to expand your email list, increase sales, or enhance brand awareness,
                                        we have tailored solutions to meet your unique needs and goals. Ready to take your
                                        email marketing to the next level? Sign up for our email marketing services today and
                                        unlock the most effective strategy to move your email contacts up the sales funnel.</p>
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} className="bw_sevices_right_text_box">
                                <Image
                                    src={EmialMarketImg}
                                    alt="Shopify Dewelopment Image"
                                    className="img-fluid"
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default React.memo(EmailMarketingStaticPage);
