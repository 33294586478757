import React, { useRef, useState } from "react";
import "../../Assets/css/services.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form } from "react-bootstrap";
import NewBranding from "../../Components/Front/NewBranding.js";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import TogetherImg from "../../Assets/images/home/together-img.jpg";
import axios from "axios";
import { baseURL, postHeader } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { LazyLoadImage } from "react-lazy-load-image-component";
import Checkbox from '@mui/material/Checkbox';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

// import ServicesImg1 from '../../Assets/images/services-img/webdev.png'
// import ServicesImg2 from '../../Assets/images/services-img/ux.png'
// import ServicesImg3 from '../../Assets/images/services-img/shopify.png'
// import ServicesImg4 from '../../Assets/images/services-img/smm.png'
// import ServicesImg5 from '../../Assets/images/services-img/seo.png'
// import ServicesImg6 from '../../Assets/images/services-img/email-marketing.png'
import { useHistory } from "react-router-dom";

const Services = () => {
  const history = useHistory();
  const captchaRef = useRef(null);
  // const [fname, setFname] = React.useState("");
  // const [lname, setLname] = React.useState("");
  // const [email, setEmail] = React.useState("");
  // const [phone, setPhone] = React.useState("");
  // const [phoneError, setPhoneError] = React.useState("");
  // const [message, setMessage] = React.useState("");
  // const [load, setLoad] = React.useState(false);
  // const [captcha, setCaptcha] = React.useState(true);
  const [selectedDate, setSelectedDate] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [business, setBusiness] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [time, setTime] = React.useState("10:00");
  const [timefeild, setTimefeild] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [timezone, setTimezone] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  const [services, setServices] = useState([]);
  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const selectService = (val) => {
    if(services.includes(val)){
     var arr = services.filter(value => value != val);
     setServices(arr);
    }else{
     var arr = services.push(val);
    }
}
  const SubmitServicedetails = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    if ( phone.length>7) {
      const data = {
        first_name: firstname,
          last_name: lastname,
          email: email,
          phone: phone.length ? `+${phone}` : "",
          website: website,
          company_name: business,
          services: services
      };
    setLoad(true);
    axios
      .post(`${baseURL}/service/send_service_request`, data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          swal(res.data.message, { icon: "success" });
          // setFname("");
          // setLname("");
          // setPhone("");
          // setEmail("");
          // setMessage("");
          // setLoad(false);
          // setSelectedDate("");
            setFirstname("");
            setLastname("");
            setPhone("");
            setEmail("");
            setMessage("");
            setTime("10:00");
            setTimefeild("");
            setTimezone("");
            setBusiness("");
            setWebsite("");
            setLoad(false);
            history.push('./thankyou')
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
          setLoad(false);
        }
      });}
      else {
        setPhoneError('Please Enter Valid Number');
      }
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Web & Digital Services For Jewelers | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Our web and digital marketing services can help jewelry businesses reach out to their potential target customers. Book an appointment with our experts now!"
        ></meta>
        <meta name="keywords" content="web service, digital marketing service "></meta>
      </Helmet>
      <section className="bw_services_section_main">
        <div className="bw_all_banner bw_banner_pricing">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1 className="bw_banner_heading">Services</h1>
                <p className="bw_banner_sub_heading">
                  Let’s Build A Better Brand Together
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_sercices_image_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col sm={12} md={6} lg={6} className="bw_sevices_left_text_box">
                <div
                  className="text-left bw_servics_textyyy"
                >
                  <h2>We Help You Grow Your Business</h2>
                  <p>
                    We are proud to be a leading digital marketing and web development company catering to jewelers. With our extensive experience and industry expertise; we have emerged as a trusted partner for businesses throughout the USA. We understand the unique challenges and requirements of this industry, allowing us to deliver tailored solutions that yield exceptional results.
                  </p>
                  <p>Our unwavering commitment to delivering unparalleled services and achieving tangible outcomes has made us the preferred choice for jewelers, seeking to elevate their online presence. Our satisfied clients serve as enthusiastic advocates, spreading the word about our remarkable services. Their referrals have played a pivotal role in converting potential clients into long-term partners, a testament to the exceptional quality and positive impact we offer.</p>
                  <p>From captivating website design to effective Search engine optimization, engaging social media management, and comprehensive digital marketing strategies, we have all your needs covered. Our team of experts seamlessly blends technical proficiency with creative thinking to develop solutions that perfectly align with your business goals. Stay ahead of the competition with our industry insights, cutting-edge solutions, and state-of-the-art technologies.</p>
                  <p><span onClick={() => history.push("/contacts")}>Contact us</span> today to explore how we can elevate your online presence.</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className="bw_sevices_right_text_box bw_img_align_midal d-flex justify-content-center align-items-center">
                <LazyLoadImage
                  src={TogetherImg}
                  alt="Services Image"
                  className="img-fluid"
                />
              </Col>
            </Row>
          </Container>
        </div>


        <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main bw_service_branding_main pt-0">
          <NewBranding />
        </section>

        <div className="bw_services_form_section">
          <Container>
            <Row className="w-100 m-auto">
              <Col>
                <div
                  className="bw_contact_form_outer_box"
                >
                  <h2>Let's Book An Appointment</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitServicedetails}
                    >
                     <Row>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name*</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={business}
                              onChange={(e) => setBusiness(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number</Form.Label>
                            <PhoneInput
                            disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={setPhone}
                              onlyCountries={[
                                "us",
                                "cn",
                                "in",
                                "au",
                                "jp",
                                "hk",
                                "kw",
                                "gb",
                                "es",
                              ]}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                console.log('value',value.length);
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                              
                            />
                            {!phoneError.length?'':<p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                          </Form.Group>
                        </Col>
                        
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        {/* <Col sm={12} md={4} lg={4}>
                          <Form.Label>Date</Form.Label>
                          <Form.Control
                            type="date"
                            timefo
                            placeholder="Pick your date"
                            value={selectedDate}
                            onChange={(e) => setSelectedDate(e.target.value)}
                            min={disablePastDate()}
                            required
                            className="mb-3"
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <Form.Label>Time</Form.Label>
                          <TimePicker
                            start="10:00"
                            end="21:00"
                            placeholder="Your Preferred Time For The Call*"
                            step={30}
                            value={timefeild}
                            onChange={(e) => {
                              setTime(secondsToHms(e));
                              setTimefeild(e);
                            }}
                            className="mb-3"
                            required
                          />
                        </Col>
                        <Col sm={12} md={4} lg={4}>
                          <Form.Label>Timezone</Form.Label>
                          <TimezoneSelect
                            value={timezone}
                            onChange={setTimezone}
                            className="mb-3 bw_time_zone"
                          />
                        </Col> */}
                        {/* <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Message*"
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                        <Col sm={12} md={12} lg={12}>
                        
  <Form.Label>Services</Form.Label>
      <FormGroup aria-label="position" row className="mb-3">
        <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
         <FormControlLabel
          value="end"
          label="Website Design & Development"
          control={<Checkbox  />}
          labelPlacement="end"
          className="bwn_consultation_form_checkbox"
          onChange={()=> selectService("Website Design & Development")}
        />
        </Col>
     
       
           <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
             <FormControlLabel
          value="end"
          control={<Checkbox  />}
          label="Shopify Development"
          labelPlacement="end"
          className="bwn_consultation_form_checkbox"
            onChange={()=> selectService("Shopify Development")}
        />
           </Col>
       
           <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
            <FormControlLabel
          value="end"
          control={<Checkbox  />}
          label="Social Media Marketing"
          labelPlacement="end"
          className="bwn_consultation_form_checkbox"
            onChange={()=> selectService("Social Media Marketing")}
        />
           </Col>
        
           <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
             <FormControlLabel
          value="end"
          control={<Checkbox  />}
          label="Search Engine Optimization"
          labelPlacement="end"
          className="bwn_consultation_form_checkbox"
            onChange={()=> selectService("Search Engine Optimization")}
        />
           </Col>
       
           <Col sm={12} md={6} lg={6} xl={4} className="pl-0">
  <FormControlLabel
          value="end"
          control={<Checkbox  />}
          label="Email Marketing"
          labelPlacement="end"
          className="bwn_consultation_form_checkbox"
            onChange={()=> selectService("Email Marketing")}
        />
           </Col>
      
      </FormGroup>
 
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}>
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(Services);
