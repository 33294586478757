const countries = {
    "type": "FeatureCollection",
    "features": [
      {
        "type": "Feature",
        "properties": {
          "POP_EST": 54841552,
          "ECONOMY": "5. Emerging region: G20",
          "INCOME_GRP": "3. Upper middle income",
          "ADMIN": "South Africa",
          "ISO_A2": "ZA",
          "ISO_A3": "ZAF",
          "TINY": -99,
          "HOMEPART": 1,
          "MIN_ZOOM": 0,
          "MIN_LABEL": 1.7,
          "MAX_LABEL": 6.7
        },
        "bbox": [16.344977, -34.819166, 32.83012, -22.091313],
        "geometry": {
          "type": "Polygon",
          "coordinates": [
            [
              [19.895768, -24.76779],
              [20.165726, -24.917962],
              [20.758609, -25.868136],
              [20.66647, -26.477453],
              [20.889609, -26.828543],
              [21.605896, -26.726534],
              [22.105969, -26.280256],
              [22.579532, -25.979448],
              [22.824271, -25.500459],
              [23.312097, -25.26869],
              [23.73357, -25.390129],
              [24.211267, -25.670216],
              [25.025171, -25.71967],
              [25.664666, -25.486816],
              [25.765849, -25.174845],
              [25.941652, -24.696373],
              [26.485753, -24.616327],
              [26.786407, -24.240691],
              [27.11941, -23.574323],
              [28.017236, -22.827754],
              [29.432188, -22.091313],
              [29.839037, -22.102216],
              [30.322883, -22.271612],
              [30.659865, -22.151567],
              [31.191409, -22.25151],
              [31.670398, -23.658969],
              [31.930589, -24.369417],
              [31.752408, -25.484284],
              [31.837778, -25.843332],
              [31.333158, -25.660191],
              [31.04408, -25.731452],
              [30.949667, -26.022649],
              [30.676609, -26.398078],
              [30.685962, -26.743845],
              [31.282773, -27.285879],
              [31.86806, -27.177927],
              [32.071665, -26.73382],
              [32.83012, -26.742192],
              [32.580265, -27.470158],
              [32.462133, -28.301011],
              [32.203389, -28.752405],
              [31.521001, -29.257387],
              [31.325561, -29.401978],
              [30.901763, -29.909957],
              [30.622813, -30.423776],
              [30.055716, -31.140269],
              [28.925553, -32.172041],
              [28.219756, -32.771953],
              [27.464608, -33.226964],
              [26.419452, -33.61495],
              [25.909664, -33.66704],
              [25.780628, -33.944646],
              [25.172862, -33.796851],
              [24.677853, -33.987176],
              [23.594043, -33.794474],
              [22.988189, -33.916431],
              [22.574157, -33.864083],
              [21.542799, -34.258839],
              [20.689053, -34.417175],
              [20.071261, -34.795137],
              [19.616405, -34.819166],
              [19.193278, -34.462599],
              [18.855315, -34.444306],
              [18.424643, -33.997873],
              [18.377411, -34.136521],
              [18.244499, -33.867752],
              [18.25008, -33.281431],
              [17.92519, -32.611291],
              [18.24791, -32.429131],
              [18.221762, -31.661633],
              [17.566918, -30.725721],
              [17.064416, -29.878641],
              [17.062918, -29.875954],
              [16.344977, -28.576705],
              [16.824017, -28.082162],
              [17.218929, -28.355943],
              [17.387497, -28.783514],
              [17.836152, -28.856378],
              [18.464899, -29.045462],
              [19.002127, -28.972443],
              [19.894734, -28.461105],
              [19.895768, -24.76779]
            ],
            [
              [28.978263, -28.955597],
              [28.5417, -28.647502],
              [28.074338, -28.851469],
              [27.532511, -29.242711],
              [26.999262, -29.875954],
              [27.749397, -30.645106],
              [28.107205, -30.545732],
              [28.291069, -30.226217],
              [28.8484, -30.070051],
              [29.018415, -29.743766],
              [29.325166, -29.257387],
              [28.978263, -28.955597]
            ]
          ]
        }
      },
      {
        "type": "Feature",
        "properties": {
          "POP_EST": 1379302771,
          "ECONOMY": "3. Emerging region: BRIC",
          "INCOME_GRP": "3. Upper middle income",
          "ADMIN": "Hong Kong",
          "ISO_A2": "CN",
          "ISO_A3": "CHN",
          "TINY": -99,
          "HOMEPART": 1,
          "MIN_ZOOM": 0,
          "MIN_LABEL": 1.7,
          "MAX_LABEL": 5.7
        },
        "bbox": [73.675379, 18.197701, 135.026311, 53.4588],
        "geometry": {
          "type": "MultiPolygon",
          "coordinates": [
            [
              [
                [114.763827, 22.668074],
                [114.152547, 22.22376],
                [113.80678, 22.94834],
                // [113.819955, 28.332859],
                [113.241078, 22.941367],
                [111.843592, 21.850494],
              ]
            ],
          ]
        }
      },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 23232413,
              "ECONOMY": "2. Developed region: nonG7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "Australia",
              "ISO_A2": "AU",
              "ISO_A3": "AUS",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 1.7,
              "MAX_LABEL": 5.7
            },
            "bbox": [113.338953, -43.634597, 153.569469, -10.668186],
            "geometry": {
              "type": "MultiPolygon",
              "coordinates": [
                [
                  [
                    [145.397978, -40.792549],
                    [146.364121, -41.137695],
                    [146.908584, -41.000546],
                    [147.689259, -40.808258],
                    [148.289068, -40.875438],
                    [148.359865, -42.062445],
                    [148.017301, -42.407024],
                    [147.914052, -43.211522],
                    [147.564564, -42.937689],
                    [146.870343, -43.634597],
                    [146.663327, -43.580854],
                    [146.048378, -43.549745],
                    [145.43193, -42.693776],
                    [145.29509, -42.03361],
                    [144.718071, -41.162552],
                    [144.743755, -40.703975],
                    [145.397978, -40.792549]
                  ]
                ],
                [
                  [
                    [143.561811, -13.763656],
                    [143.922099, -14.548311],
                    [144.563714, -14.171176],
                    [144.894908, -14.594458],
                    [145.374724, -14.984976],
                    [145.271991, -15.428205],
                    [145.48526, -16.285672],
                    [145.637033, -16.784918],
                    [145.888904, -16.906926],
                    [146.160309, -17.761655],
                    [146.063674, -18.280073],
                    [146.387478, -18.958274],
                    [147.471082, -19.480723],
                    [148.177602, -19.955939],
                    [148.848414, -20.39121],
                    [148.717465, -20.633469],
                    [149.28942, -21.260511],
                    [149.678337, -22.342512],
                    [150.077382, -22.122784],
                    [150.482939, -22.556142],
                    [150.727265, -22.402405],
                    [150.899554, -23.462237],
                    [151.609175, -24.076256],
                    [152.07354, -24.457887],
                    [152.855197, -25.267501],
                    [153.136162, -26.071173],
                    [153.161949, -26.641319],
                    [153.092909, -27.2603],
                    [153.569469, -28.110067],
                    [153.512108, -28.995077],
                    [153.339095, -29.458202],
                    [153.069241, -30.35024],
                    [153.089602, -30.923642],
                    [152.891578, -31.640446],
                    [152.450002, -32.550003],
                    [151.709117, -33.041342],
                    [151.343972, -33.816023],
                    [151.010555, -34.31036],
                    [150.714139, -35.17346],
                    [150.32822, -35.671879],
                    [150.075212, -36.420206],
                    [149.946124, -37.109052],
                    [149.997284, -37.425261],
                    [149.423882, -37.772681],
                    [148.304622, -37.809061],
                    [147.381733, -38.219217],
                    [146.922123, -38.606532],
                    [146.317922, -39.035757],
                    [145.489652, -38.593768],
                    [144.876976, -38.417448],
                    [145.032212, -37.896188],
                    [144.485682, -38.085324],
                    [143.609974, -38.809465],
                    [142.745427, -38.538268],
                    [142.17833, -38.380034],
                    [141.606582, -38.308514],
                    [140.638579, -38.019333],
                    [139.992158, -37.402936],
                    [139.806588, -36.643603],
                    [139.574148, -36.138362],
                    [139.082808, -35.732754],
                    [138.120748, -35.612296],
                    [138.449462, -35.127261],
                    [138.207564, -34.384723],
                    [137.71917, -35.076825],
                    [136.829406, -35.260535],
                    [137.352371, -34.707339],
                    [137.503886, -34.130268],
                    [137.890116, -33.640479],
                    [137.810328, -32.900007],
                    [136.996837, -33.752771],
                    [136.372069, -34.094766],
                    [135.989043, -34.890118],
                    [135.208213, -34.47867],
                    [135.239218, -33.947953],
                    [134.613417, -33.222778],
                    [134.085904, -32.848072],
                    [134.273903, -32.617234],
                    [132.990777, -32.011224],
                    [132.288081, -31.982647],
                    [131.326331, -31.495803],
                    [129.535794, -31.590423],
                    [128.240938, -31.948489],
                    [127.102867, -32.282267],
                    [126.148714, -32.215966],
                    [125.088623, -32.728751],
                    [124.221648, -32.959487],
                    [124.028947, -33.483847],
                    [123.659667, -33.890179],
                    [122.811036, -33.914467],
                    [122.183064, -34.003402],
                    [121.299191, -33.821036],
                    [120.580268, -33.930177],
                    [119.893695, -33.976065],
                    [119.298899, -34.509366],
                    [119.007341, -34.464149],
                    [118.505718, -34.746819],
                    [118.024972, -35.064733],
                    [117.295507, -35.025459],
                    [116.625109, -35.025097],
                    [115.564347, -34.386428],
                    [115.026809, -34.196517],
                    [115.048616, -33.623425],
                    [115.545123, -33.487258],
                    [115.714674, -33.259572],
                    [115.679379, -32.900369],
                    [115.801645, -32.205062],
                    [115.689611, -31.612437],
                    [115.160909, -30.601594],
                    [114.997043, -30.030725],
                    [115.040038, -29.461095],
                    [114.641974, -28.810231],
                    [114.616498, -28.516399],
                    [114.173579, -28.118077],
                    [114.048884, -27.334765],
                    [113.477498, -26.543134],
                    [113.338953, -26.116545],
                    [113.778358, -26.549025],
                    [113.440962, -25.621278],
                    [113.936901, -25.911235],
                    [114.232852, -26.298446],
                    [114.216161, -25.786281],
                    [113.721255, -24.998939],
                    [113.625344, -24.683971],
                    [113.393523, -24.384764],
                    [113.502044, -23.80635],
                    [113.706993, -23.560215],
                    [113.843418, -23.059987],
                    [113.736552, -22.475475],
                    [114.149756, -21.755881],
                    [114.225307, -22.517488],
                    [114.647762, -21.82952],
                    [115.460167, -21.495173],
                    [115.947373, -21.068688],
                    [116.711615, -20.701682],
                    [117.166316, -20.623599],
                    [117.441545, -20.746899],
                    [118.229559, -20.374208],
                    [118.836085, -20.263311],
                    [118.987807, -20.044203],
                    [119.252494, -19.952942],
                    [119.805225, -19.976506],
                    [120.85622, -19.683708],
                    [121.399856, -19.239756],
                    [121.655138, -18.705318],
                    [122.241665, -18.197649],
                    [122.286624, -17.798603],
                    [122.312772, -17.254967],
                    [123.012574, -16.4052],
                    [123.433789, -17.268558],
                    [123.859345, -17.069035],
                    [123.503242, -16.596506],
                    [123.817073, -16.111316],
                    [124.258287, -16.327944],
                    [124.379726, -15.56706],
                    [124.926153, -15.0751],
                    [125.167275, -14.680396],
                    [125.670087, -14.51007],
                    [125.685796, -14.230656],
                    [126.125149, -14.347341],
                    [126.142823, -14.095987],
                    [126.582589, -13.952791],
                    [127.065867, -13.817968],
                    [127.804633, -14.276906],
                    [128.35969, -14.86917],
                    [128.985543, -14.875991],
                    [129.621473, -14.969784],
                    [129.4096, -14.42067],
                    [129.888641, -13.618703],
                    [130.339466, -13.357376],
                    [130.183506, -13.10752],
                    [130.617795, -12.536392],
                    [131.223495, -12.183649],
                    [131.735091, -12.302453],
                    [132.575298, -12.114041],
                    [132.557212, -11.603012],
                    [131.824698, -11.273782],
                    [132.357224, -11.128519],
                    [133.019561, -11.376411],
                    [133.550846, -11.786515],
                    [134.393068, -12.042365],
                    [134.678632, -11.941183],
                    [135.298491, -12.248606],
                    [135.882693, -11.962267],
                    [136.258381, -12.049342],
                    [136.492475, -11.857209],
                    [136.95162, -12.351959],
                    [136.685125, -12.887223],
                    [136.305407, -13.29123],
                    [135.961758, -13.324509],
                    [136.077617, -13.724278],
                    [135.783836, -14.223989],
                    [135.428664, -14.715432],
                    [135.500184, -14.997741],
                    [136.295175, -15.550265],
                    [137.06536, -15.870762],
                    [137.580471, -16.215082],
                    [138.303217, -16.807604],
                    [138.585164, -16.806622],
                    [139.108543, -17.062679],
                    [139.260575, -17.371601],
                    [140.215245, -17.710805],
                    [140.875463, -17.369069],
                    [141.07111, -16.832047],
                    [141.274095, -16.38887],
                    [141.398222, -15.840532],
                    [141.702183, -15.044921],
                    [141.56338, -14.561333],
                    [141.63552, -14.270395],
                    [141.519869, -13.698078],
                    [141.65092, -12.944688],
                    [141.842691, -12.741548],
                    [141.68699, -12.407614],
                    [141.928629, -11.877466],
                    [142.118488, -11.328042],
                    [142.143706, -11.042737],
                    [142.51526, -10.668186],
                    [142.79731, -11.157355],
                    [142.866763, -11.784707],
                    [143.115947, -11.90563],
                    [143.158632, -12.325656],
                    [143.522124, -12.834358],
                    [143.597158, -13.400422],
                    [143.561811, -13.763656]
                  ]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 6072475,
              "ECONOMY": "6. Developing region",
              "INCOME_GRP": "2. High income: nonOECD",
              "ADMIN": "UAE",
              "ISO_A2": "AE",
              "ISO_A3": "ARE",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 4,
              "MAX_LABEL": 9
            },
            "bbox": [51.579519, 22.496948, 56.396847, 26.055464],
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [51.579519, 24.245497],
                  [51.757441, 24.294073],
                  [51.794389, 24.019826],
                  [52.577081, 24.177439],
                  [53.404007, 24.151317],
                  [54.008001, 24.121758],
                  [54.693024, 24.797892],
                  [55.439025, 25.439145],
                  [56.070821, 26.055464],
                  [56.261042, 25.714606],
                  [56.396847, 24.924732],
                  [55.886233, 24.920831],
                  [55.804119, 24.269604],
                  [55.981214, 24.130543],
                  [55.528632, 23.933604],
                  [55.525841, 23.524869],
                  [55.234489, 23.110993],
                  [55.208341, 22.70833],
                  [55.006803, 22.496948],
                  [52.000733, 23.001154],
                  [51.617708, 24.014219],
                  [51.579519, 24.245497]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 48958159,
              "ECONOMY": "2. Developed region: nonG7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "Spain",
              "ISO_A2": "ES",
              "ISO_A3": "ESP",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 2,
              "MAX_LABEL": 7
            },
            "bbox": [-9.392884, 35.94685, 3.039484, 43.748338],
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [-9.034818, 41.880571],
                  [-8.984433, 42.592775],
                  [-9.392884, 43.026625],
                  [-7.97819, 43.748338],
                  [-6.754492, 43.567909],
                  [-5.411886, 43.57424],
                  [-4.347843, 43.403449],
                  [-3.517532, 43.455901],
                  [-1.901351, 43.422802],
                  [-1.502771, 43.034014],
                  [0.338047, 42.579546],
                  [0.701591, 42.795734],
                  [1.826793, 42.343385],
                  [2.985999, 42.473015],
                  [3.039484, 41.89212],
                  [2.091842, 41.226089],
                  [0.810525, 41.014732],
                  [0.721331, 40.678318],
                  [0.106692, 40.123934],
                  [-0.278711, 39.309978],
                  [0.111291, 38.738514],
                  [-0.467124, 38.292366],
                  [-0.683389, 37.642354],
                  [-1.438382, 37.443064],
                  [-2.146453, 36.674144],
                  [-3.415781, 36.6589],
                  [-4.368901, 36.677839],
                  [-4.995219, 36.324708],
                  [-5.37716, 35.94685],
                  [-5.866432, 36.029817],
                  [-6.236694, 36.367677],
                  [-6.520191, 36.942913],
                  [-7.453726, 37.097788],
                  [-7.537105, 37.428904],
                  [-7.166508, 37.803894],
                  [-7.029281, 38.075764],
                  [-7.374092, 38.373059],
                  [-7.098037, 39.030073],
                  [-7.498632, 39.629571],
                  [-7.066592, 39.711892],
                  [-7.026413, 40.184524],
                  [-6.86402, 40.330872],
                  [-6.851127, 41.111083],
                  [-6.389088, 41.381815],
                  [-6.668606, 41.883387],
                  [-7.251309, 41.918346],
                  [-7.422513, 41.792075],
                  [-8.013175, 41.790886],
                  [-8.263857, 42.280469],
                  [-8.671946, 42.134689],
                  [-9.034818, 41.880571]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 9960487,
              "ECONOMY": "2. Developed region: nonG7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "Sweden",
              "ISO_A2": "SE",
              "ISO_A3": "SWE",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 3,
              "MAX_LABEL": 7
            },
            "bbox": [11.027369, 55.361737, 23.903379, 69.106247],
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [20.645593, 69.106247],
                  [21.978535, 68.616846],
                  [23.539473, 67.936009],
                  [23.56588, 66.396051],
                  [23.903379, 66.006927],
                  [22.183173, 65.723741],
                  [21.213517, 65.026005],
                  [21.369631, 64.413588],
                  [19.778876, 63.609554],
                  [17.847779, 62.7494],
                  [17.119555, 61.341166],
                  [17.831346, 60.636583],
                  [18.787722, 60.081914],
                  [17.869225, 58.953766],
                  [16.829185, 58.719827],
                  [16.44771, 57.041118],
                  [15.879786, 56.104302],
                  [14.666681, 56.200885],
                  [14.100721, 55.407781],
                  [12.942911, 55.361737],
                  [12.625101, 56.30708],
                  [11.787942, 57.441817],
                  [11.027369, 58.856149],
                  [11.468272, 59.432393],
                  [12.300366, 60.117933],
                  [12.631147, 61.293572],
                  [11.992064, 61.800362],
                  [11.930569, 63.128318],
                  [12.579935, 64.066219],
                  [13.571916, 64.049114],
                  [13.919905, 64.445421],
                  [13.55569, 64.787028],
                  [15.108411, 66.193867],
                  [16.108712, 67.302456],
                  [16.768879, 68.013937],
                  [17.729182, 68.010552],
                  [17.993868, 68.567391],
                  [19.87856, 68.407194],
                  [20.025269, 69.065139],
                  [20.645593, 69.106247]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 64769452,
              "ECONOMY": "1. Developed region: G7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "United Kingdom",
              "ISO_A2": "GB",
              "ISO_A3": "GBR",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 1.7,
              "MAX_LABEL": 6.7
            },
            "bbox": [-7.572168, 49.96, 1.681531, 58.635],
            "geometry": {
              "type": "MultiPolygon",
              "coordinates": [
                [
                  [
                    [-6.197885, 53.867565],
                    [-6.95373, 54.073702],
                    [-7.572168, 54.059956],
                    [-7.366031, 54.595841],
                    [-7.572168, 55.131622],
                    [-6.733847, 55.17286],
                    [-5.661949, 54.554603],
                    [-6.197885, 53.867565]
                  ]
                ],
                [
                  [
                    [-3.005005, 58.635],
                    [-4.073828, 57.553025],
                    [-3.055002, 57.690019],
                    [-1.959281, 57.6848],
                    [-2.219988, 56.870017],
                    [-3.119003, 55.973793],
                    [-2.085009, 55.909998],
                    [-2.005676, 55.804903],
                    [-1.114991, 54.624986],
                    [-0.430485, 54.464376],
                    [0.184981, 53.325014],
                    [0.469977, 52.929999],
                    [1.681531, 52.73952],
                    [1.559988, 52.099998],
                    [1.050562, 51.806761],
                    [1.449865, 51.289428],
                    [0.550334, 50.765739],
                    [-0.787517, 50.774989],
                    [-2.489998, 50.500019],
                    [-2.956274, 50.69688],
                    [-3.617448, 50.228356],
                    [-4.542508, 50.341837],
                    [-5.245023, 49.96],
                    [-5.776567, 50.159678],
                    [-4.30999, 51.210001],
                    [-3.414851, 51.426009],
                    [-3.422719, 51.426848],
                    [-4.984367, 51.593466],
                    [-5.267296, 51.9914],
                    [-4.222347, 52.301356],
                    [-4.770013, 52.840005],
                    [-4.579999, 53.495004],
                    [-3.093831, 53.404547],
                    [-3.09208, 53.404441],
                    [-2.945009, 53.985],
                    [-3.614701, 54.600937],
                    [-3.630005, 54.615013],
                    [-4.844169, 54.790971],
                    [-5.082527, 55.061601],
                    [-4.719112, 55.508473],
                    [-5.047981, 55.783986],
                    [-5.586398, 55.311146],
                    [-5.644999, 56.275015],
                    [-6.149981, 56.78501],
                    [-5.786825, 57.818848],
                    [-5.009999, 58.630013],
                    [-4.211495, 58.550845],
                    [-3.005005, 58.635]
                  ]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 35623680,
              "ECONOMY": "1. Developed region: G7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "Canada",
              "ISO_A2": "CA",
              "ISO_A3": "CAN",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 1.7,
              "MAX_LABEL": 5.7
            },
            "bbox": [-140.99778, 41.675105, -52.648099, 83.23324],
            "geometry": {
              "type": "MultiPolygon",
              "coordinates": [
                [
                  [
                    [-63.6645, 46.55001],
                    [-62.9393, 46.41587],
                    [-62.01208, 46.44314],
                    [-62.50391, 46.03339],
                    [-62.87433, 45.96818],
                    [-64.1428, 46.39265],
                    [-64.39261, 46.72747],
                    [-64.01486, 47.03601],
                    [-63.6645, 46.55001]
                  ]
                ],
                [
                  [
                    [-61.806305, 49.10506],
                    [-62.29318, 49.08717],
                    [-63.58926, 49.40069],
                    [-64.51912, 49.87304],
                    [-64.17322, 49.95718],
                    [-62.85829, 49.70641],
                    [-61.835585, 49.28855],
                    [-61.806305, 49.10506]
                  ]
                ],
                [
                  [
                    [-123.510002, 48.510011],
                    [-124.012891, 48.370846],
                    [-125.655013, 48.825005],
                    [-125.954994, 49.179996],
                    [-126.850004, 49.53],
                    [-127.029993, 49.814996],
                    [-128.059336, 49.994959],
                    [-128.444584, 50.539138],
                    [-128.358414, 50.770648],
                    [-127.308581, 50.552574],
                    [-126.695001, 50.400903],
                    [-125.755007, 50.295018],
                    [-125.415002, 49.950001],
                    [-124.920768, 49.475275],
                    [-123.922509, 49.062484],
                    [-123.510002, 48.510011]
                  ]
                ],
                [
                  [
                    [-56.134036, 50.68701],
                    [-56.795882, 49.812309],
                    [-56.143105, 50.150117],
                    [-55.471492, 49.935815],
                    [-55.822401, 49.587129],
                    [-54.935143, 49.313011],
                    [-54.473775, 49.556691],
                    [-53.476549, 49.249139],
                    [-53.786014, 48.516781],
                    [-53.086134, 48.687804],
                    [-52.958648, 48.157164],
                    [-52.648099, 47.535548],
                    [-53.069158, 46.655499],
                    [-53.521456, 46.618292],
                    [-54.178936, 46.807066],
                    [-53.961869, 47.625207],
                    [-54.240482, 47.752279],
                    [-55.400773, 46.884994],
                    [-55.997481, 46.91972],
                    [-55.291219, 47.389562],
                    [-56.250799, 47.632545],
                    [-57.325229, 47.572807],
                    [-59.266015, 47.603348],
                    [-59.419494, 47.899454],
                    [-58.796586, 48.251525],
                    [-59.231625, 48.523188],
                    [-58.391805, 49.125581],
                    [-57.35869, 50.718274],
                    [-56.73865, 51.287438],
                    [-55.870977, 51.632094],
                    [-55.406974, 51.588273],
                    [-55.600218, 51.317075],
                    [-56.134036, 50.68701]
                  ]
                ],
                [
                  [
                    [-133.180004, 54.169975],
                    [-132.710008, 54.040009],
                    [-131.74999, 54.120004],
                    [-132.04948, 52.984621],
                    [-131.179043, 52.180433],
                    [-131.57783, 52.182371],
                    [-132.180428, 52.639707],
                    [-132.549992, 53.100015],
                    [-133.054611, 53.411469],
                    [-133.239664, 53.85108],
                    [-133.180004, 54.169975]
                  ]
                ],
                [
                  [
                    [-79.26582, 62.158675],
                    [-79.65752, 61.63308],
                    [-80.09956, 61.7181],
                    [-80.36215, 62.01649],
                    [-80.315395, 62.085565],
                    [-79.92939, 62.3856],
                    [-79.52002, 62.36371],
                    [-79.26582, 62.158675]
                  ]
                ],
                [
                  [
                    [-81.89825, 62.7108],
                    [-83.06857, 62.15922],
                    [-83.77462, 62.18231],
                    [-83.99367, 62.4528],
                    [-83.25048, 62.91409],
                    [-81.87699, 62.90458],
                    [-81.89825, 62.7108]
                  ]
                ],
                [
                  [
                    [-85.161308, 65.657285],
                    [-84.975764, 65.217518],
                    [-84.464012, 65.371772],
                    [-83.882626, 65.109618],
                    [-82.787577, 64.766693],
                    [-81.642014, 64.455136],
                    [-81.55344, 63.979609],
                    [-80.817361, 64.057486],
                    [-80.103451, 63.725981],
                    [-80.99102, 63.411246],
                    [-82.547178, 63.651722],
                    [-83.108798, 64.101876],
                    [-84.100417, 63.569712],
                    [-85.523405, 63.052379],
                    [-85.866769, 63.637253],
                    [-87.221983, 63.541238],
                    [-86.35276, 64.035833],
                    [-86.224886, 64.822917],
                    [-85.883848, 65.738778],
                    [-85.161308, 65.657285]
                  ]
                ],
                [
                  [
                    [-75.86588, 67.14886],
                    [-76.98687, 67.09873],
                    [-77.2364, 67.58809],
                    [-76.81166, 68.14856],
                    [-75.89521, 68.28721],
                    [-75.1145, 68.01036],
                    [-75.10333, 67.58202],
                    [-75.21597, 67.44425],
                    [-75.86588, 67.14886]
                  ]
                ],
                [
                  [
                    [-95.647681, 69.10769],
                    [-96.269521, 68.75704],
                    [-97.617401, 69.06003],
                    [-98.431801, 68.9507],
                    [-99.797401, 69.40003],
                    [-98.917401, 69.71003],
                    [-98.218261, 70.14354],
                    [-97.157401, 69.86003],
                    [-96.557401, 69.68003],
                    [-96.257401, 69.49003],
                    [-95.647681, 69.10769]
                  ]
                ],
                [
                  [
                    [-68.23444, 47.35486],
                    [-68.905, 47.185],
                    [-69.237216, 47.447781],
                    [-69.99997, 46.69307],
                    [-70.305, 45.915],
                    [-70.66, 45.46],
                    [-71.08482, 45.30524],
                    [-71.405, 45.255],
                    [-71.50506, 45.0082],
                    [-73.34783, 45.00738],
                    [-74.867, 45.00048],
                    [-75.31821, 44.81645],
                    [-76.375, 44.09631],
                    [-76.5, 44.018459],
                    [-76.820034, 43.628784],
                    [-77.737885, 43.629056],
                    [-78.72028, 43.625089],
                    [-79.171674, 43.466339],
                    [-79.01, 43.27],
                    [-78.92, 42.965],
                    [-78.939362, 42.863611],
                    [-80.247448, 42.3662],
                    [-81.277747, 42.209026],
                    [-82.439278, 41.675105],
                    [-82.690089, 41.675105],
                    [-83.02981, 41.832796],
                    [-83.142, 41.975681],
                    [-83.12, 42.08],
                    [-82.9, 42.43],
                    [-82.43, 42.98],
                    [-82.137642, 43.571088],
                    [-82.337763, 44.44],
                    [-82.550925, 45.347517],
                    [-83.592851, 45.816894],
                    [-83.469551, 45.994686],
                    [-83.616131, 46.116927],
                    [-83.890765, 46.116927],
                    [-84.091851, 46.275419],
                    [-84.14212, 46.512226],
                    [-84.3367, 46.40877],
                    [-84.6049, 46.4396],
                    [-84.543749, 46.538684],
                    [-84.779238, 46.637102],
                    [-84.87608, 46.900083],
                    [-85.652363, 47.220219],
                    [-86.461991, 47.553338],
                    [-87.439793, 47.94],
                    [-88.378114, 48.302918],
                    [-89.272917, 48.019808],
                    [-89.6, 48.01],
                    [-90.83, 48.27],
                    [-91.64, 48.14],
                    [-92.61, 48.45],
                    [-93.63087, 48.60926],
                    [-94.32914, 48.67074],
                    [-94.64, 48.84],
                    [-94.81758, 49.38905],
                    [-95.15609, 49.38425],
                    [-95.15907, 49],
                    [-97.22872, 49.0007],
                    [-100.65, 49],
                    [-104.04826, 48.99986],
                    [-107.05, 49],
                    [-110.05, 49],
                    [-113, 49],
                    [-116.04818, 49],
                    [-117.03121, 49],
                    [-120, 49],
                    [-122.84, 49],
                    [-122.97421, 49.002538],
                    [-124.91024, 49.98456],
                    [-125.62461, 50.41656],
                    [-127.43561, 50.83061],
                    [-127.99276, 51.71583],
                    [-127.85032, 52.32961],
                    [-129.12979, 52.75538],
                    [-129.30523, 53.56159],
                    [-130.51497, 54.28757],
                    [-130.536109, 54.802754],
                    [-130.53611, 54.80278],
                    [-129.98, 55.285],
                    [-130.00778, 55.91583],
                    [-131.70781, 56.55212],
                    [-132.73042, 57.69289],
                    [-133.35556, 58.41028],
                    [-134.27111, 58.86111],
                    [-134.945, 59.27056],
                    [-135.47583, 59.78778],
                    [-136.47972, 59.46389],
                    [-137.4525, 58.905],
                    [-138.34089, 59.56211],
                    [-139.039, 60],
                    [-140.013, 60.27682],
                    [-140.99778, 60.30639],
                    [-140.9925, 66.00003],
                    [-140.986, 69.712],
                    [-140.985988, 69.711998],
                    [-139.12052, 69.47102],
                    [-137.54636, 68.99002],
                    [-136.50358, 68.89804],
                    [-135.62576, 69.31512],
                    [-134.41464, 69.62743],
                    [-132.92925, 69.50534],
                    [-131.43136, 69.94451],
                    [-129.79471, 70.19369],
                    [-129.10773, 69.77927],
                    [-128.36156, 70.01286],
                    [-128.13817, 70.48384],
                    [-127.44712, 70.37721],
                    [-125.75632, 69.48058],
                    [-124.42483, 70.1584],
                    [-124.28968, 69.39969],
                    [-123.06108, 69.56372],
                    [-122.6835, 69.85553],
                    [-121.47226, 69.79778],
                    [-119.94288, 69.37786],
                    [-117.60268, 69.01128],
                    [-116.22643, 68.84151],
                    [-115.2469, 68.90591],
                    [-113.89794, 68.3989],
                    [-115.30489, 67.90261],
                    [-113.49727, 67.68815],
                    [-110.798, 67.80612],
                    [-109.94619, 67.98104],
                    [-108.8802, 67.38144],
                    [-107.79239, 67.88736],
                    [-108.81299, 68.31164],
                    [-108.16721, 68.65392],
                    [-106.95, 68.7],
                    [-106.15, 68.8],
                    [-105.34282, 68.56122],
                    [-104.33791, 68.018],
                    [-103.22115, 68.09775],
                    [-101.45433, 67.64689],
                    [-99.90195, 67.80566],
                    [-98.4432, 67.78165],
                    [-98.5586, 68.40394],
                    [-97.66948, 68.57864],
                    [-96.11991, 68.23939],
                    [-96.12588, 67.29338],
                    [-95.48943, 68.0907],
                    [-94.685, 68.06383],
                    [-94.23282, 69.06903],
                    [-95.30408, 69.68571],
                    [-96.47131, 70.08976],
                    [-96.39115, 71.19482],
                    [-95.2088, 71.92053],
                    [-93.88997, 71.76015],
                    [-92.87818, 71.31869],
                    [-91.51964, 70.19129],
                    [-92.40692, 69.69997],
                    [-90.5471, 69.49766],
                    [-90.55151, 68.47499],
                    [-89.21515, 69.25873],
                    [-88.01966, 68.61508],
                    [-88.31749, 67.87338],
                    [-87.35017, 67.19872],
                    [-86.30607, 67.92146],
                    [-85.57664, 68.78456],
                    [-85.52197, 69.88211],
                    [-84.10081, 69.80539],
                    [-82.62258, 69.65826],
                    [-81.28043, 69.16202],
                    [-81.2202, 68.66567],
                    [-81.96436, 68.13253],
                    [-81.25928, 67.59716],
                    [-81.38653, 67.11078],
                    [-83.34456, 66.41154],
                    [-84.73542, 66.2573],
                    [-85.76943, 66.55833],
                    [-86.0676, 66.05625],
                    [-87.03143, 65.21297],
                    [-87.32324, 64.77563],
                    [-88.48296, 64.09897],
                    [-89.91444, 64.03273],
                    [-90.70398, 63.61017],
                    [-90.77004, 62.96021],
                    [-91.93342, 62.83508],
                    [-93.15698, 62.02469],
                    [-94.24153, 60.89865],
                    [-94.62931, 60.11021],
                    [-94.6846, 58.94882],
                    [-93.21502, 58.78212],
                    [-92.76462, 57.84571],
                    [-92.29703, 57.08709],
                    [-90.89769, 57.28468],
                    [-89.03953, 56.85172],
                    [-88.03978, 56.47162],
                    [-87.32421, 55.99914],
                    [-86.07121, 55.72383],
                    [-85.01181, 55.3026],
                    [-83.36055, 55.24489],
                    [-82.27285, 55.14832],
                    [-82.4362, 54.28227],
                    [-82.12502, 53.27703],
                    [-81.40075, 52.15788],
                    [-79.91289, 51.20842],
                    [-79.14301, 51.53393],
                    [-78.60191, 52.56208],
                    [-79.12421, 54.14145],
                    [-79.82958, 54.66772],
                    [-78.22874, 55.13645],
                    [-77.0956, 55.83741],
                    [-76.54137, 56.53423],
                    [-76.62319, 57.20263],
                    [-77.30226, 58.05209],
                    [-78.51688, 58.80458],
                    [-77.33676, 59.85261],
                    [-77.77272, 60.75788],
                    [-78.10687, 62.31964],
                    [-77.41067, 62.55053],
                    [-75.69621, 62.2784],
                    [-74.6682, 62.18111],
                    [-73.83988, 62.4438],
                    [-72.90853, 62.10507],
                    [-71.67708, 61.52535],
                    [-71.37369, 61.13717],
                    [-69.59042, 61.06141],
                    [-69.62033, 60.22125],
                    [-69.2879, 58.95736],
                    [-68.37455, 58.80106],
                    [-67.64976, 58.21206],
                    [-66.20178, 58.76731],
                    [-65.24517, 59.87071],
                    [-64.58352, 60.33558],
                    [-63.80475, 59.4426],
                    [-62.50236, 58.16708],
                    [-61.39655, 56.96745],
                    [-61.79866, 56.33945],
                    [-60.46853, 55.77548],
                    [-59.56962, 55.20407],
                    [-57.97508, 54.94549],
                    [-57.3332, 54.6265],
                    [-56.93689, 53.78032],
                    [-56.15811, 53.64749],
                    [-55.75632, 53.27036],
                    [-55.68338, 52.14664],
                    [-56.40916, 51.7707],
                    [-57.12691, 51.41972],
                    [-58.77482, 51.0643],
                    [-60.03309, 50.24277],
                    [-61.72366, 50.08046],
                    [-63.86251, 50.29099],
                    [-65.36331, 50.2982],
                    [-66.39905, 50.22897],
                    [-67.23631, 49.51156],
                    [-68.51114, 49.06836],
                    [-69.95362, 47.74488],
                    [-71.10458, 46.82171],
                    [-70.25522, 46.98606],
                    [-68.65, 48.3],
                    [-66.55243, 49.1331],
                    [-65.05626, 49.23278],
                    [-64.17099, 48.74248],
                    [-65.11545, 48.07085],
                    [-64.79854, 46.99297],
                    [-64.47219, 46.23849],
                    [-63.17329, 45.73902],
                    [-61.52072, 45.88377],
                    [-60.51815, 47.00793],
                    [-60.4486, 46.28264],
                    [-59.80287, 45.9204],
                    [-61.03988, 45.26525],
                    [-63.25471, 44.67014],
                    [-64.24656, 44.26553],
                    [-65.36406, 43.54523],
                    [-66.1234, 43.61867],
                    [-66.16173, 44.46512],
                    [-64.42549, 45.29204],
                    [-66.02605, 45.25931],
                    [-67.13741, 45.13753],
                    [-67.79134, 45.70281],
                    [-67.79046, 47.06636],
                    [-68.23444, 47.35486]
                  ]
                ],
                [
                  [
                    [-114.16717, 73.12145],
                    [-114.66634, 72.65277],
                    [-112.44102, 72.9554],
                    [-111.05039, 72.4504],
                    [-109.92035, 72.96113],
                    [-109.00654, 72.63335],
                    [-108.18835, 71.65089],
                    [-107.68599, 72.06548],
                    [-108.39639, 73.08953],
                    [-107.51645, 73.23598],
                    [-106.52259, 73.07601],
                    [-105.40246, 72.67259],
                    [-104.77484, 71.6984],
                    [-104.46476, 70.99297],
                    [-102.78537, 70.49776],
                    [-100.98078, 70.02432],
                    [-101.08929, 69.58447],
                    [-102.73116, 69.50402],
                    [-102.09329, 69.11962],
                    [-102.43024, 68.75282],
                    [-104.24, 68.91],
                    [-105.96, 69.18],
                    [-107.12254, 69.11922],
                    [-109, 68.78],
                    [-111.534149, 68.630059],
                    [-113.3132, 68.53554],
                    [-113.85496, 69.00744],
                    [-115.22, 69.28],
                    [-116.10794, 69.16821],
                    [-117.34, 69.96],
                    [-116.67473, 70.06655],
                    [-115.13112, 70.2373],
                    [-113.72141, 70.19237],
                    [-112.4161, 70.36638],
                    [-114.35, 70.6],
                    [-116.48684, 70.52045],
                    [-117.9048, 70.54056],
                    [-118.43238, 70.9092],
                    [-116.11311, 71.30918],
                    [-117.65568, 71.2952],
                    [-119.40199, 71.55859],
                    [-118.56267, 72.30785],
                    [-117.86642, 72.70594],
                    [-115.18909, 73.31459],
                    [-114.16717, 73.12145]
                  ]
                ],
                [
                  [
                    [-104.5, 73.42],
                    [-105.38, 72.76],
                    [-106.94, 73.46],
                    [-106.6, 73.6],
                    [-105.26, 73.64],
                    [-104.5, 73.42]
                  ]
                ],
                [
                  [
                    [-76.34, 73.102685],
                    [-76.251404, 72.826385],
                    [-77.314438, 72.855545],
                    [-78.39167, 72.876656],
                    [-79.486252, 72.742203],
                    [-79.775833, 72.802902],
                    [-80.876099, 73.333183],
                    [-80.833885, 73.693184],
                    [-80.353058, 73.75972],
                    [-78.064438, 73.651932],
                    [-76.34, 73.102685]
                  ]
                ],
                [
                  [
                    [-86.562179, 73.157447],
                    [-85.774371, 72.534126],
                    [-84.850112, 73.340278],
                    [-82.31559, 73.750951],
                    [-80.600088, 72.716544],
                    [-80.748942, 72.061907],
                    [-78.770639, 72.352173],
                    [-77.824624, 72.749617],
                    [-75.605845, 72.243678],
                    [-74.228616, 71.767144],
                    [-74.099141, 71.33084],
                    [-72.242226, 71.556925],
                    [-71.200015, 70.920013],
                    [-68.786054, 70.525024],
                    [-67.91497, 70.121948],
                    [-66.969033, 69.186087],
                    [-68.805123, 68.720198],
                    [-66.449866, 68.067163],
                    [-64.862314, 67.847539],
                    [-63.424934, 66.928473],
                    [-61.851981, 66.862121],
                    [-62.163177, 66.160251],
                    [-63.918444, 64.998669],
                    [-65.14886, 65.426033],
                    [-66.721219, 66.388041],
                    [-68.015016, 66.262726],
                    [-68.141287, 65.689789],
                    [-67.089646, 65.108455],
                    [-65.73208, 64.648406],
                    [-65.320168, 64.382737],
                    [-64.669406, 63.392927],
                    [-65.013804, 62.674185],
                    [-66.275045, 62.945099],
                    [-68.783186, 63.74567],
                    [-67.369681, 62.883966],
                    [-66.328297, 62.280075],
                    [-66.165568, 61.930897],
                    [-68.877367, 62.330149],
                    [-71.023437, 62.910708],
                    [-72.235379, 63.397836],
                    [-71.886278, 63.679989],
                    [-73.378306, 64.193963],
                    [-74.834419, 64.679076],
                    [-74.818503, 64.389093],
                    [-77.70998, 64.229542],
                    [-78.555949, 64.572906],
                    [-77.897281, 65.309192],
                    [-76.018274, 65.326969],
                    [-73.959795, 65.454765],
                    [-74.293883, 65.811771],
                    [-73.944912, 66.310578],
                    [-72.651167, 67.284576],
                    [-72.92606, 67.726926],
                    [-73.311618, 68.069437],
                    [-74.843307, 68.554627],
                    [-76.869101, 68.894736],
                    [-76.228649, 69.147769],
                    [-77.28737, 69.76954],
                    [-78.168634, 69.826488],
                    [-78.957242, 70.16688],
                    [-79.492455, 69.871808],
                    [-81.305471, 69.743185],
                    [-84.944706, 69.966634],
                    [-87.060003, 70.260001],
                    [-88.681713, 70.410741],
                    [-89.51342, 70.762038],
                    [-88.467721, 71.218186],
                    [-89.888151, 71.222552],
                    [-90.20516, 72.235074],
                    [-89.436577, 73.129464],
                    [-88.408242, 73.537889],
                    [-85.826151, 73.803816],
                    [-86.562179, 73.157447]
                  ]
                ],
                [
                  [
                    [-100.35642, 73.84389],
                    [-99.16387, 73.63339],
                    [-97.38, 73.76],
                    [-97.12, 73.47],
                    [-98.05359, 72.99052],
                    [-96.54, 72.56],
                    [-96.72, 71.66],
                    [-98.35966, 71.27285],
                    [-99.32286, 71.35639],
                    [-100.01482, 71.73827],
                    [-102.5, 72.51],
                    [-102.48, 72.83],
                    [-100.43836, 72.70588],
                    [-101.54, 73.36],
                    [-100.35642, 73.84389]
                  ]
                ],
                [
                  [
                    [-93.196296, 72.771992],
                    [-94.269047, 72.024596],
                    [-95.409856, 72.061881],
                    [-96.033745, 72.940277],
                    [-96.018268, 73.43743],
                    [-95.495793, 73.862417],
                    [-94.503658, 74.134907],
                    [-92.420012, 74.100025],
                    [-90.509793, 73.856732],
                    [-92.003965, 72.966244],
                    [-93.196296, 72.771992]
                  ]
                ],
                [
                  [
                    [-120.46, 71.383602],
                    [-123.09219, 70.90164],
                    [-123.62, 71.34],
                    [-125.928949, 71.868688],
                    [-125.5, 72.292261],
                    [-124.80729, 73.02256],
                    [-123.94, 73.68],
                    [-124.91775, 74.29275],
                    [-121.53788, 74.44893],
                    [-120.10978, 74.24135],
                    [-117.55564, 74.18577],
                    [-116.58442, 73.89607],
                    [-115.51081, 73.47519],
                    [-116.76794, 73.22292],
                    [-119.22, 72.52],
                    [-120.46, 71.82],
                    [-120.46, 71.383602]
                  ]
                ],
                [
                  [
                    [-93.612756, 74.979997],
                    [-94.156909, 74.592347],
                    [-95.608681, 74.666864],
                    [-96.820932, 74.927623],
                    [-96.288587, 75.377828],
                    [-94.85082, 75.647218],
                    [-93.977747, 75.29649],
                    [-93.612756, 74.979997]
                  ]
                ],
                [
                  [
                    [-98.5, 76.72],
                    [-97.735585, 76.25656],
                    [-97.704415, 75.74344],
                    [-98.16, 75],
                    [-99.80874, 74.89744],
                    [-100.88366, 75.05736],
                    [-100.86292, 75.64075],
                    [-102.50209, 75.5638],
                    [-102.56552, 76.3366],
                    [-101.48973, 76.30537],
                    [-99.98349, 76.64634],
                    [-98.57699, 76.58859],
                    [-98.5, 76.72]
                  ]
                ],
                [
                  [
                    [-108.21141, 76.20168],
                    [-107.81943, 75.84552],
                    [-106.92893, 76.01282],
                    [-105.881, 75.9694],
                    [-105.70498, 75.47951],
                    [-106.31347, 75.00527],
                    [-109.7, 74.85],
                    [-112.22307, 74.41696],
                    [-113.74381, 74.39427],
                    [-113.87135, 74.72029],
                    [-111.79421, 75.1625],
                    [-116.31221, 75.04343],
                    [-117.7104, 75.2222],
                    [-116.34602, 76.19903],
                    [-115.40487, 76.47887],
                    [-112.59056, 76.14134],
                    [-110.81422, 75.54919],
                    [-109.0671, 75.47321],
                    [-110.49726, 76.42982],
                    [-109.5811, 76.79417],
                    [-108.54859, 76.67832],
                    [-108.21141, 76.20168]
                  ]
                ],
                [
                  [
                    [-94.684086, 77.097878],
                    [-93.573921, 76.776296],
                    [-91.605023, 76.778518],
                    [-90.741846, 76.449597],
                    [-90.969661, 76.074013],
                    [-89.822238, 75.847774],
                    [-89.187083, 75.610166],
                    [-87.838276, 75.566189],
                    [-86.379192, 75.482421],
                    [-84.789625, 75.699204],
                    [-82.753445, 75.784315],
                    [-81.128531, 75.713983],
                    [-80.057511, 75.336849],
                    [-79.833933, 74.923127],
                    [-80.457771, 74.657304],
                    [-81.948843, 74.442459],
                    [-83.228894, 74.564028],
                    [-86.097452, 74.410032],
                    [-88.15035, 74.392307],
                    [-89.764722, 74.515555],
                    [-92.422441, 74.837758],
                    [-92.768285, 75.38682],
                    [-92.889906, 75.882655],
                    [-93.893824, 76.319244],
                    [-95.962457, 76.441381],
                    [-97.121379, 76.751078],
                    [-96.745123, 77.161389],
                    [-94.684086, 77.097878]
                  ]
                ],
                [
                  [
                    [-116.198587, 77.645287],
                    [-116.335813, 76.876962],
                    [-117.106051, 76.530032],
                    [-118.040412, 76.481172],
                    [-119.899318, 76.053213],
                    [-121.499995, 75.900019],
                    [-122.854924, 76.116543],
                    [-122.854925, 76.116543],
                    [-121.157535, 76.864508],
                    [-119.103939, 77.51222],
                    [-117.570131, 77.498319],
                    [-116.198587, 77.645287]
                  ]
                ],
                [
                  [
                    [-93.840003, 77.519997],
                    [-94.295608, 77.491343],
                    [-96.169654, 77.555111],
                    [-96.436304, 77.834629],
                    [-94.422577, 77.820005],
                    [-93.720656, 77.634331],
                    [-93.840003, 77.519997]
                  ]
                ],
                [
                  [
                    [-110.186938, 77.697015],
                    [-112.051191, 77.409229],
                    [-113.534279, 77.732207],
                    [-112.724587, 78.05105],
                    [-111.264443, 78.152956],
                    [-109.854452, 77.996325],
                    [-110.186938, 77.697015]
                  ]
                ],
                [
                  [
                    [-109.663146, 78.601973],
                    [-110.881314, 78.40692],
                    [-112.542091, 78.407902],
                    [-112.525891, 78.550555],
                    [-111.50001, 78.849994],
                    [-110.963661, 78.804441],
                    [-109.663146, 78.601973]
                  ]
                ],
                [
                  [
                    [-95.830295, 78.056941],
                    [-97.309843, 77.850597],
                    [-98.124289, 78.082857],
                    [-98.552868, 78.458105],
                    [-98.631984, 78.87193],
                    [-97.337231, 78.831984],
                    [-96.754399, 78.765813],
                    [-95.559278, 78.418315],
                    [-95.830295, 78.056941]
                  ]
                ],
                [
                  [
                    [-100.060192, 78.324754],
                    [-99.670939, 77.907545],
                    [-101.30394, 78.018985],
                    [-102.949809, 78.343229],
                    [-105.176133, 78.380332],
                    [-104.210429, 78.67742],
                    [-105.41958, 78.918336],
                    [-105.492289, 79.301594],
                    [-103.529282, 79.165349],
                    [-100.825158, 78.800462],
                    [-100.060192, 78.324754]
                  ]
                ],
                [
                  [
                    [-87.02, 79.66],
                    [-85.81435, 79.3369],
                    [-87.18756, 79.0393],
                    [-89.03535, 78.28723],
                    [-90.80436, 78.21533],
                    [-92.87669, 78.34333],
                    [-93.95116, 78.75099],
                    [-93.93574, 79.11373],
                    [-93.14524, 79.3801],
                    [-94.974, 79.37248],
                    [-96.07614, 79.70502],
                    [-96.70972, 80.15777],
                    [-96.01644, 80.60233],
                    [-95.32345, 80.90729],
                    [-94.29843, 80.97727],
                    [-94.73542, 81.20646],
                    [-92.40984, 81.25739],
                    [-91.13289, 80.72345],
                    [-89.45, 80.509322],
                    [-87.81, 80.32],
                    [-87.02, 79.66]
                  ]
                ],
                [
                  [
                    [-68.5, 83.106322],
                    [-65.82735, 83.02801],
                    [-63.68, 82.9],
                    [-61.85, 82.6286],
                    [-61.89388, 82.36165],
                    [-64.334, 81.92775],
                    [-66.75342, 81.72527],
                    [-67.65755, 81.50141],
                    [-65.48031, 81.50657],
                    [-67.84, 80.9],
                    [-69.4697, 80.61683],
                    [-71.18, 79.8],
                    [-73.2428, 79.63415],
                    [-73.88, 79.430162],
                    [-76.90773, 79.32309],
                    [-75.52924, 79.19766],
                    [-76.22046, 79.01907],
                    [-75.39345, 78.52581],
                    [-76.34354, 78.18296],
                    [-77.88851, 77.89991],
                    [-78.36269, 77.50859],
                    [-79.75951, 77.20968],
                    [-79.61965, 76.98336],
                    [-77.91089, 77.022045],
                    [-77.88911, 76.777955],
                    [-80.56125, 76.17812],
                    [-83.17439, 76.45403],
                    [-86.11184, 76.29901],
                    [-87.6, 76.42],
                    [-89.49068, 76.47239],
                    [-89.6161, 76.95213],
                    [-87.76739, 77.17833],
                    [-88.26, 77.9],
                    [-87.65, 77.970222],
                    [-84.97634, 77.53873],
                    [-86.34, 78.18],
                    [-87.96192, 78.37181],
                    [-87.15198, 78.75867],
                    [-85.37868, 78.9969],
                    [-85.09495, 79.34543],
                    [-86.50734, 79.73624],
                    [-86.93179, 80.25145],
                    [-84.19844, 80.20836],
                    [-83.408696, 80.1],
                    [-81.84823, 80.46442],
                    [-84.1, 80.58],
                    [-87.59895, 80.51627],
                    [-89.36663, 80.85569],
                    [-90.2, 81.26],
                    [-91.36786, 81.5531],
                    [-91.58702, 81.89429],
                    [-90.1, 82.085],
                    [-88.93227, 82.11751],
                    [-86.97024, 82.27961],
                    [-85.5, 82.652273],
                    [-84.260005, 82.6],
                    [-83.18, 82.32],
                    [-82.42, 82.86],
                    [-81.1, 83.02],
                    [-79.30664, 83.13056],
                    [-76.25, 83.172059],
                    [-75.71878, 83.06404],
                    [-72.83153, 83.23324],
                    [-70.665765, 83.169781],
                    [-68.5, 83.106322]
                  ]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 8299706,
              "ECONOMY": "2. Developed region: nonG7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "Israel",
              "ISO_A2": "IL",
              "ISO_A3": "ISR",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 3,
              "MAX_LABEL": 8
            },
            "bbox": [34.265433, 29.501326, 35.836397, 33.277426],
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [34.823243, 29.761081],
                  [34.26544, 31.21936],
                  [34.265435, 31.219357],
                  [34.265433, 31.219361],
                  [34.556372, 31.548824],
                  [34.488107, 31.605539],
                  [34.752587, 32.072926],
                  [34.955417, 32.827376],
                  [35.098457, 33.080539],
                  [35.126053, 33.0909],
                  [35.460709, 33.08904],
                  [35.552797, 33.264275],
                  [35.821101, 33.277426],
                  [35.836397, 32.868123],
                  [35.700798, 32.716014],
                  [35.719918, 32.709192],
                  [35.545665, 32.393992],
                  [35.18393, 32.532511],
                  [34.974641, 31.866582],
                  [35.225892, 31.754341],
                  [34.970507, 31.616778],
                  [34.927408, 31.353435],
                  [35.397561, 31.489086],
                  [35.420918, 31.100066],
                  [34.922603, 29.501326],
                  [34.823243, 29.761081]
                ]
              ]
            }
          },
          // {
          //   "type": "Feature",
          //   "properties": {
          //     "POP_EST": 204924861,
          //     "ECONOMY": "5. Emerging region: G20",
          //     "INCOME_GRP": "4. Lower middle income",
          //     "ADMIN": "India",
          //     "ISO_A2": "PK",
          //     "ISO_A3": "PAK",
          //     "TINY": -99,
          //     "HOMEPART": 1,
          //     "MIN_ZOOM": 0,
          //     "MIN_LABEL": 3,
          //     "MAX_LABEL": 7
          //   },
          //   "bbox": [60.874248, 23.691965, 77.837451, 37.133031],
          //   "geometry": {
          //     "type": "Polygon",
          //     "coordinates": [
          //       [
          //         [71.498768, 35.650563],
          //         [71.262348, 36.074388],
          //         [71.846292, 36.509942],
          //         [72.920025, 36.720007],
          //         [74.067552, 36.836176],
          //         [74.575893, 37.020841],
          //         [75.158028, 37.133031],
          //         [75.896897, 36.666806],
          //         [76.192848, 35.898403],
          //         [77.837451, 35.49401],
          //         [76.871722, 34.653544],
          //         [75.757061, 34.504923],
          //         [74.240203, 34.748887],
          //         // [73.749948, 34.317699],
          //         [74.104294, 33.441473],
          //         [74.451559, 32.7649],
          //         [75.258642, 32.271105],
          //         [74.405929, 31.692639],
          //         [74.42138, 30.979815],
          //         [73.450638, 29.976413],
          //       ]
          //     ]
          //   }
          // }, 
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 1281935911,
              "ECONOMY": "3. Emerging region: BRIC",
              "INCOME_GRP": "4. Lower middle income",
              "ADMIN": "India",
              "ISO_A2": "IN",
              "ISO_A3": "IND",
              "TINY": -99,
              "HOMEPART": 5,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 1.7,
              "MAX_LABEL": 6.7
            },
            "bbox": [68.176645, 7.965535, 97.402561, 35.49401],
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                [
                  [92.672721, 22.041239],
                  [92.146035, 23.627499],
                  [91.869928, 23.624346],
                  [91.706475, 22.985264],
                  [91.158963, 23.503527],
                  [91.46773, 24.072639],
                  [91.915093, 24.130414],
                  [92.376202, 24.976693],
                  [91.799596, 25.147432],
                  [90.872211, 25.132601],
                  [89.920693, 25.26975],
                  [89.832481, 25.965082],
                  [89.355094, 26.014407],
                  [88.563049, 26.446526],
                  [88.209789, 25.768066],
                  [88.931554, 25.238692],
                  [88.306373, 24.866079],
                  [88.084422, 24.501657],
                  [88.69994, 24.233715],
                  [88.52977, 23.631142],
                  [88.876312, 22.879146],
                  [89.031961, 22.055708],
                  [88.888766, 21.690588],
                  [88.208497, 21.703172],
                  [86.975704, 21.000000],
                  [87.033169, 20.743308],
                  [86.499351, 20.151638],
                  [85.060266, 19.478579],
                  [83.941006, 18.30201],
                  [83.189217, 17.671221],
                  [82.192792, 17.016636],
                  [82.191242, 16.556664],
                  [81.692719, 16.310219],
                  [80.791999, 15.951972],
                  [80.324896, 15.899185],
                  [80.025069, 15.136415],
                  [80.233274, 13.835771],
                  [80.286294, 13.006261],
                  [79.862547, 12.056215],
                  [79.857999, 10.357275],
                  [79.340512, 10.308854],
                  [78.885345, 9.546136],
                  [79.18972, 9.216544],
                  [78.277941, 8.933047],
                  [77.941165, 8.252959],
                  [77.539898, 7.965535],
                  [76.592979, 8.899276],
                  [76.130061, 10.29963],
                  [75.746467, 11.308251],
                  [75.396101, 11.781245],
                  [74.864816, 12.741936],
                  [74.616717, 13.992583],
                  [74.443859, 14.617222],
                  [73.534199, 15.990652],
                  [73.119909, 17.92857],
                  [72.820909, 19.208234],
                  [72.824475, 20.419503],
                  [72.630533, 21.356009],
                  [71.175273, 20.757441],
                  [70.470459, 20.877331],
                  [69.16413, 22.089298],
                  [69.644928, 22.450775],
                  [69.349597, 22.84318],
                  [68.176645, 23.691965],
                  [68.842599, 24.359134],
                  [71.04324, 24.356524],
                  [70.844699, 25.215102],
                  [70.282873, 25.722229],
                  [70.168927, 26.491872],
                  [69.514393, 26.940966],
                  [70.616496, 27.989196],
                  [71.777666, 27.91318],
                  [72.823752, 28.961592],
                  [73.450638, 29.976413],
                  [74.42138, 30.979815],
                  [74.405929, 31.692639],
                  [75.258642, 32.271105],
                  [74.451559, 32.7649],
                  [74.104294, 33.441473],
                  [73.749948, 34.317699],
                  [74.240203, 34.748887],
                  [75.757061, 34.504923],
                  [76.871722, 34.653544],
                  [77.837451, 35.49401],
                  [78.912269, 34.321936],
                  [78.811086, 33.506198],
                  [79.208892, 32.994395],
                  [79.176129, 32.48378],
                  [78.458446, 32.618164],
                  [78.738894, 31.515906],
                  [79.721367, 30.882715],
                  [81.111256, 30.183481],
                  [80.476721, 29.729865],
                  [80.088425, 28.79447],
                  [81.057203, 28.416095],
                  [81.999987, 27.925479],
                  [83.304249, 27.364506],
                  [84.675018, 27.234901],
                  [85.251779, 26.726198],
                  [86.024393, 26.630985],
                  [87.227472, 26.397898],
                  [88.060238, 26.414615],
                  [88.174804, 26.810405],
                  [88.043133, 27.445819],
                  [88.120441, 27.876542],
                  [88.730326, 28.086865],
                  [88.814248, 27.299316],
                  [88.835643, 27.098966],
                  [89.744528, 26.719403],
                  [90.373275, 26.875724],
                  [91.217513, 26.808648],
                  [92.033484, 26.83831],
                  [92.103712, 27.452614],
                  [91.696657, 27.771742],
                  [92.503119, 27.896876],
                  [93.413348, 28.640629],
                  [94.56599, 29.277438],
                  [95.404802, 29.031717],
                  [96.117679, 29.452802],
                  [96.586591, 28.83098],
                  [96.248833, 28.411031],
                  [97.327114, 28.261583],
                  [97.402561, 27.882536],
                  [97.051989, 27.699059],
                  [97.133999, 27.083774],
                  [96.419366, 27.264589],
                  [95.124768, 26.573572],
                  [95.155153, 26.001307],
                  [94.603249, 25.162495],
                  [94.552658, 24.675238],
                  [94.106742, 23.850741],
                  [93.325188, 24.078556],
                  [93.286327, 23.043658],
                  [93.060294, 22.703111],
                  [93.166128, 22.27846],
                  [92.672721, 22.041239]
                ]
              ]
            }
          },
        {
            "type": "Feature",
            "properties": {
              "POP_EST": 326625791,
              "ECONOMY": "1. Developed region: G7",
              "INCOME_GRP": "1. High income: OECD",
              "ADMIN": "United States ",
              "ISO_A2": "US",
              "ISO_A3": "USA",
              "TINY": -99,
              "HOMEPART": 1,
              "MIN_ZOOM": 0,
              "MIN_LABEL": 1.7,
              "MAX_LABEL": 5.7
            },
            "bbox": [-171.791111, 18.91619, -66.96466, 71.357764],
            "geometry": {
              "type": "MultiPolygon",
              "coordinates": [
                [
                  [
                    [-122.84, 49],
                    [-120, 49],
                    [-117.03121, 49],
                    [-116.04818, 49],
                    [-113, 49],
                    [-110.05, 49],
                    [-107.05, 49],
                    [-104.04826, 48.99986],
                    [-100.65, 49],
                    [-97.22872, 49.0007],
                    [-95.15907, 49],
                    [-95.15609, 49.38425],
                    [-94.81758, 49.38905],
                    [-94.64, 48.84],
                    [-94.32914, 48.67074],
                    [-93.63087, 48.60926],
                    [-92.61, 48.45],
                    [-91.64, 48.14],
                    [-90.83, 48.27],
                    [-89.6, 48.01],
                    [-89.272917, 48.019808],
                    [-88.378114, 48.302918],
                    [-87.439793, 47.94],
                    [-86.461991, 47.553338],
                    [-85.652363, 47.220219],
                    [-84.87608, 46.900083],
                    [-84.779238, 46.637102],
                    [-84.543749, 46.538684],
                    [-84.6049, 46.4396],
                    [-84.3367, 46.40877],
                    [-84.14212, 46.512226],
                    [-84.091851, 46.275419],
                    [-83.890765, 46.116927],
                    [-83.616131, 46.116927],
                    [-83.469551, 45.994686],
                    [-83.592851, 45.816894],
                    [-82.550925, 45.347517],
                    [-82.337763, 44.44],
                    [-82.137642, 43.571088],
                    [-82.43, 42.98],
                    [-82.9, 42.43],
                    [-83.12, 42.08],
                    [-83.142, 41.975681],
                    [-83.02981, 41.832796],
                    [-82.690089, 41.675105],
                    [-82.439278, 41.675105],
                    [-81.277747, 42.209026],
                    [-80.247448, 42.3662],
                    [-78.939362, 42.863611],
                    [-78.92, 42.965],
                    [-79.01, 43.27],
                    [-79.171674, 43.466339],
                    [-78.72028, 43.625089],
                    [-77.737885, 43.629056],
                    [-76.820034, 43.628784],
                    [-76.5, 44.018459],
                    [-76.375, 44.09631],
                    [-75.31821, 44.81645],
                    [-74.867, 45.00048],
                    [-73.34783, 45.00738],
                    [-71.50506, 45.0082],
                    [-71.405, 45.255],
                    [-71.08482, 45.30524],
                    [-70.66, 45.46],
                    [-70.305, 45.915],
                    [-69.99997, 46.69307],
                    [-69.237216, 47.447781],
                    [-68.905, 47.185],
                    [-68.23444, 47.35486],
                    [-67.79046, 47.06636],
                    [-67.79134, 45.70281],
                    [-67.13741, 45.13753],
                    [-66.96466, 44.8097],
                    [-68.03252, 44.3252],
                    [-69.06, 43.98],
                    [-70.11617, 43.68405],
                    [-70.645476, 43.090238],
                    [-70.81489, 42.8653],
                    [-70.825, 42.335],
                    [-70.495, 41.805],
                    [-70.08, 41.78],
                    [-70.185, 42.145],
                    [-69.88497, 41.92283],
                    [-69.96503, 41.63717],
                    [-70.64, 41.475],
                    [-71.12039, 41.49445],
                    [-71.86, 41.32],
                    [-72.295, 41.27],
                    [-72.87643, 41.22065],
                    [-73.71, 40.931102],
                    [-72.24126, 41.11948],
                    [-71.945, 40.93],
                    [-73.345, 40.63],
                    [-73.982, 40.628],
                    [-73.952325, 40.75075],
                    [-74.25671, 40.47351],
                    [-73.96244, 40.42763],
                    [-74.17838, 39.70926],
                    [-74.90604, 38.93954],
                    [-74.98041, 39.1964],
                    [-75.20002, 39.24845],
                    [-75.52805, 39.4985],
                    [-75.32, 38.96],
                    [-75.071835, 38.782032],
                    [-75.05673, 38.40412],
                    [-75.37747, 38.01551],
                    [-75.94023, 37.21689],
                    [-76.03127, 37.2566],
                    [-75.72205, 37.93705],
                    [-76.23287, 38.319215],
                    [-76.35, 39.15],
                    [-76.542725, 38.717615],
                    [-76.32933, 38.08326],
                    [-76.989998, 38.239992],
                    [-76.30162, 37.917945],
                    [-76.25874, 36.9664],
                    [-75.9718, 36.89726],
                    [-75.86804, 36.55125],
                    [-75.72749, 35.55074],
                    [-76.36318, 34.80854],
                    [-77.397635, 34.51201],
                    [-78.05496, 33.92547],
                    [-78.55435, 33.86133],
                    [-79.06067, 33.49395],
                    [-79.20357, 33.15839],
                    [-80.301325, 32.509355],
                    [-80.86498, 32.0333],
                    [-81.33629, 31.44049],
                    [-81.49042, 30.72999],
                    [-81.31371, 30.03552],
                    [-80.98, 29.18],
                    [-80.535585, 28.47213],
                    [-80.53, 28.04],
                    [-80.056539, 26.88],
                    [-80.088015, 26.205765],
                    [-80.13156, 25.816775],
                    [-80.38103, 25.20616],
                    [-80.68, 25.08],
                    [-81.17213, 25.20126],
                    [-81.33, 25.64],
                    [-81.71, 25.87],
                    [-82.24, 26.73],
                    [-82.70515, 27.49504],
                    [-82.85526, 27.88624],
                    [-82.65, 28.55],
                    [-82.93, 29.1],
                    [-83.70959, 29.93656],
                    [-84.1, 30.09],
                    [-85.10882, 29.63615],
                    [-85.28784, 29.68612],
                    [-85.7731, 30.15261],
                    [-86.4, 30.4],
                    [-87.53036, 30.27433],
                    [-88.41782, 30.3849],
                    [-89.18049, 30.31598],
                    [-89.593831, 30.159994],
                    [-89.413735, 29.89419],
                    [-89.43, 29.48864],
                    [-89.21767, 29.29108],
                    [-89.40823, 29.15961],
                    [-89.77928, 29.30714],
                    [-90.15463, 29.11743],
                    [-90.880225, 29.148535],
                    [-91.626785, 29.677],
                    [-92.49906, 29.5523],
                    [-93.22637, 29.78375],
                    [-93.84842, 29.71363],
                    [-94.69, 29.48],
                    [-95.60026, 28.73863],
                    [-96.59404, 28.30748],
                    [-97.14, 27.83],
                    [-97.37, 27.38],
                    [-97.38, 26.69],
                    [-97.33, 26.21],
                    [-97.14, 25.87],
                    [-97.53, 25.84],
                    [-98.24, 26.06],
                    [-99.02, 26.37],
                    [-99.3, 26.84],
                    [-99.52, 27.54],
                    [-100.11, 28.11],
                    [-100.45584, 28.69612],
                    [-100.9576, 29.38071],
                    [-101.6624, 29.7793],
                    [-102.48, 29.76],
                    [-103.11, 28.97],
                    [-103.94, 29.27],
                    [-104.45697, 29.57196],
                    [-104.70575, 30.12173],
                    [-105.03737, 30.64402],
                    [-105.63159, 31.08383],
                    [-106.1429, 31.39995],
                    [-106.50759, 31.75452],
                    [-108.24, 31.754854],
                    [-108.24194, 31.34222],
                    [-109.035, 31.34194],
                    [-111.02361, 31.33472],
                    [-113.30498, 32.03914],
                    [-114.815, 32.52528],
                    [-114.72139, 32.72083],
                    [-115.99135, 32.61239],
                    [-117.12776, 32.53534],
                    [-117.295938, 33.046225],
                    [-117.944, 33.621236],
                    [-118.410602, 33.740909],
                    [-118.519895, 34.027782],
                    [-119.081, 34.078],
                    [-119.438841, 34.348477],
                    [-120.36778, 34.44711],
                    [-120.62286, 34.60855],
                    [-120.74433, 35.15686],
                    [-121.71457, 36.16153],
                    [-122.54747, 37.55176],
                    [-122.51201, 37.78339],
                    [-122.95319, 38.11371],
                    [-123.7272, 38.95166],
                    [-123.86517, 39.76699],
                    [-124.39807, 40.3132],
                    [-124.17886, 41.14202],
                    [-124.2137, 41.99964],
                    [-124.53284, 42.76599],
                    [-124.14214, 43.70838],
                    [-124.020535, 44.615895],
                    [-123.89893, 45.52341],
                    [-124.079635, 46.86475],
                    [-124.39567, 47.72017],
                    [-124.68721, 48.184433],
                    [-124.566101, 48.379715],
                    [-123.12, 48.04],
                    [-122.58736, 47.096],
                    [-122.34, 47.36],
                    [-122.5, 48.18],
                    [-122.84, 49]
                  ]
                ],
                [
                  [
                    [-140.985988, 69.711998],
                    [-140.986, 69.712],
                    [-140.9925, 66.00003],
                    [-140.99778, 60.30639],
                    [-140.013, 60.27682],
                    [-139.039, 60],
                    [-138.34089, 59.56211],
                    [-137.4525, 58.905],
                    [-136.47972, 59.46389],
                    [-135.47583, 59.78778],
                    [-134.945, 59.27056],
                    [-134.27111, 58.86111],
                    [-133.35556, 58.41028],
                    [-132.73042, 57.69289],
                    [-131.70781, 56.55212],
                    [-130.00778, 55.91583],
                    [-129.98, 55.285],
                    [-130.53611, 54.80278],
                    [-130.536109, 54.802754],
                    [-130.53611, 54.802753],
                    [-131.085818, 55.178906],
                    [-131.967211, 55.497776],
                    [-132.250011, 56.369996],
                    [-133.539181, 57.178887],
                    [-134.078063, 58.123068],
                    [-135.038211, 58.187715],
                    [-136.628062, 58.212209],
                    [-137.800006, 58.499995],
                    [-139.867787, 59.537762],
                    [-140.825274, 59.727517],
                    [-142.574444, 60.084447],
                    [-143.958881, 59.99918],
                    [-145.925557, 60.45861],
                    [-147.114374, 60.884656],
                    [-148.224306, 60.672989],
                    [-148.018066, 59.978329],
                    [-148.570823, 59.914173],
                    [-149.727858, 59.705658],
                    [-150.608243, 59.368211],
                    [-151.716393, 59.155821],
                    [-151.859433, 59.744984],
                    [-151.409719, 60.725803],
                    [-150.346941, 61.033588],
                    [-150.621111, 61.284425],
                    [-151.895839, 60.727198],
                    [-152.57833, 60.061657],
                    [-154.019172, 59.350279],
                    [-153.287511, 58.864728],
                    [-154.232492, 58.146374],
                    [-155.307491, 57.727795],
                    [-156.308335, 57.422774],
                    [-156.556097, 56.979985],
                    [-158.117217, 56.463608],
                    [-158.433321, 55.994154],
                    [-159.603327, 55.566686],
                    [-160.28972, 55.643581],
                    [-161.223048, 55.364735],
                    [-162.237766, 55.024187],
                    [-163.069447, 54.689737],
                    [-164.785569, 54.404173],
                    [-164.942226, 54.572225],
                    [-163.84834, 55.039431],
                    [-162.870001, 55.348043],
                    [-161.804175, 55.894986],
                    [-160.563605, 56.008055],
                    [-160.07056, 56.418055],
                    [-158.684443, 57.016675],
                    [-158.461097, 57.216921],
                    [-157.72277, 57.570001],
                    [-157.550274, 58.328326],
                    [-157.041675, 58.918885],
                    [-158.194731, 58.615802],
                    [-158.517218, 58.787781],
                    [-159.058606, 58.424186],
                    [-159.711667, 58.93139],
                    [-159.981289, 58.572549],
                    [-160.355271, 59.071123],
                    [-161.355003, 58.670838],
                    [-161.968894, 58.671665],
                    [-162.054987, 59.266925],
                    [-161.874171, 59.633621],
                    [-162.518059, 59.989724],
                    [-163.818341, 59.798056],
                    [-164.662218, 60.267484],
                    [-165.346388, 60.507496],
                    [-165.350832, 61.073895],
                    [-166.121379, 61.500019],
                    [-165.734452, 62.074997],
                    [-164.919179, 62.633076],
                    [-164.562508, 63.146378],
                    [-163.753332, 63.219449],
                    [-163.067224, 63.059459],
                    [-162.260555, 63.541936],
                    [-161.53445, 63.455817],
                    [-160.772507, 63.766108],
                    [-160.958335, 64.222799],
                    [-161.518068, 64.402788],
                    [-160.777778, 64.788604],
                    [-161.391926, 64.777235],
                    [-162.45305, 64.559445],
                    [-162.757786, 64.338605],
                    [-163.546394, 64.55916],
                    [-164.96083, 64.446945],
                    [-166.425288, 64.686672],
                    [-166.845004, 65.088896],
                    [-168.11056, 65.669997],
                    [-166.705271, 66.088318],
                    [-164.47471, 66.57666],
                    [-163.652512, 66.57666],
                    [-163.788602, 66.077207],
                    [-161.677774, 66.11612],
                    [-162.489715, 66.735565],
                    [-163.719717, 67.116395],
                    [-164.430991, 67.616338],
                    [-165.390287, 68.042772],
                    [-166.764441, 68.358877],
                    [-166.204707, 68.883031],
                    [-164.430811, 68.915535],
                    [-163.168614, 69.371115],
                    [-162.930566, 69.858062],
                    [-161.908897, 70.33333],
                    [-160.934797, 70.44769],
                    [-159.039176, 70.891642],
                    [-158.119723, 70.824721],
                    [-156.580825, 71.357764],
                    [-155.06779, 71.147776],
                    [-154.344165, 70.696409],
                    [-153.900006, 70.889989],
                    [-152.210006, 70.829992],
                    [-152.270002, 70.600006],
                    [-150.739992, 70.430017],
                    [-149.720003, 70.53001],
                    [-147.613362, 70.214035],
                    [-145.68999, 70.12001],
                    [-144.920011, 69.989992],
                    [-143.589446, 70.152514],
                    [-142.07251, 69.851938],
                    [-140.985988, 69.711998],
                    [-140.985988, 69.711998]
                  ]
                ],
                [
                  [
                    [-155.54211, 19.08348],
                    [-155.68817, 18.91619],
                    [-155.93665, 19.05939],
                    [-155.90806, 19.33888],
                    [-156.07347, 19.70294],
                    [-156.02368, 19.81422],
                    [-155.85008, 19.97729],
                    [-155.91907, 20.17395],
                    [-155.86108, 20.26721],
                    [-155.78505, 20.2487],
                    [-155.40214, 20.07975],
                    [-155.22452, 19.99302],
                    [-155.06226, 19.8591],
                    [-154.80741, 19.50871],
                    [-154.83147, 19.45328],
                    [-155.22217, 19.23972],
                    [-155.54211, 19.08348]
                  ]
                ],
                [
                  [
                    [-156.07926, 20.64397],
                    [-156.41445, 20.57241],
                    [-156.58673, 20.783],
                    [-156.70167, 20.8643],
                    [-156.71055, 20.92676],
                    [-156.61258, 21.01249],
                    [-156.25711, 20.91745],
                    [-155.99566, 20.76404],
                    [-156.07926, 20.64397]
                  ]
                ],
                [
                  [
                    [-156.75824, 21.17684],
                    [-156.78933, 21.06873],
                    [-157.32521, 21.09777],
                    [-157.25027, 21.21958],
                    [-156.75824, 21.17684]
                  ]
                ],
                [
                  [
                    [-157.65283, 21.32217],
                    [-157.70703, 21.26442],
                    [-157.7786, 21.27729],
                    [-158.12667, 21.31244],
                    [-158.2538, 21.53919],
                    [-158.29265, 21.57912],
                    [-158.0252, 21.71696],
                    [-157.94161, 21.65272],
                    [-157.65283, 21.32217]
                  ]
                ],
                [
                  [
                    [-159.34512, 21.982],
                    [-159.46372, 21.88299],
                    [-159.80051, 22.06533],
                    [-159.74877, 22.1382],
                    [-159.5962, 22.23618],
                    [-159.36569, 22.21494],
                    [-159.34512, 21.982]
                  ]
                ],
                [
                  [
                    [-153.006314, 57.115842],
                    [-154.00509, 56.734677],
                    [-154.516403, 56.992749],
                    [-154.670993, 57.461196],
                    [-153.76278, 57.816575],
                    [-153.228729, 57.968968],
                    [-152.564791, 57.901427],
                    [-152.141147, 57.591059],
                    [-153.006314, 57.115842]
                  ]
                ],
                [
                  [
                    [-165.579164, 59.909987],
                    [-166.19277, 59.754441],
                    [-166.848337, 59.941406],
                    [-167.455277, 60.213069],
                    [-166.467792, 60.38417],
                    [-165.67443, 60.293607],
                    [-165.579164, 59.909987]
                  ]
                ],
                [
                  [
                    [-171.731657, 63.782515],
                    [-171.114434, 63.592191],
                    [-170.491112, 63.694975],
                    [-169.682505, 63.431116],
                    [-168.689439, 63.297506],
                    [-168.771941, 63.188598],
                    [-169.52944, 62.976931],
                    [-170.290556, 63.194438],
                    [-170.671386, 63.375822],
                    [-171.553063, 63.317789],
                    [-171.791111, 63.405846],
                    [-171.731657, 63.782515]
                  ]
                ]
              ]
            }
          },
    ],
    "bbox": [-180, -90, 180, 83.64513]
  }
  
  export default countries