import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Row, Col, Container, Tabs, Tab, Card } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SocialImg from '../../Assets/images/social-img/social.webp'
import SocialImg2 from '../../Assets/images/social-img/social2.webp'
import SocialImg3 from '../../Assets/images/social-img/social3.webp'
import SocialImg4 from '../../Assets/images/social-img/social4.webp'
import TabImg1 from '../../Assets/images/social-img/tab1.webp'
import TabImg2 from '../../Assets/images/social-img/tab2.webp'
import TabImg3 from '../../Assets/images/social-img/tab3.webp'
import TabImg4 from '../../Assets/images/social-img/tab4.webp'
import SocialIconImg1 from '../../Assets/images/social-img/instagram.webp'
import SocialIconImg2 from '../../Assets/images/social-img/tiktok.webp'
import SocialIconImg3 from '../../Assets/images/social-img/pintrest.webp'
import SocialIconImg4 from '../../Assets/images/social-img/facebook.webp'

import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";

import BreadCum from "../Ui/breadCum";
import '../../Assets/css/ui.css'
import PlanCard from "../Ui/planCard";
import ReviewSlider from "../Front/ReviewSlider";
import ConsultCard from "../Ui/consultCard";
const SocialMediaMarketingForJewelers = () => {
  const [key, setKey] = useState('home');
  const [expanded, setExpanded] = React.useState("panel1");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const tabsData = [
    {
      eventKey: 'home',
      title: 'Custom Strategy Development',
      heading: 'Custom Strategy Development',
      content: 'Belgium WebNet exists mainly to help jewelry businesses develop unique relationships with their target audience on different social media platforms. That\'s why we develop the social media strategy as per your individual brand, explore the target audience through in-depth market research and competitor analysis, and set clear goals for your online success. We develop strategies based on content themes, posting schedules, methods to engage, and ways to enhance your reach to maximize your involvement.',
      image: TabImg1,
    },
    {
      eventKey: 'Content',
      title: 'Content Creation and Management',
      heading: 'Content Creation and Management',
      content: 'The content we design for your marketing includes posts on jewelry images, behind-the-scenes content, user-generated content, customer reviews, and promotions. We design content to ensure it is high quality and consistent on different social media platforms, ensuring that the brand message we are trying to spread remains the same as we move from one social media platform to another.',
      image: TabImg2,
    },
    {
      eventKey: 'Social',
      title: 'Social Media Advertising',
      heading: 'Social Media Advertising',
      content: 'We use Facebook and Instagram ads to determine your target audience. In these ad campaigns, we target your audience through age, location, interests, and many other factors to ensure that your ads reach the right people. With well-planned Google Ads, we drive targeted traffic to your site with strategic advertising.',
      image: TabImg3,
    },
    {
      eventKey: 'Analytics',
      title: 'Analytics and Reporting',
      heading: 'Analytics and Reporting',
      content: 'We do detailed analysis, monthly of the engagement, click-through, and conversion rates, among other key metrics. From here, we extract the insights that will help strategically shift the content, targeting, or ad spend to ensure betterment in your online presence.',
      image: TabImg4,
    },
  ];


  const platformsData = [
    {
      img: SocialIconImg1,
      title: "Instagram Marketing for Jewelers",
      content: "Instagram is a platform with great potential as it gives the brand an opportunity to interact with your highly visual audience and drive in sales. We devise monthly calendars and posts to run on your Instagram handle to help you build a relationship with your audience. We focus on Instagram ads to push more traffic to the site and bring in more sales."
    },
    {
      img: SocialIconImg2,
      title: "Tiktok Marketing for Jewelers",
      content: "TikTok can be considered a remarkable platform for engaging your visual audience in driving sales. We create tailored content calendars and compelling posts for your TikTok account. We help you develop an authentic relationship with your followers. Whether leveraging TikTok advertisements to increase website traffic and prompt sales, our efforts will maximize the visibility and impact of your brand on this platform."
    },
    {
      img: SocialIconImg4,
      title: "Facebook Marketing for Jewelers",
      content: "Facebook Marketing is a great way to channel community building and better customer engagement. We maintain a regular posting schedule, use Facebook analytics to understand the right time for posting, and know the right kind of content to post so as to get as much traffic and customer engagement as possible. Moreover, Facebook ads help us engage with the customers who are looking for your products, resulting in more leads and sales."
    },
    {
      img: SocialIconImg3,
      title: "Pinterest Marketing for Jewelers",
      content: "Ours is a pure-play visual storytelling strategy, where every pin is of the highest quality to capture and grip users and actively sell inspiration and ideas. We also emphasize creating optimized pin descriptions and keywords to make these pins visible through the Pinterest search engine."
    }
  ];

  const ContentSection = ({ imgSrc, title, content, reverse }) => (
    <Row className="mb-30">
      <Col sm={12} md={6} lg={6} className={`my-auto ${reverse ? 'order-md-2' : ''}`}>
        <div className="image-cover">
          <LazyLoadImage
            src={imgSrc}
            alt={title}
            className="img-fluid mb-xs-2"
          />
        </div>
      </Col>
      <Col sm={12} md={6} lg={6} className="my-auto">
        <div className={`${reverse ? 'pr-0 pr-lg-5' : 'pl-0 pl-lg-5'}`}>
          <h2 className="head-3">{title}</h2>
          <p className="para-2 text-grey">{content}</p>
        </div>
      </Col>
    </Row>
  );


  const TabContentSection = ({ heading, content, image }) => (
    <Row className="mx-0">
      <Col md={6} className="my-auto">
        <div className="pr-0 pr-lg-5">
          <h3 className="text-white head-3">{heading}</h3>
          <p className="mb-0 text-grey-1 para-2">{content}</p>
        </div>
      </Col>
      <Col md={6} className="my-auto">
        <div className="image-cover">
          <LazyLoadImage
            src={image}
            alt="Tab content"
            className="img-fluid mb-xs-2"
          />
        </div>
      </Col>
    </Row>
  );

  const renderPlatformCard = (platform, index) => (
    <Col xs={12} className="mb-4" key={index}>
      <Card className="socialCard border-0 p-3">
        <Card.Body>
          <div className="d-flex align-items-center mb-3">
            <div className="image-cover w-80 mr-3 flex-none">
              <LazyLoadImage
                src={platform.img}
                alt="Social Media Marketing"
                className="img-fluid mb-xs-2"
              />
            </div>
            <h3 className="head-5">{platform.title}</h3>
          </div>
          <p className="text-grey">{platform.content}</p>
        </Card.Body>
      </Card>
    </Col>
  );

  return (

    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Social Media Marketing for Jewelers | Belgium Webnet
        </title>
        <meta
          name="description"
          content="Boost your jewelry business with expert social media marketing. Discover customized strategies and services for jewelers. Contact us today!"
        ></meta>
        <meta name="keywords" content="social media marketing for jewelry,  best social media marketing company for jewelers"></meta>
      </Helmet>


      <section className="bw_wdd_section">
        <div className="bw_all_banner bw_wdd_banner">
          <Container className="bw_custome_container">
            <Row>
              <Col>
                <BreadCum
                  head={"Social Media Marketing for Jewelers"}
                  desc={"Boost your jewelry business with expert social media marketing. Discover customized strategies and services for jewelers. Contact us today!"}
                />

              </Col>
            </Row>
          </Container>
        </div>

        <div className="bw_website_desing">
          <Container className="bw_custome_container">
            <Row>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="bw_website_deigns_innerbox1"
              >
                <div>
                  <div>
                    <h2>   Social Media Marketing  for Jewelers  </h2>
                    <p>
                      Social media marketing has become an important platform for jewelers to
                      promote their brands. It does so through high-quality images, photos, and
                      video interaction in the manufacturing of products and their promotion to
                      prospective customers. It does not only increase the number of visits to
                      your webpage but also leads to increased sales. Instagram, Facebook,
                      Pinterest, YouTube, and TikTok all cater to various types of audiences.
                      All you need to do in order to succeed is choose the platform best
                      suited to your target brand for maximum optimal results.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className="bw_wdd_img_right_box">
                <div>
                  <LazyLoadImage
                    src={SocialImg}
                    alt="Web Development &amp; design image"
                    className="img-fluid mb-xs-2"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        <section className="bg-grey">
          <Container className="py-100">
            <div className="text-center mb-30">
              <h2 className="head-2">Why Social Media Marketing is Essential for Jewelers</h2>
              <p className="para-2 mx-w-900 mx-auto text-grey">
                Social media marketing is essential for jewelers and jewelry store owners because it helps with better brand visibility,
                drives traffic to stores, and boosts customer engagement, leading to increased sales and loyalty.
              </p>
            </div>

            <ContentSection
              imgSrc={SocialImg2}
              title="Social Media Marketing for Jewelers"
              content="Social media marketing has become an important platform for jewelers to promote their brands. It does so through high-quality images, photos, and video interaction in the manufacturing of products and their promotion to prospective customers. It does not only increase the number of visits to your webpage but also leads to increased sales. Instagram, Facebook, Pinterest, YouTube, and TikTok all cater to various types of audiences. All you need to do in order to succeed is choose the platform best suited to your target brand for maximum optimal results."
            />

            <ContentSection
              imgSrc={SocialImg3}
              title="Drive More Traffic to Your Store"
              content="When you have well-planned social media marketing strategies, it can help you attract more customers to both your physical and online shops. This can be done by promoting the brand to its complete potential on different social media websites, including, but not limited to, Instagram, Facebook, Pinterest, and YouTube. A well-strategized content that can ignite interaction will lead your followers to your website, thus increasing foot traffic in your physical store and more sales on your online front."
              reverse
            />

            <ContentSection
              imgSrc={SocialImg4}
              title="Engage with Your Audience"
              content="When used correctly, social media operates as a great tool for customer interaction and engagement. 76% of consumers are likely to buy a product from a brand after coming through it on social media. Social media makes it very easy to share posts, images, and your brand's messages through videos to help you target the right audience and embrace a large number of people."
            />
          </Container>
        </section>



        <section className="bg-black">
          <Container className="py-100">
            <Row className="mb-50">
              <Col md={6} className="border-right-1 my-auto">
                <h2 className="head-4 text-white">
                  Our Social Media Marketing Services for Jewelers
                </h2>
              </Col>
              <Col md={6} className="my-auto text-grey-1">
                <p className="mb-0 para-1">
                  At Belgium WebNet, we offer personalized social media management service for jewelers worldwide. Our services are targeted towards building brand awareness and visibility for your brand in the competitive market.
                </p>
              </Col>
            </Row>

            <Row className="tab_bar">
              <Tabs
                defaultActiveKey="home"
                id="uncontrolled-tab-example"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="mb-3 bg-grey-1 border-0 p-2 w-100"
              >
                {tabsData.map(tab => (
                  <Tab eventKey={tab.eventKey} title={tab.title} key={tab.eventKey}>
                    <TabContentSection
                      heading={tab.heading}
                      content={tab.content}
                      image={tab.image}
                    />
                  </Tab>
                ))}
              </Tabs>
            </Row>
          </Container>
        </section>


        <section>
          <Container className="my-100">
            <Row>
              <Col xs={12} className="mb-30">
                <h2 className="head-2 text-center">Platforms We Specialize In</h2>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <Row>
                  {platformsData.slice(0, 2).map((platform, index) => renderPlatformCard(platform, index))}
                </Row>
              </Col>
              <Col md={6}>
                <Row>
                  {platformsData.slice(2, 4).map((platform, index) => renderPlatformCard(platform, index))}
                </Row>
              </Col>
            </Row>
          </Container>
        </section>


        <PlanCard />
        <ReviewSlider />


        <div className="bw_faq_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  Frequently Asked Questions
                </h2>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col className="px-md-0">
                <div
                  className="bw_wdd_faq_accordian"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        <h3>  How soon will I see the results? </h3>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Every social media platform has a specific timeline for acquiring results; it all depends on your target audience, business goals, and consistency. But with good social media strategy, you can expect results to be evident in 3 to 6 months. It is very important to realize and take into account that social media marketing for jewelry is a long-term project, so it will take time to show results.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        <h3>   What platforms should I focus on for my jewelry business? </h3>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Depending on your goals, you should give an equal focus to each of the social media platforms. Instagram and Pinterest are both used for the visually active customer. Facebook is an ideal tool to build a community, and YouTube will help you educate your customers on your products.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        <h3>How do you measure success?  </h3>
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        To be wildly successful on social media, keep track of engagement metrics (likes, comments, shares), reach (number of followers, number of impressions), conversion rates (click-through rate, sales), and overall impact on your business goals.

                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>


        <ConsultCard head={"Get Started with Our Social Media Marketing Services"}
          desc={"Whether you aim for better reach, more leads, or sales, we have the strategy to make it happen. Contact us today to learn more about our plans and understand social media for jewelers from our experts."}
          btnText={"Book Your Free Consultation"}
        />

      </section>
    </>
  );
};

export default React.memo(SocialMediaMarketingForJewelers);
