import React, { useRef } from "react";
import "../../Assets/css/contactus.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import "react-phone-input-2/lib/style.css";
import Helmet from "react-helmet";
import { baseURL, postHeader } from "../../Helpers/request";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import { useState } from "react";

const PackageFormPayment = () => {
 const captchaRef = useRef(null)
  const [name, setname] = React.useState("");
  const [firstname,setFirstname]=useState("");
  const [lastname,setLastname]=useState("");
  const [phone,setPhone]=useState("");
  const [cardnumber, setcardnumber] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [month, setmonth] = React.useState("");
  const [plan, setPlan] = React.useState('');
  const [cvc, setcvc] = React.useState('');
  
  const [year, setyear] = React.useState('');
  const [load, setLoad] = React.useState(false);
  const [captcha,setCaptcha] = React.useState(true)
  function onChange(value) {
    if(value){
      setCaptcha(false)
    }
  }




  const stripePay=(e)=>{

   
    window.Stripe.setPublishableKey("pk_test_51Hf6ViFngcuk5Ha0Mc7yBo9s7uZmlgTOmtwaNtQABwFH89tRNw3Sg887W4eyC7h37IaDuWq7QcIMCPSd9rfqEOjp00t6KbsUIp");
    window.Stripe.createToken({
      number: cardnumber,
      cvc: cvc,
      exp_month: month,
      exp_year: year
  }, stripeResponseHandler);

  //submit from callback
  return false;
  }


function stripeResponseHandler(status, response) {
  if (response.error) {
      // $("#error-message").html(response.error.message).show();
  } else {
      //get token id
      var token = response['id'];


    const data={
      first_name: firstname,
      last_name: lastname,
      email: email,
      phone: phone,
      amount: Number(300 * 100),
      plan_name: "Basic",
      plan_id: "51",
      token: token
      
  }

    axios.post(`${baseURL}/stripepay/payment`, data, { headers: postHeader })
    .then((res) => {
      console.log(res);
    })
 
  }
}
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Belgium WebNet-Package Features</title>
        <meta name="description" content=""></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_contactus_section position-relative">
        <div className="bw_all_banner bw_contact_banner bw_package_form_banner">
          <Container>

            <Row>
              <Col>
                <h1 className="text-center text-white">Book An Appointments</h1>
                <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="/" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/contacts" className="text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
            <div>



</div>
          </Container>
        </div>
        <div className="bw_new_position_box bw_contact_position mb-5">
          <Container>
            <Row className="w-100 m-auto">
              <Col className="px-0">
                <div
                  className="bw_contact_form_outer_box"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h2>Select Your Services Here</h2>
                  <div className="bw_contact_form_inner_box">
                    <div
                      className="bw_contact_us_form"
                    >
                      <Row>
                      <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number*</Form.Label>
                            <PhoneInput
                              disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={(e) => setPhone(e)}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                            />
                          </Form.Group>
                        </Col>

                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Card Holder Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Card Holder Name"
                              value={name}
                              onChange={(e) => setname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                      
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Card Number*</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="Card Number"
                              value={cardnumber}
                              onChange={(e) =>setcardnumber(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                        <Row>
                        <Col sm={12} md={4} lg={4}>
                        <Form.Label>Expiry Month</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="mb-3"
                             value={month}
                              onChange={(e) => setmonth(e.target.value)}
                          >
                                 <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                          </Form.Select>
                        </Col>

                        <Col sm={12} md={4} lg={4}>
                        <Form.Label>Expiry Year</Form.Label>
                          <Form.Select
                            aria-label="Default select example"
                            className="mb-3"
                             value={year}
                              onChange={(e) => setyear(e.target.value)}
                          >
                           <option value="21">2021</option>
                            <option value="22">2022</option>
                            <option value="23">2023</option>
                            <option value="24">2024</option>
                            <option value="25">2025</option>
                            <option value="26">2026</option>
                            <option value="27">2027</option>
                            <option value="28">2028</option>
                            <option value="29">2029</option>
                            <option value="30">2030</option>
                          </Form.Select>
                        </Col>

                        <Col sm={12} md={4} lg={4}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>CVC*</Form.Label>
                            <Form.Control
                              type="number"
                              placeholder="CVC"
                              value={cvc}
                              onChange={(e) =>setcvc(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        </Row>
                     
                        </Col>
                     
                      
                      </Row>
                      <Row>
                      <button  onClick={()=>stripePay()}>Submit</button> 
                      </Row>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
         
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(PackageFormPayment);
