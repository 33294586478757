import React from "react";
import "../../Assets/css/integrations.css";
import Helmet from "react-helmet";
import { Row, Col, Container, Image } from "react-bootstrap";
import IntBannerImg from "../../Assets/images/integrations/int-group-icons.webp";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import IntPro0 from "../../Assets/images/integrations/int-pro0.png";
import IntPro1 from "../../Assets/images/integrations/int-pro1.png";
import IntPro2 from "../../Assets/images/integrations/int-pro2.png";
import IntPro3 from "../../Assets/images/integrations/int-pro3.png";
import IntPro4 from "../../Assets/images/integrations/int-pro4.png";
import IntPro5 from "../../Assets/images/integrations/int-pro5.png";
import IntPro6 from "../../Assets/images/integrations/int-pro6.png";
import IntPro7 from "../../Assets/images/integrations/int-pro7.png";
import IntPro8 from "../../Assets/images/integrations/int-pro8.png";
// import IntPro9 from "../../Assets/images/integrations/int-pro9.png";
import IntPro10 from "../../Assets/images/integrations/int-pro10.png";
import IntPro11 from "../../Assets/images/integrations/int-pro11.jpg";
import IntPro12 from "../../Assets/images/integrations/int-pro12.png";
import IntPro13 from "../../Assets/images/integrations/int13.png";
import IntPro14 from "../../Assets/images/integrations/int14.png";
import IntPro15 from "../../Assets/images/integrations/int-pro6.png";
import IntPro16 from "../../Assets/images/integrations/int16.png";
import IntPro17 from "../../Assets/images/integrations/int17.png";
import IntPro18 from "../../Assets/images/integrations/int18.png";
import IntPro19 from "../../Assets/images/integrations/int19.png";
import IntPro20 from "../../Assets/images/integrations/int20.png";
import IntPro21 from "../../Assets/images/integrations/int21.png";
import IntPro22 from "../../Assets/images/integrations/int22.png";
import IntPro23 from "../../Assets/images/integrations/int23.png";
import IntPro24 from "../../Assets/images/integrations/int24.png";
import IntPro25 from "../../Assets/images/integrations/int25.png";
import IntPro26 from "../../Assets/images/integrations/gnf/logo.png";

import crGemsImg from "../../Assets/images/integrations/cr-gems.gif";

import Tabimg1 from "../../Assets/images/integrations/green-monitore.png";
import Tabimg2 from "../../Assets/images/integrations/green-desktop.png";
import Tabimg3 from "../../Assets/images/integrations/green-light-bulb.png";

import MarketingImg1 from "../../Assets/images/integrations/social-img/ga.png";
import MarketingImg2 from "../../Assets/images/integrations/social-img/gr.png";
import MarketingImg3 from "../../Assets/images/integrations/social-img/yelp.png";
import MarketingImg4 from "../../Assets/images/integrations/social-img/abc.png";
import MarketingImg5 from "../../Assets/images/integrations/social-img/klaviyo-logo.png";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { NavLink, useHistory } from "react-router-dom";
import { useState } from "react";
import Simg1 from '../../Assets/images/integrations/social-img/fb.png'
import Simg2 from '../../Assets/images/integrations/social-img/insta.png'
import Simg3 from '../../Assets/images/integrations/social-img/linkedin.png'
import Simg4 from '../../Assets/images/integrations/social-img/pint.png'
// tabs
function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

// tabs

const ProductData = [
  {
    image: `${IntPro26}`,
    name: "guildfacet",
    url: "/guildfacet",
    target: "same"
  },
  {
    image: `${IntPro15}`,
    name: "nivoda",
    url: "/nivoda",
    target: "same"
  },
  {
    image: `${crGemsImg}`,
    name: "  cr-gems",
    url: "/cr-gems",
    target: "same"
  },
  {
    image: `${IntPro0}`,
    name: "CARDKNOX",
    url: "https://www.cardknox.com/partners/belgiumwebnet/",
  },
  {
    image: `${IntPro3}`,
    name: "STRIPE",
    url: "#",
  },
  {
    image: `${IntPro12}`,
    name: "  SQUARE",
    url: "#",
  },
  {
    image: `${IntPro17}`,
    name: "  PAYPAL",
    url: "#",
  },
  {
    image: `${IntPro18}`,
    name: "  WORLDPLAY",
    url: "#",
  },
  {
    image: `${IntPro19}`,
    name: "AUTHORIZE.NET",
    url: "#",
  },
  {
    image: `${IntPro20}`,
    name: "  PAYL8R",
    url: "#",
  },
  {
    image: `${IntPro21}`,
    name: "  AFFIRM",
    url: "#",
  },
  {
    image: `${IntPro11}`,
    name: "QUALITY GOLD",
    url: "#",
  },
  {
    image: `${IntPro1}`,
    name: "QUALITY GOLD",
    url: "#",
  },
  // {
  //   image: `${IntPro2}`,
  //   name: "UNIQUE SETTINGS",
  //   url: "#",
  // },

  {
    image: `${IntPro4}`,
    name: "RAPNET",
    url: "#",
  },
  {
    image: `${IntPro5}`,
    name: "STUller",
    url: "#",
  },

  {
    image: `${IntPro7}`,
    name: "IDEX",
    url: "#",
  },
  {
    image: `${IntPro8}`,
    name: "VDB",
    url: "#",
  },
  // {
  //   image: `${IntPro9}`,
  //   name: "MID DIAMONDS",
  //   url: "#",
  // },
  {
    image: `${IntPro10}`,
    name: "POLYGON",
    url: "#",
  },


  {
    image: `${IntPro13}`,
    name: "  SQUARE",
    url: "#",
  },
  {
    image: `${IntPro14}`,
    name: "  SQUARE",
    url: "#",
  },
 
  {
    image: `${IntPro25}`,
    name: "  THE DIAMOND PORT",
    url: "#",
  },





  {
    image: `${IntPro22}`,
    name: "  TIDIO",
    url: "#",
  },
  {
    image: `${IntPro23}`,
    name: "  PODIUM",
    url: "#",
  },
  {
    image: `${IntPro24}`,
    name: "  ADA",
    url: "#",
  },

];

const Integrations = () => {
  const [value, setValue] = React.useState(0);
const history = useHistory()
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  // const [loadmoreProduct, setLoadmoreProduct] = useState(8)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Integrations | API Services | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Develop a B2B website for your jewelry business with Belgium WebNet. Schedule a consultation call with our experts for impactful results and growth now!"
        ></meta>
        <meta name="keywords" content="b2b website development company"></meta>
      </Helmet>
      <div className="bw_integration_section">
        <div className="bw_all_banner bw_integration_banner">
          <Container className="bw_custome_container_banner">
            <Row>
              <Col sm={12} md={6} lg={6} className="bw_int_banner_heading_box">
                <div>
                  <h1>Integrations</h1>
                  <p>
                    We have integrated with various third-party platforms,
                    jewelry manufacturers, and API services. If you are
                    interested in integrating your services into the framework
                    of our website, get in touch with us.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6}>
                <div>
                  <LazyLoadImage
                    src={IntBannerImg}
                    className="img-fluid"
                    alt="Integration Banner Image"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_int_tab_section">
          <Container className="bw_custome_container_banner">
            <Row>
              <Col>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab
                    label="Products"
                    {...a11yProps(0)}
                    icon={
                      <LazyLoadImage
                        src={Tabimg1}
                        className="bw_int_tab_img"
                        alt="Product Image"
                      />
                    }
                  />
                  <Tab
                    label="Social Media"
                    {...a11yProps(1)}
                    icon={
                      <LazyLoadImage
                        src={Tabimg2}
                        className="bw_int_tab_img"
                        alt=" Social Media Image"
                      />
                    }
                  />
                  <Tab
                    label="Marketing"
                    {...a11yProps(2)}
                    icon={
                      <LazyLoadImage
                        src={Tabimg3}
                        className="bw_int_tab_img"
                        alt="Marketing Image"
                      />
                    }
                  />
                </Tabs>
                <TabPanel
                  value={value}
                  index={0}
                  className="mb-2"
                >
                  <Row className="bw_int_image_tab1">
                    {ProductData.map((res,i) => (
                      <Col sm={12} md={6} lg={3} key={i}>
                        <div className="bw_int_product_box"
                        onClick={() =>  res.target=="same" ?
                        history.push(res.url) :  window.open(res.url, "_blank")
                      }
                        >
                          <div className="bw_int_pro_img_box bw_new_img_box">
                            {
                              res.target=="same" ?
                              <NavLink
                              to={res.url}
                            >
                              <LazyLoadImage
                                src={res.image}
                                alt="Integation product Logo"
                                className="img-fluid"
                              />

                            </NavLink> :   <NavLink
                              to="#"
                              onClick={() => res.url != "#" ?
                                window.open(res.url, "_blank") : ""
                              }
                            >
                              <LazyLoadImage
                                src={res.image}
                                alt="Integation product Logo"
                                className="img-fluid"
                              />

                            </NavLink>
                            }
                          
                          </div>
                         
                        </div>
                      </Col>
                    ))}
                    {/* <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro2}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          UNIQUE SETTINGS
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro3}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          BENCHMARK
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro4}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          RAPNET
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro5}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          STUller
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro6}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          NIVODA
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro7}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          IDEX
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro8}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          VDB
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro9}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          MID DIAMONDS
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro10}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          POLYGON
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro3}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          STRIPE
                        </NavLink>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={IntPro12}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                        <NavLink to="#" className="bw_int_product_btn">
                          SQUARE
                        </NavLink>
                      </div>
                    </Col> */}
                  </Row>
                  {/* <Row className="bw_loade_more_design_btn_desktop_view">
                      <Col>
                          <div className="bw_btb_border_black text-center m-auto pb-5 pt-4">
                              {loadmoreProduct==8 ? <button type="button" className="bw_custom_btn text-uppercasee bw_btn_load_more btn btn-primary" onClick={()=>setLoadmoreProduct(ProductData.length)}>LOAD MORE</button>
                              : <button type="button" className="bw_custom_btn text-uppercasee bw_btn_load_more btn btn-primary" onClick={()=>{setLoadmoreProduct(8);window.scrollTo(0, 1000 );}}>LESS MORE</button>}
                            </div>
                      </Col>
                    </Row> */}
                </TabPanel>
                <TabPanel
                  value={value}
                  index={1}
                  className="bw_int_tab_social mb-5"
                >
                  <Row>
                    <Col sm={12} md={6} lg={3}>
                      <div
                        className="bw_int_product_box px-0 pt-0"
                        onClick={() =>
                          window.open(
                            "https://www.facebook.com/belgiumwebnet/",
                            "_blank"
                          )
                        }
                      >
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <Image src={Simg1} alt="Facebook Image" />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div
                        className="bw_int_product_box px-0 pt-0"
                        onClick={() =>
                          window.open(
                            "https://www.instagram.com/belgium_webnet/",
                            "_blank"
                          )
                        }
                      >
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <Image src={Simg2} alt="instagram Image" />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#" onClick={() =>
                            window.open(
                              "https://www.linkedin.com/company/belgium-webnet/?original_referer=http%3A%2F%2F192.168.1.127%3A3000%2F",
                              "_blank"
                            )
                          }>
                            <Image src={Simg3} alt="Linkedin Image" />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <Image src={Simg4} alt=" pintrest Image" />
                          </NavLink>
                        </div>
                      </div>
                    </Col>

                  </Row>
                </TabPanel>
                <TabPanel
                  value={value}
                  index={2}
                  className="bw_int_tab_marketing mb-5"
                >
                  <Row>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box  px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={MarketingImg1}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box  px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={MarketingImg2}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box  px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={MarketingImg3}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box  px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={MarketingImg4}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                    <Col sm={12} md={6} lg={3}>
                      <div className="bw_int_product_box  px-0 pt-0">
                        <div className="bw_int_pro_img_box">
                          <NavLink to="#">
                            <LazyLoadImage
                              src={MarketingImg5}
                              alt="Integation product Logo"
                              className="img-fluid"
                            />
                          </NavLink>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPanel>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
};

export default React.memo(Integrations);
