import React from 'react'
import { Container } from 'react-bootstrap'
import Check from '../../Assets/images/check.png';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Thankyou = (props) => {
  return (
    <div className='thankyou-wrapper'>
        <Container className='text-center'>
            <img src={Check} alt='check' />
            <h1>Thank You</h1>
            <p>Thanks for reaching out!</p>
            {/* <p>Your message just showed up in my inbox. Talk to you soon!</p> */}
            <Link to="/" className="bw_btn_price_start btn btn-primary mt-lg-5 mt-4">Back to Home</Link>
        </Container>
    </div>
  )
}

export default Thankyou