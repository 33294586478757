import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import PageNotFoundImage from "../../Assets/images/home/404.webp";
import { Helmet } from "react-helmet";
import "../../Assets/css/pageNotFound.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

const PageNotFound = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Belgium WebNet- Error Page</title>
        <meta name="description" content="Page Not Found."></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_pagenot_found_section bw-404">
        <div className="bw_all_banner bw_contact_banner bw_banner_page_notfound">
    
        </div>
       
      </section>
       <div className="bw_page_not_found_img mb-5 ">
          <Container>
            <Row className="w-100 m-auto text-center">
              <Col>
                <LazyLoadImage
                  src={PageNotFoundImage}
                  alt="404 Error Image"
                  className="img-fluid bw_page_not_found_img_width"
                />
              </Col>
            </Row>
          </Container>
        </div>
    </>
  );
};

export default React.memo(PageNotFound);
