import React, { useRef } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import banner from "../../Assets/images/home/banner_lap.webp";
import bannerMobileView from "../../Assets/images/home/mobile-banner.webp";

import { LazyLoadImage } from "react-lazy-load-image-component";

const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

const Banner = () => {
  const myRef = useRef(null);
  const executeScroll = () => scrollToRef(myRef);
  const history = useHistory();
  // function handleClick() {
  //   history.push("/diamond-jewelry-web-development");
  // }

  return (
    <>
      <section className="bw_banner_section">
        <Container className="bw_custome_container_banner">
          <div className="bw_banner_content">
            <Row className="w-100 m-auto align-items-center">
              <Col
                lg={5}
                md={12}
                xs={12}
                className="order-md-2 order-xs-2  order-lg-0"
              >
                <div className="bw_banner_content_inner">
                  <h1>
                    Let’s Eliminate The{" "}
                    <strong>
                      Physical <span>Barrier </span>
                    </strong>{" "}
                    Between You & Your Customers{" "}
                  </h1>
                  <p>
                    A physical store alone cannot convey the magnitude of your
                    brand, that is why you need a virtual store with an
                    incredible marketing prospect, and that is where we come in.{" "}
                  </p>
                  <div className="bw_btb_border_white">
                    <Button
                      className="bw_custom_btn"
                      onClick={() => history.push("/b2c-pricing")}
                    >
                      Get Started
                    </Button>
                  </div>
                </div>
              </Col>
              <Col lg={7} md={10} xs={12} className="m-md-auto">
                <div className="bw_banner_img_main position-relative">
                  <LazyLoadImage
                    src={bannerMobileView}
                    alt="banner img"
                    className="bw_banner_image_desktopview"
                  />
                  <LazyLoadImage
                    src={bannerMobileView}
                    alt="banner img Mobile View"
                    className="bw_banner_mobile_view"
                  />
                  {/* <div className="bw_home_banner_iframe_box">
                    <iframe
                      src="https://showcase.demobw.com/"
                      title="Belgium Webnet Showcase"
                      width="100%"
                      style={{ border: "none" }}
                      allow="autoplay"
                      allowFullScreen
                    ></iframe>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
          <div className="bw_scroll_sec">
            <div className="bw_scroll-down" onClick={executeScroll}></div>
            <p>Scroll to explore</p>
          </div>
        </Container>
      </section>

  
      <div ref={myRef}></div>
    </>
  );
};

export default React.memo(Banner);
