import React from 'react'
import { Container } from 'react-bootstrap'
import { Link } from "react-router-dom"
export default function ConsultCard({ head, desc, btnText }) {
    return (
        <Container className='bw_custome_container  bg-blue text-center py-50 px-3 px-lg-5 mb-50 text-white'>
            <h2 className='head-2'> {head} </h2>
            <p className='para-3'> {desc} </p>
            <Link to="/consultation">
                <button className='btn-outline-white'>  <span>  {btnText} </span> </button>
            </Link>
        </Container>
    )
}
