import { TextField } from '@material-ui/core'
import React from 'react'
import { Container } from 'react-bootstrap'
import Stripe from "../Payments/Stripe/Stripe";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
const stripePromise = loadStripe("pk_live_51Hf6ViFngcuk5Ha0TBfsYUxMqqk9bVKG5IDeizt01CpJGs8qLykHvUqSwhWTAwhmFY1jso0dZVcFc7RmfeBPrU9100grK2gEVf");

const Doller100PlanForm = () => {

    const [paypalreq, setPaypalreq] = React.useState([]);



    return (
        <>
            <div className='bw_doller_100_plan_section pt-5 pb-5'>
                <Container>
                    <div className='form_outer_box mt-5'>
                        <div className="bw_contact_form_inner_box">
                            {/* <h4 className='text-left mb-3 pb-3'>Register Now</h4>
                            <Form
                                className="bw_contact_us_form"
                                onSubmit={SubmitAppointment}
                            >
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <div className="rcs_cap_main">
                                            <ReCAPTCHA
                                                className="rcs_g-recaptcha"
                                                ref={captchaRef}
                                                sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                                                onChange={onChange}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <div className='text-left'>
                                            <button disabled={load || captcha ? true : false}>
                                                {load ? (
                                                    <div className="bw_loader_style">
                                                        <Loader
                                                            type="spinner-default"
                                                            bgColor={"#FFFFFF"}
                                                            color={"#FFFFFF"}
                                                            size={20}
                                                        />
                                                    </div>
                                                ) : (
                                                    "submit"
                                                )}
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                           
                            </Form> */}
                            <Elements stripe={stripePromise}>
                                <Stripe data={paypalreq} /></Elements>

                        </div>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Doller100PlanForm