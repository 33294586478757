import React from "react";
import { Col, Container, Row, Button, Image} from "react-bootstrap";
import logoInsta from '../../Assets/images/home/logo.webp';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { FaInstagram } from 'react-icons/fa'


const Insta = () => {

    return (
        <>
            <section className="bw_insta_section">
                <Container fluid className="p-0">
                    <h2 class="bwn_insta_hading_in_mobile">Instagram</h2>
                    <Row className="w-100 m-auto">
                        <Col lg={4} md={6} sm={5} xs={12} className="bwn_insta_in_mobile_view">
                            <div className="bw_insta_content_left">
                                <ul>
                                    <li><h2 className="font-stroke">Instagram</h2></li>
                                    <li className="bw_insta_box">
                                        <div className="bw_insta_inner_content">
                                            <LazyLoadImage src={logoInsta} alt="Belgium Webnet" width='100%'></LazyLoadImage>
                                            {/* <h3>#Belgium_Webnet</h3> */}
                                            {/* <Button className="btn_follow" onClick={()=>window.open("https://www.instagram.com/accounts/login/?next=/belgium_webnet/",
                          "_blank")}>Follow</Button> */}
                          <div className="bwn_home_insta text-center m-auto">
                               <div className="bwn_footer_iocn_circle bwn_footer_bg_insta" onClick={()=>window.open("https://www.instagram.com/belgium_webnet/", "_blank")}>
                                    <FaInstagram/>
                                </div>
                          </div>
                                        </div>
                                        <div className="bw_insta_inner_content">
                                            <h4>@Belgium_Webnet</h4>
                                            <span>Information Technology Company</span>
                                            <p>@belgium.Diamonds Digital marketing solution for jewelers.💎</p>
                                            <p>Web Development, Design, and Digital Marketing</p>
                                        </div>                                        
                                    </li>
                                </ul>
                            </div>
                        </Col>
                        <Col lg={8} md={6} sm={12} xs={12}>
                            {/* <div className="elfsight-app-7bae1688-1768-4fa2-95f4-5d42b89561d6"></div> */}
                            <div className="iframe-box">          <iframe src="https://instagram.demobw.live/belgium-webnet/" frameborder="0" width="100%" class="insta-iframe"></iframe></div> 
                  
                        </Col> 
                    </Row>
                </Container>
            </section>
        </>
    );
};

export default React.memo(Insta);
