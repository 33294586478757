import React from 'react'

export default function BreadCum({ head, desc }) {
    return (
        <div className="text-center text-white">
            <h1 className="">{head}</h1>
            <p className="mx-w-900 mx-auto">{desc}</p>
        </div>
    )
}
