import React, { useRef } from "react";
import "../../Assets/css/services.css";
import "../../Assets/css/inrPricing.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import InrImg1 from "../../Assets/images/inr-heading-img/b2c.png";
import InrImg2 from "../../Assets/images/inr-heading-img/b2b.png";
import InrImg3 from "../../Assets/images/inr-heading-img/sd.png";
import InrImg4 from "../../Assets/images/inr-heading-img/hair.png";
import InrImg5 from "../../Assets/images/inr-heading-img/sp.png";
import InrImg6 from "../../Assets/images/inr-heading-img/seo.png";
import InrImg7 from "../../Assets/images/inr-heading-img/smm.png";
import InrImg8 from "../../Assets/images/inr-heading-img/email.png";
import InrImg9 from "../../Assets/images/inr-heading-img/listing.png";

const PricingHeadingGBP = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Belgium WebNet- Digital Marketing And Social Media Marketing Company
        </title>
        <meta
          name="description"
          content="The best digital marketing service provider to grow your business online. Belgium WebNet is the best social media marketing company to conquer social media."
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="">
        <div className="bw_all_banner bw_banner_pricing">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1 className="bw_banner_heading new_pricing_hading">
                  {" "}
                  Online Jewelry Marketing For Indian Jewellers
                </h1>
                <p className="bw_banner_sub_heading">
                  Western Jewelry Industry Is Thriving With Their Online Profit
                  & Sales Belgium WebNet brings the same opportunity to Indian
                  jewellers.<br></br>
                  Start your online journey with us.
                </p>
              </Col>
            </Row>
          </Container>
        </div>

        <div className="pricing_hading_box_section">
          <Container className="bw_custome_container">
            <Row>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/b2c-pricing-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg1} alt="b2c pricing" />
                    </div>
                    <h6>B2C Website Development</h6>
                  </NavLink>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/b2b-pricing-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg2} alt="b2c pricing" />
                    </div>
                    <h6>B2B Website Development</h6>
                  </NavLink>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/shopify-website-pricing-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg3} alt="b2c pricing" />
                    </div>
                    <h6>Shopify Website Development</h6>
                  </NavLink>
                </div>
              </Col>
              {/* <Col sm={12} md={6} lg={4}>
                        <div className="pricing_img_text_box">
                                <NavLink to="#" className="pricing_img_text_innerbox">
                                    <div>
                                        <Image src={InrImg4} alt= "b2c pricing"/>
                                    </div>
                                    <h6>Hair Salon Website Development</h6>
                                </NavLink>
                        </div>
                    </Col> */}
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/shopify-plugin-pricing-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg5} alt="b2c pricing" />
                    </div>
                    <h6>Shopify & WordPress Plugin</h6>
                  </NavLink>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/plans-pricing-seo-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg6} alt="b2c pricing" />
                    </div>
                    <h6>Search Engine Optimization</h6>
                  </NavLink>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/plans-pricing-smo-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg7} alt="b2c pricing" />
                    </div>
                    <h6>Social Media Marketing</h6>
                  </NavLink>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/email-marketing-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg8} alt="b2c pricing" />
                    </div>
                    <h6>Email Marketing</h6>
                  </NavLink>
                </div>
              </Col>
              <Col sm={12} md={6} lg={4}>
                <div className="pricing_img_text_box">
                  <NavLink
                    to="/listing-and-promotion-of-nventory-inr"
                    className="pricing_img_text_innerbox"
                  >
                    <div>
                      <Image src={InrImg9} alt="b2c pricing" />
                    </div>
                    <h6>Listing & Promotion of Inventory</h6>
                  </NavLink>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(PricingHeadingGBP);
