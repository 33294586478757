import React from 'react'
import Countdown from 'react-countdown';
import '../../Assets/css/topnav.css';
import '../../Assets/css/count.css';
import { Col, Container, Row } from 'react-bootstrap';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import Count from './Count';
import { BsArrowRight } from 'react-icons/bs';
import { NavLink } from 'react-router-dom';

function Topnav() {
  const history = useHistory();
  return (
    <>
      {/* <Countdown
        date={"jun 2, 2023 00:00:00"}
        renderer={({ days, hours, minutes, seconds, completed }) => {
        
            // Render a countdown
            return (
              <div className='topnav-bg'>
              <Container className='bw_custome_container_header'>
                <Row className='m-auto w-100'>
                  <Col sm={12}> 
              <div className='topnav'>
                <div className='count-down_text'>
                  <a href='#' onClick={()=> history.push('/jck')}>
                    Book Your Appointment for JCK Las Vegas! June 2-5th, 2023, BelgiumWebNet 	&nbsp; <strong> Meet you at JCK in:</strong>  
                    </a>             
                </div>
                <div className='count-down_container'>
                  <div className='top-inner-box'>
                    <b>{days}</b><span>Days</span></div>
                  <div className='top-inner-box'>
                    <b>{hours}</b><span>Hrs.</span></div>
                  <div className='top-inner-box'>
                    <b>{minutes}</b><span>Min.</span></div>
                  <div className='top-inner-box'>
                    <b>{seconds}</b><span>Sec.</span></div>
                </div></div>
                </Col>
                </Row>
                </Container>
                </div>
            ); 
        }}
      /> */}

<div className='conutdown_top_bar'>
        {/* <h3> Meet you at JCK:  </h3> */}
        <Count targetDate="2024-05-31T00:12:00" />
       <NavLink to="/jck" className="jck_bbok_slot"><h3> <strong>Book Your Slot</strong> </h3></NavLink> 
      </div>
    </>
  )
}

export default Topnav 