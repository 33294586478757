import React from "react";
import "../../Assets/css/freePlansSmo.css";
import '../../Assets/css/newPlandoller_100.css'
import { Helmet } from "react-helmet";
import { Row, Container, Col, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Snowfall from 'react-snowfall'
import KApImage from '../../Assets/images/snow-img/kap.webp'
import Doller100PlanForm from "./Doller100PlanForm";

const NewPlans100 = () => {

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Belgium WebNet-100 Plan</title>
                <meta name="description" content=""></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>

            <div className="bw_free_plans_smo snowfall_main">
                {/* <div className="bw_all_banner bw_banner_pricing">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1
                  className="bw_banner_heading"
                >
                  Free Plans SMO
                </h1>
                <p className="bw_banner_sub_heading">
                Choose a plan to avail our services and get started with your transformation journey today.
                </p>
              </Col>
            </Row>
          </Container>
        </div> */}
                <Snowfall    snowflakeCount={300} />

                <div className="banner_img doller_100_plan_banner">
                    <Container className="bw_custome_container ">
                        <Row>
                            <Col sm={12} md={6}>
                                {/* <div className="bwn_banner_text_and_btn_outer_box position-relative">
                                    <div className="bwn_banner_fpsmo_get_started_btn_outer_box">
                                        <h2>Build An Online Presence On A Budget!</h2>
                                        <p>Your Full instagram Setup at just $100</p>
                                        <button className="bwn_banner_fpsmo_get_started_btn"> <Link to="/free-trial-smo"> Subscribe NOW </Link> </button>
                                    </div>

                                </div> */}
                                {/* <Col sm={12} md={6} ></Col> */}
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="snow_img">
                    <div className="bw_freeplans_smo_bgcolor bww_new_plan_doller_100_section">
                        <div>
                            <h1 className="bw_free_plan_smo_heading ">LET'S GET STARTED!</h1>
                        </div>

                        <Container className="bw_custome_container mt-2">
                            <Row>
                                <Col sm={12} md={3} lg={3} className="px-md-0 border-right-0">
                                    <div className="bw_free_plans_box_inner kap new_100_doller_white_box">
                                        <Image src={KApImage} alt="KAp image" className="kap_img" />
                                        <h3>Instagram Setup</h3>
                                        <div className="bw_free_plans_pricing_box position-relative">
                                            <span className="bw_free_price">$100</span>
                                            {/* <span className="bw_free_currency">USD</span>
                                            <span className="bw_free_perMonth">/mo.</span> */}
                                        </div>
                                        <div className="bw_free_btn_box">
                                            <Link
                                                to={{
                                                    pathname: "/register-instagram-setup",
                                                    state: { planName: "FREEMIUM" },
                                                }}
                                                className="bw_start_my_subscription_btn bwn_animation_btn bwn_new_sub_btn"
                                            >
                                                Start my Subscription
                                            </Link>
                                        </div>
                                        <div className="bw_free_box_subheading">
                                            <p>What’s included:</p>
                                            <ul className="list-unstyled">
                                                <li>
                                                    <span>
                                                        One Time Payment, no recurring fees

                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Weekly Post- For 5 Consecutive Weeks </span>
                                                </li>
                                                <li>
                                                    <span>Bio Implementation: </span>
                                                    <ul className="p-0 list-style-circle list_subcategory">
                                                        <li>Your Company Name</li>
                                                        <li>Link To Your Website</li>
                                                        <li>Profile Image </li>
                                                    </ul>
                                                </li>
                                                <li >
                                                    <span>
                                                        Types Of Posts Included
                                                    </span>
                                                    <ul className="p-0 list-style-circle list_subcategory">
                                                        <li>Customer Review</li>
                                                        <li>Product Images </li>
                                                        <li>About Your Store </li>
                                                    </ul>
                                                </li>
                                                <li >
                                                    <span className="bw_free_font_waight">
                                                        Login & Account Credentials
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={3} lg={3} className="px-md-0">
                                    <div className="bw_free_plans_box_inner new_100_inner">
                                        <h3>GOLD</h3>
                                        <div className="bw_free_plans_pricing_box position-relative">
                                            <span className="bw_free_price">250</span>
                                            <span className="bw_free_currency">USD</span>
                                            <span className="bw_free_perMonth">/mo.</span>
                                        </div>
                                        <div className="bw_free_btn_box">
                                            <Link
                                                to={{
                                                    pathname: "/consultation",
                                                    state: { planName: "GOLD" },
                                                }}
                                                className="bw_start_my_subscription_btn"
                                            >
                                                Start my Subscription
                                            </Link>
                                        </div>
                                        <div className="bw_free_box_subheading">
                                            <p>What’s included:</p>
                                            <ul className="list-unstyled">
                                                <li>
                                                    <span>
                                                        10 Posts Per Month (Including Festive & Birthstone
                                                        Posts)
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Social Media Channels are Facebook, Instagram.</span>
                                                </li>
                                                <li>
                                                    <span>Setup of Social Media accounts</span>
                                                </li>
                                                <li>
                                                    <span>Social Media Account Optimization</span>
                                                </li>
                                                <li>
                                                    <span>Content Writing for Post & Captions</span>
                                                </li>
                                                <li>
                                                    <span>Custom Designing for Post.</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Video Editing for Posts</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Followers Growth strategy and implementation.</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Story & Highlights cover management.</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Ad Campaign management - Facebook</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Ad Campaign management - Instagram</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Customized Feed (Theme management)</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Insta Shop setup & management</span>
                                                </li>
                                                <li>
                                                    <span>Research for Trending Hashtags</span>
                                                </li>
                                                <li>
                                                    <span>Trending audio selection for Reels/Videos</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Audience analysis</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Comments & Like management</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Reply to Customers direct Messages (DM)</span>
                                                </li>
                                                <li>
                                                    <span>Festive Posts</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Social Media Calendar</span>
                                                </li>
                                                <li>
                                                    <span>Monthly Consultation & Report</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={3} lg={3} className="px-md-0">
                                    <div className="bw_free_plans_box_inner new_100_inner">
                                        <h3>PLATINUM</h3>
                                        <div className="bw_free_plans_pricing_box position-relative">
                                            <span className="bw_free_price">350</span>
                                            <span className="bw_free_currency">USD</span>
                                            <span className="bw_free_perMonth">/mo.</span>
                                        </div>
                                        <div className="bw_free_btn_box">
                                            <Link
                                                to={{
                                                    pathname: "/consultation",
                                                    state: { planName: "PLATINUM" },
                                                }}
                                                className="bw_start_my_subscription_btn"
                                            >
                                                Start my Subscription
                                            </Link>
                                        </div>
                                        <div className="bw_free_box_subheading">
                                            <p>What’s included:</p>
                                            <ul className="list-unstyled">
                                                <li>
                                                    <span>
                                                        20 Custom-Designed Posts Per Month (Including Videos,
                                                        GIFs, and Festive & Birthstone Posts)
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Social Media Channels are Facebook, Instagram, Pinterest.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Setup of Social Media accounts</span>
                                                </li>
                                                <li>
                                                    <span>Social Media Account Optimization</span>
                                                </li>
                                                <li>
                                                    <span>Content Writing for Post & Captions</span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Custom Designing for Post
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Video Editing for Posts</span>
                                                </li>
                                                <li>
                                                    <span>Followers Growth strategy and implementation</span>
                                                </li>
                                                <li>
                                                    <span>Story & Highlights cover management</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Ad Campaign management - Facebook</span>
                                                </li>
                                                <li>
                                                    <span>Ad Campaign management - Instagram</span>
                                                </li>
                                                <li>
                                                    <span>Customized Feed (Theme management)</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Insta Shop setup & management</span>
                                                </li>
                                                <li>
                                                    <span>Research for Trending Hashtags</span>
                                                </li>
                                                <li>
                                                    <span>Trending audio selection for Reels/Videos</span>
                                                </li>
                                                <li>
                                                    <span>Audience analysis</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Comments & Like management</span>
                                                </li>
                                                <li className="bwn_free_plans_li_before_no">
                                                    <span>Reply to Customers direct Messages (DM)</span>
                                                </li>
                                                <li>
                                                    <span>Festive Posts</span>
                                                </li>
                                                <li>
                                                    <span>Social Media Calendar</span>
                                                </li>
                                                <li>
                                                    <span>Monthly Consultation & Report</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col sm={12} md={3} lg={3} className="px-md-0">
                                    <div className="bw_free_plans_box_inner new_100_inner border-0">
                                        <h3>Rhodium</h3>
                                        <div className="bw_free_plans_pricing_box position-relative">
                                            <span className="bw_free_price">450</span>
                                            <span className="bw_free_currency">USD</span>
                                            <span className="bw_free_perMonth">/mo.</span>
                                        </div>
                                        <div className="bw_free_btn_box">
                                            <Link
                                                to={{
                                                    pathname: "/consultation",
                                                    state: { planName: "TITANIUM" },
                                                }}
                                                className="bw_start_my_subscription_btn"
                                            >
                                                Start my Subscription
                                            </Link>
                                        </div>
                                        <div className="bw_free_box_subheading">
                                            <p>What’s included:</p>
                                            <ul className="list-unstyled">
                                                <li>
                                                    <span>
                                                        30 Custom-Designed Posts Per Month (Including Videos,
                                                        GIFs, and Festive & Birthstone Posts)
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Social Media Channels are Facebook, Instagram, Pinterest, TikTok.
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Setup of Social Media accounts</span>
                                                </li>
                                                <li>
                                                    <span>Social Media Account Optimization</span>
                                                </li>
                                                <li>
                                                    <span>Content Writing for Post & Captions</span>
                                                </li>
                                                <li>
                                                    <span>
                                                        Custom Designing for Post
                                                    </span>
                                                </li>
                                                <li>
                                                    <span>Video Editing for Posts</span>
                                                </li>
                                                <li>
                                                    <span>Followers Growth strategy and implementation</span>
                                                </li>
                                                <li>
                                                    <span>Story & Highlights cover management</span>
                                                </li>
                                                <li>
                                                    <span>Ad Campaign management - Facebook</span>
                                                </li>
                                                <li>
                                                    <span>Ad Campaign management - Instagram</span>
                                                </li>
                                                <li>
                                                    <span>Customized Feed (Theme management)</span>
                                                </li>
                                                <li>
                                                    <span>Insta Shop setup & management</span>
                                                </li>
                                                <li>
                                                    <span>Research for Trending Hashtags</span>
                                                </li>
                                                <li>
                                                    <span>Trending audio selection for Reels/Videos</span>
                                                </li>
                                                <li>
                                                    <span>Audience analysis </span>
                                                </li>
                                                <li>
                                                    <span>Comments & Like management</span>
                                                </li>
                                                <li>
                                                    <span>Reply to Customers direct Messages (DM)</span>
                                                </li>
                                                <li>
                                                    <span>Festive Posts</span>
                                                </li>
                                                <li>
                                                    <span>Social Media Calendar</span>
                                                </li>
                                                <li>
                                                    <span>Monthly Consultation & Report</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </div>

            </div>


        </>
    );
};

export default React.memo(NewPlans100);

