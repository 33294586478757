import React, { useRef } from "react";
import "../../Assets/css/contactus.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import Helmet from "react-helmet";
import { baseURL, postHeader } from "../../Helpers/request";
import axios from "axios";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import JckDesktopImg from "../../Assets/images/jck-img/jck-appointment-desktop-img-2023.jpg";
import JckDesktopImgMob from "../../Assets/images/jck-img/jck-appointment-mobile-img-2023.jpg";
import Loader from "react-js-loader";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isDesktop, isMobileOnly } from "react-device-detect";

const JckAppointment = () => {
  const captchaRef = useRef(null);
  const [selectedDate, setSelectedDate] = React.useState("2023-06-02");
  const [selectedTime, setSelectedTime] = React.useState("8:00AM ");
  const [name, setName] = React.useState("");
  const [firstname, setFirstname] = React.useState("");
  const [lastname, setLastname] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [website, setWebsite] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);

  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const SubmitShowrequest = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    if ( phone.length>7) {
    const data = {
      // name: name,
      company_name: company,
      first_name: firstname,
      last_name: lastname,
      email: email,
      show_time:selectedTime,
      website_url: website,
      //country_code:countrycode || 91,
      phone: phone.length ? `+${phone}` : "",
      show_date: selectedDate,
      message: message,
    };
    setLoad(true);
    axios
      .post(`${baseURL}/home/current_show_request`, data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          swal(res.data.message, { icon: "success" });
          // setName("");
          setFirstname("");
            setLastname("");
          setCompany("");
          setPhone("");
          setEmail("");
          setMessage("");
          setSelectedDate("2023-06-02");
          setSelectedTime("8:00AM ");
          setLoad(false);
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
          setLoad(false);
        }
      });}
      else {
        setPhoneError('Please Enter Valid Number');
      }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>JCK LAS VEGAS 2024 - Belgium Webnet</title>
        <meta
          name="description"
          content="We Offer Consultation To Grow Your Business Online"
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_contactus_section position-relative">
        <div className="bw_all_banner bw_contact_banner  bw_jck_banner">
          <Container>
            <Row className="w-100 m-auto">
              <Col>
                <img
                  src={isMobileOnly ? JckDesktopImgMob :JckDesktopImg}
                  className="img-fluid d-none"
                  alt="JCK Image"
                />
                {/* <h1 className="text-center text-white">JCK-Appointment</h1> */}
                {/* <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="#" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="#" className="text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul> */}
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_new_position_box bw_jck_position mb-5 bw_jck_banner_desk">
          <Container>
            <Row className="w-100 m-auto">
              <Col>
                <div
                  className="bw_contact_form_outer_box"
                  data-aos="fade-up"
                  data-aos-duration="500"
                >
                  <h2>Let's Book An Appointment</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitShowrequest}
                    >
                      <Row>
                        {/* <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Full Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col> */}
                         <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Company Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Company Name"
                              value={company}
                              onChange={(e) => setCompany(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={firstname}
                              onChange={(e) => setFirstname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lastname}
                              onChange={(e) => setLastname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                       
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number*</Form.Label>
                            <PhoneInput
                            disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={(e) => setPhone(e)}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                            />
                                 {!phoneError.length?'':<p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Website</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Website"
                              value={website}
                              onChange={(e) => setWebsite(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                        <Form.Group
                            className="mb-3"
                          >
                          <Form.Label>Date</Form.Label>
                          <Form.Select
                          className="form-control sales_representative_select"
                            aria-label="Default select example"
                            onChange={(e) => setSelectedDate(e.target.value)}
                            value={selectedDate}
                          >
                            <option
                              value="2024-05-31"
                              disabled={
                                new Date("2024-06-02T23:59:00") < new Date()
                                  ? true
                                  : false
                              }
                            >
                              Friday, May 31st
                            </option>
                            <option
                              value="2024-06-01"
                              disabled={
                                new Date("2024-06-03T23:59:00") < new Date()
                                  ? true
                                  : false
                              }
                            >
                              Saturday, June 1st
                            </option>
                            <option
                              value="2024-06-02"
                              disabled={
                                new Date("2024-06-04T23:59:00") < new Date()
                                  ? true
                                  : false
                              }
                            >
                              Sunday, June 2nd
                            </option>
                            <option
                              value="2024-06-03"
                              disabled={
                                new Date("2024-06-05T23:59:00") < new Date()
                                  ? true
                                  : false
                              }
                            >
                              Monday, June 3rd
                            </option>
                          </Form.Select>
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                          >
                          <Form.Label>Time</Form.Label>
                          <Form.Select
                          className="form-control sales_representative_select"
                            aria-label="Default select example"
                            onChange={(e) => setSelectedTime(e.target.value)}
                            value={selectedTime}
                          >
                            {/* <option
                              value="8:00 AM "
                            >
                             8:00 AM
                            </option>
                            <option
                              value="9:00 AM"
                            >
                              9:00 AM
                            </option> */}
                            <option
                              value="10:00 AM "
                            >
                             10:00 AM 
                            </option>
                            <option
                              value="11:00 AM "
                            >
                             11:00 AM 
                            </option>
                            <option
                              value="12:00PM "
                            >
                             12:00 PM 
                            </option>
                            <option
                              value="1:00 PM "
                            >
                             1:00 PM 
                            </option>
                            <option
                              value="2:00 PM "
                            >
                             2:00PM 
                            </option>
                            <option
                              value="3:00 PM "
                            >
                             3:00 PM 
                            </option>
                            <option
                              value="4:00 PM "
                            >
                             4:00 PM 
                            </option>
                            <option
                              value="5:00 PM "
                            >
                             5:00 PM 
                            </option>
                            <option
                              value="6:00 PM "
                            >
                             6:00 PM 
                            </option>
                          </Form.Select>
                          </Form.Group>
                        </Col>
                        
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Message*"
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? false : false} type="submit">
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(JckAppointment);
