import React from "react";
import { Helmet } from "react-helmet";
import "../../Assets/css/webDevelopmentDesign.css";
import { Row, Col, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import WddImageSeo from "../../Assets/images/web-development-and-design/design3.jpg";
import WddImage2 from "../../Assets/images/web-development-and-design/wdd2.webp";
import ServiceIcon from "../../Assets/images/web-development-and-design/green-services-icon.webp"; 
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SeoImg1 from '../../Assets/images/seo-img/seo1.png'
import SeoImg2 from '../../Assets/images/seo-img/seo2.png'
import SeoImg3 from '../../Assets/images/seo-img/seo3.png'
import SeoImg4 from '../../Assets/images/seo-img/seo4.png'

const SearchEngineOptimization = () => {
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        The Best Search Engine Optimization Company | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Have Belgium WebNet, the best search engine optimization company by your side for a foolproof plan for your jewelry business. Book an appointment with us!"
        ></meta>
        <meta name="keywords" content="Best search engine optimization company"></meta>
      </Helmet>
      <section className="bw_wdd_section">
        <div className="bw_all_banner bw_wdd_banner">
          <Container className="bw_custome_container">
            <Row>
              <Col>
                <h1 className="text-center text-white text-capitalize">
                  Search Engine Optimization
                </h1>
                <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="/" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/contacts" className="text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_website_desing">
          <Container className="bw_custome_container">
            <Row>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="bw_website_deigns_innerbox1"
              >
                <div>
                  <div>
                    <h2>
                     SEARCH ENGINE OPTIMIZATION
                    </h2>
                    <p>
                      Optimize your website by achieving Higher Search Ranking
                      by making the website easily accessible to potential
                      customers. It is an effective method to generate enormous
                      traffic. We mainly focus on Google since it is the largest
                      search engine. Our experts identify the website and
                      business-relevant keywords and incorporate them to
                      increase their search engine ranking. The higher the rank,
                      the greater the clicks it will receive.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className="bw_wdd_img_right_box">
                <div>
                  <LazyLoadImage
                    src={WddImageSeo}
                    alt="Web Development &amp; design image"
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_why_choose_us">
          <Container className="bw_custome_container">
            <Row>
              <Col sm={6} md={7} lg={7}>
                <div>
                  <h2>
                    What Sets Us Apart?
                  </h2>
                  <ul
                    className="pl-0"
                  >
                    <li>
                      <p>Tailored SEO solutions for every client.</p>
                    </li>
                    <li>
                      <p>
                        Successful SEO method for lead generation using SMO,
                        SMM, content marketing, referrals, etc.
                      </p>
                    </li>
                    <li>
                      <p>
                        Increased traffic results in higher lead close rates.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>Reduced advertising costs with SEO promotions.</p>
                    </li>
                    <li>
                      <p>
                        Enhanced brand awareness and credibility with strategic
                        SEO.
                      </p>{" "}
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col sm={6} md={5} lg={5} className="bw_wdd_imagebox2">
                <div>
                  <LazyLoadImage
                    src={WddImage2}
                    alt="web Development and design Image"
                    className="img-fluid"
                  />
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bw_faq_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  Why Choose Our SEO Services
                </h2>
              </Col>
            </Row>
            <Row className="mb-lg-4">
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg1} alt="Service Icon" />
                  <p>SEO that helps build a definite brand image </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg2} alt="Service Icon" />
                  <p>Save advertising costs with SEO Promotions</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg3} alt="Service Icon" />
                  <p>Analyzing the usability, speed, and design of your site</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SeoImg4} alt="Service Icon" />
                  <p>Generate maximum traffic and build your customer base</p>
                </div>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  FAQ’S
                </h2>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col className="px-md-0">
                <div
                  className="bw_wdd_faq_accordian"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Why Do You Need Digital Marketing Service?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Digital marketing can help businesses improve their
                        customer relationships. Digital marketing strategies
                        like special media are great options to improvise
                        customer relationships and fuel sales for businesses. It
                        allows brands to understand their customer's
                        requirements better.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What is SEO?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        SEO stands for Search Engine Optimization. It helps
                        improve website rank on search engines like Google. SEO
                        has two domains, namely off-page and on-page. On-page
                        SEO helps the search engine identify your websites, and
                        Off-page SEO helps in creating backlinks.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        How are backlinks helpful?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Backlinks are an indispensable part of SEO as it
                        represents a vote of confidence from one site to
                        another. If various sites link to a similar webpage or
                        website, search engines can interpret the content and
                        decide whether it is worth linking and surfing on the
                        Search Engine Results Page (SERP). Backlinks are part of
                        Google’s original PageRank algorithm, making them
                        essential for SEO.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What is Local SEO?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Local SEO focuses on achieving results in location-based
                        searches, like- jewelry stores in New York. These types
                        of searches become more frequent and lead to map display
                        in search results. There are some strategies to
                        influence performance on location-based search results.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel5" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What is content marketing?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Content marketing is an approach used to attract,
                        engage, and gain customers. It requires creating,
                        distributing, and sharing relevant content. The content
                        includes blogs, articles, social media posts, videos,
                        etc., that help retain existing customers and attract
                        potential ones. Content marketing strategy focuses on
                        creating brand awareness and promotions. It depends upon
                        the content delivery, along with the right time and
                        platform.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(SearchEngineOptimization);
