

import React, { useRef } from "react";
import "../../Assets/css/services.css";
import { Helmet } from "react-helmet";
import { Container, Row, Col, Form, Image } from "react-bootstrap";

import "react-phone-input-2/lib/style.css";
import ShopifyDewelopmentImg from "../../Assets/images/shopify-img/shopify-development-img.png";


const ShopifyDevelopmentStaticPage = () => {



    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                Shopify Website Development Company | Belgium WebNet
                </title>
                <meta
                    name="description"
                    content="Shopify offers an easy-to-use platform that simplifies ecommerce for jewelers and customers. We can help you with Shopify website development, contact us now!"
                ></meta>
                <meta name="keywords" content="Shopify website development company"></meta>
            </Helmet>
            <section className="bw_services_section_main">
                <div className="bw_all_banner bw_banner_pricing">
                    <Container className="bw_custome_container">
                        <Row className="w-100 m-auto">
                            <Col>
                                <h1 className="bw_banner_heading">Shopify Development</h1>
                                <p className="bw_banner_sub_heading">
                                    Create an impactful website to sell your products and services with our Shopify Experts
                                </p>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="bw_sercices_image_section bw_shopify_development_section">
                    <Container className="bw_custome_container">
                        <Row className="w-100 m-auto">
                            <Col sm={12} md={6} lg={6} className="bw_sevices_left_text_box">
                                <div
                                    className="text-left"
                                >
                                    <h2>Shopify Development</h2>
                                    <p>
                                        Belgium WebNet is proud to be the leading Shopify partner that exclusively caters
                                        to the jewelry industry. The Shopify platform offers numerous advantages for online
                                        retailers, making it an incredibly powerful tool for e-commerce. Moreover, Shopify
                                        consistently updates and improves its features and functionality, ensuring that your
                                        website always stays up-to-date with the latest technology and trends.
                                    </p>
                                    <p>We understand that the jewelry industry has its unique requirements and challenges,
                                        which is why we specialize in developing custom Shopify websites that cater specifically
                                        to the needs of jewelry businesses. We have the expertise to create high-performing,
                                        user-friendly Shopify sites that help your brand succeed online, from product display
                                        and management to secure payment processing and shipping options.</p>
                                    <p>Our team specializes in creating Shopify websites that are not only great to look at but
                                        are also capable of driving sales and boosting your overall online presence.  Investing
                                        in a Shopify website is the perfect way to take your jewelry brand to the next level, and
                                        our Shopify experts are here to help you every step of the way. Contact us today to learn
                                        more and get started!</p>
                                </div>
                            </Col>
                            <Col sm={12} md={6} lg={6} className="bw_sevices_right_text_box">
                                <Image
                                    src={ShopifyDewelopmentImg}
                                    alt="Shopify Dewelopment Image"
                                    className="img-fluid"
                                />
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </>
    );
};

export default React.memo(ShopifyDevelopmentStaticPage);
