import React from 'react'
import { Container } from 'react-bootstrap'
import Doller100PlanForm from './Doller100PlanForm'
import Stripe from '../Payments/Stripe/Stripe'

const RegisterHundredDollar = () => {
  return (
    <div className='bw_doller_100_plan_section pt-5'>
      <Container>
        <Doller100PlanForm />
      </Container>
    </div>
  )
}

export default RegisterHundredDollar