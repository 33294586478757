import React, { useState } from "react";
import "../../../Assets/css/diamondJewelryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
import "../../../Assets/css/plansPricingSeo.css";
import '../../../Assets/css/b2c.css'
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import { NavLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import CountUp from "react-countup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function TabPanel(props) {
  const { children, value, valueInner, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AedB2C = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };
  const [currency,setCurrency] = useState("AED");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Belgium WebNet- B2C Website Development</title>
        <meta
          name="description"
          content="Get affordable and highly effective search engine optimization services to help you rank your website on the search engine result pages. View pricing now. "
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bwn_b2c_pricing_section">
        <div className="bw_pricing_section">
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                    B2C Website Development
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="bw_pricing_tabs_section">
            <Container className="bw_custome_container_pricing position-relative">
              {/* <div className="bwn_confit">
                    {isExploding && <ConfettiExplosion   force={.2}  duration= {3000}  particleCount={60} width={1000} height={100}/>}
              </div> */}
              
              <div className="bw_pricing_tabs_box bw_only_month_tab text-center">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab label="Monthly" {...a11yProps(0)} />
                  <Tab label="Annually" {...a11yProps(1)} />
                </Tabs>
              </div>
              <div className="bwn_first_month_free_text text-center">
                <div className="position-relative">
                  <button className="bwn_get_on_month_free_button">Annually Get 1 Month Free</button>
                  {/* <Image src={ArrowImpImg}></Image> */}
                </div>
              </div>
              {/* Monthly tab starting */}
            {window.location.pathname.includes("-aed") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12} xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"AED"}>AED</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""}
              <TabPanel
                value={value}
                index={0}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 399 : 150} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 2499" : '$999'}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 749 : 250} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-aed") && currency == "AED" ?"AED  3499" : "$1499"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 999: 350} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 4999" :"$1999"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                PHP
                              </div>
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                            <td>
                              <div>
                                Instagram & Facebook
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Offline
                              </div>
                            </td>
                            <td>
                              <div>
                                Google & Offline
                              </div>
                            </td>
                            <td>
                              <div>
                                Google, Yelp & Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers

                              </div>
                            </td>
                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Monthly</td>
                            <td>Bi-Monthly</td>
                            <td>Weekly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label=" RHODIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 399 : 150} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED " :"$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                  {window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 2499" : "$999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                PHP
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Monthly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 749 : 250} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               { window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 3499" :"$1499"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Google & Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                2 Suppliers

                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>

                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>

                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Bi-Monthly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM
                              </h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 999 :350} duration={0} className="bwn_price_count_up"  prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               {window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 4999": "$1999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div>
                                Instagram & Facebook
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Google, Yelp & Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>

                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                3 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>

                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Weekly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                {/* <Row className="w-100 m-auto">
                  <Col>
                    <ul className="bw_green_djdinr_list_style pl-4">
                      <li>
                        Particulars like Rapnet, VDB, Polygon, Idex and Get Diamonds will be cosidered as one supplier each as per your plan.
                      </li>
                      <li>For any additional Diamonds/Gemstones/Fancy Color Stones Supplier Integration : One time charges will be {window.location.pathname.includes("-aed") && currency == "AED"  ? "₹15000" :"$250"} each.</li>
                      <li>For any additional Jewelry Supplier Integration : One time charges will be {window.location.pathname.includes("-aed") && currency == "AED"  ? "₹25000" :"$500"} Onwards. </li>
                      <li>For any additional Payment Gateway Integration :  One time charges will be {window.location.pathname.includes("-aed") && currency == "AED"  ? "₹15000" :"$250"} Each.</li>
                      <li>Above prices are exclusive of GST.</li>
                    </ul>
                  </Col>
                </Row> */}
                {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/diamond-jewelry-web-development"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      Website Development Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Marketing Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
              </TabPanel>
              {/* Monthly tab ending */}
              {/* Annual tab starting */}
              <TabPanel
                value={value}
                index={1}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 399*11 : 1650} duration={.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-aed") && currency == "AED" ? "AED 2499" : "$999"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                  <CountUp end={ window.location.pathname.includes("-aed") && currency == "AED"  ? 749*11 : 2750} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p> 
                                <h4>{ window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 3499" : "$1499"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={ window.location.pathname.includes("-aed") && currency == "AED"  ? 999*11: 3850} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 4999": "$1999"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                PHP
                              </div>
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                            <td>
                              <div>
                                Instagram & Facebook
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Offline
                              </div>
                            </td>
                            <td>
                              <div>
                                Google & Offline
                              </div>
                            </td>
                            <td>
                              <div>
                                Google, Yelp & Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers

                              </div>
                            </td>
                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Monthly</td>
                            <td>Bi-Monthly</td>
                            <td>Weekly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label=" RHODIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                                <CountUp end={ window.location.pathname.includes("-aed") && currency == "AED"  ? 399*11 :1650} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                { window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 2499" : "$999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                PHP
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Monthly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                                <CountUp end={ window.location.pathname.includes("-aed") && currency == "AED"  ? 749*11 :2750} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               {  window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 3499": "$1499"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Google & Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                2 Suppliers

                              </div>
                            </td>

                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>

                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>

                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Bi-Monthly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM
                              </h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-aed") && currency == "AED"  ? 999*11: 3850} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "AED ":"$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                               {window.location.pathname.includes("-aed") && currency == "AED"  ? "AED 4999": "$1999"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Technology
                            </td>
                            <td>
                              <div>
                                React JS
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Language
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Multi-Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Custom Homepage design
                            </td>

                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Social Media Live Integration
                            </td>
                            <td>
                              <div>
                                Instagram & Facebook
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Reviews Integration
                            </td>
                            <td>
                              <div>
                                Google, Yelp & Offline
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Natural Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Lab Diamonds supplier inventory integration with search page</td>

                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Fancy Color Diamonds supplier inventory integration with search page </td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Gemstones Supplier inventory integration with search page </td>

                            <td>
                              <div>
                                2 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Jewelry supplier inventory integration </td>

                            <td>
                              <div>
                                3 Suppliers
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Ring Builder</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Watches search page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Fully Responsive design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Payment Gateway Integration
                            </td>
                            <td>
                              <div>
                                3 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Finance Platform Integration

                            </td>

                            <td>
                              <div>
                                2 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Diamond Markup via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Insta shop integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics Integration in Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Full E-Commerce Functionality (Including Cart, Wishlist, Coupon Code etc.)
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Education page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Blog Page
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              ADA Compliance
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              SSL/HTTPS integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud backup</td>
                            <td>Weekly</td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Dedicated Project Manager
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                {/* <Row className="w-100 m-auto">
                  <Col>
                    <ul className="bw_green_djdinr_list_style pl-4">
                      <li>
                        Particulars like Rapnet, VDB, Polygon, Idex and Get Diamonds will be cosidered as one supplier each as per your plan.
                      </li>
                      <li>For any additional Diamonds/Gemstones/Fancy Color Stones Supplier Integration : One time charges will be {window.location.pathname.includes("-aed") && currency == "AED"  ? "₹15000" :"$250"} each.</li>
                      <li>For any additional Jewelry Supplier Integration : One time charges will be {window.location.pathname.includes("-aed") && currency == "AED"  ? "₹25000" :"$500"} Onwards. </li>
                      <li>For any additional Payment Gateway Integration :  One time charges will be {window.location.pathname.includes("-aed") && currency == "AED"  ? "₹15000" :"$250"} Each.</li>
                      <li>Above prices are exclusive of GST.</li>
                    </ul>
                  </Col>
                </Row> */}
                {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/diamond-jewelry-web-development"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      Website Development Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Marketing Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
              </TabPanel>
              {/* Annual tab ending */}

            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(AedB2C);
