import React, { useRef } from "react";
import "../../Assets/css/contactus.css";
import { Row, Col, Container, Form } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { MdLocationOn, MdEmail, MdPhone } from "react-icons/md";
import Helmet from "react-helmet";
import { baseURL, postHeader } from "../../Helpers/request";
import axios from "axios";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";
import ReCAPTCHA from "react-google-recaptcha";
import Loader from "react-js-loader";
import { useHistory } from "react-router-dom";

const Contactus = () => {
  const history = useHistory();
  const captchaRef = useRef(null);
  const [fname, setFname] = React.useState("");
  const [lname, setLname] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [load, setLoad] = React.useState(false);
  const [captcha, setCaptcha] = React.useState(true);
  function onChange(value) {
    if (value) {
      setCaptcha(false);
    }
  }
  const SubmitContactdetails = (e) => {
    e.preventDefault();
    captchaRef.current.reset();
    if ( phone.length>7) {
    const data = {
      name: `${fname} ${lname}`,
      email: email,
      phone: phone,
      message: message,
    };
    setLoad(true);
    axios
      .post(`${baseURL}/contact/send_contact_request`, data, {
        headers: postHeader,
      })
      .then((res) => {
        if (res.data.status == 1) {
          // swal(res.data.message, { icon: "success" });
          setFname("");
          setLname("");
          setPhone("");
          setEmail("");
          setMessage("");
          setLoad(false);
          history.push('./thankyou')
        } else {
          swal(res.data.message, { icon: "warning" });
          setLoad(false);
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
          setLoad(false);
        }
      });}
      else {
        setPhoneError('Please Enter Valid Number');
      }
  };
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us | Website Design Company | Belgium WebNet </title>
        <meta name="description" content="Connect with Belgium WebNet – your trusted gateway to exceptional web design, digital marketing, and email and social media marketing solutions. Contact us now!"></meta>
        <meta name="keywords" content="contact us, contact belgium webnet "></meta>
      </Helmet>
      <section className="bw_contactus_section position-relative">
        <div className="bw_all_banner bw_contact_banner">
          <Container>
            <Row>
              <Col>
                <h1 className="text-center text-white">Get in Touch</h1>
                <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="/" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/contacts" className="text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_new_position_box bw_contact_position">
          <Container>
            <Row className="w-100 m-auto">
              <Col className="px-0">
                <div
                  className="bw_contact_form_outer_box"
                >
                  <h2>Contact Us</h2>
                  <div className="bw_contact_form_inner_box">
                    <Form
                      className="bw_contact_us_form"
                      onSubmit={SubmitContactdetails}
                    >
                      <Row>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>First Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="First Name"
                              value={fname}
                              onChange={(e) => setFname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control
                              type="text"
                              placeholder="Last Name"
                              value={lname}
                              onChange={(e) => setLname(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Email Address*</Form.Label>
                            <Form.Control
                              type="email"
                              placeholder="Email Id"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Phone Number*</Form.Label>
                            <PhoneInput
                              disableSearchIcon={true}
                              enableSearch={true}
                              country={"us"}
                              value={phone}
                              onChange={(e) => setPhone(e)}
                              inputProps={{
                                name: "phone",
                                required: true,
                                autoFocus: false,
                              }}
                              isValid={(value, country) => {
                                if (value.length < 10) {
                                  return "please enter valid number";
                                } else {
                                  return true;
                                }
                              }}
                            />
                               {!phoneError.length?'':<p className="phone_field_error font-weight-light text-danger">{phoneError}</p>}
                         </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <Form.Group
                            className="mb-3"
                            controlId="exampleForm.ControlInput1"
                          >
                            <Form.Label>Notes</Form.Label>
                            <Form.Control
                              as="textarea"
                              placeholder="Your Message*"
                              rows={4}
                              value={message}
                              onChange={(e) => setMessage(e.target.value)}
                              required
                            />
                          </Form.Group>
                        </Col>
                        <Col sm={12} md={12} lg={12}>
                          <div className="rcs_cap_main">
                            <ReCAPTCHA
                              className="rcs_g-recaptcha"
                              ref={captchaRef}
                              sitekey="6Lc6LUscAAAAAKO2DYPGrIPSnwx3fzROCxJzNWQ0"
                              onChange={onChange}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <button disabled={load || captcha ? true : false}>
                          {load ? (
                            <div className="bw_loader_style">
                              <Loader
                                type="spinner-default"
                                bgColor={"#FFFFFF"}
                                color={"#FFFFFF"}
                                size={20}
                              />
                            </div>
                          ) : (
                            "submit"
                          )}
                        </button>
                      </Row>
                    </Form>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="bw_contact_map_box w-100 m-auto">
              <Col sm={12} md={7} lg={7} className="px-md-0 bw_contact_map_box">
                <div
                  className="bw_contact_map_box"
                >
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3022.230272999535!2d-73.9843189674826!3d40.756959776687175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c2590a50b3a235%3A0x8af09874060f51fc!2sBelgium%20WebNet!5e0!3m2!1sen!2sin!4v1656495032151!5m2!1sen!2sin"
                    width="100%"
                    height="535"
                    style={{ border: "0" }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerpolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
              </Col>
              <Col
                sm={12}
                md={5}
                lg={5}
                className="px-md-0 bw_addres"
              >
                <div className="">
                  <div className="bw_contact_address_box">
                    <div className="d-flex bw_contat_icon_inner" onClick={() => {
                      window.open(
                        "https://www.google.com/maps/place/Belgium+WebNet/@40.7569558,-73.9798343,15z/data=!4m5!3m4!1s0x0:0x8af09874060f51fc!8m2!3d40.7569558!4d-73.9798343",
                        "_blank"
                      );
                    }}>
                      <MdLocationOn />
                      <div
                        className="bw_add_box"
                      >
                        <h6>Address</h6>
                        <p>
                          20 W 47th St, Suite 601
                          <br /> New York, NY 10036
                        </p>
                      </div>
                    </div>
                    <div className="d-flex bw_contat_icon_inner" onClick={() => window.location.href = "mailto:sales@belgiumwebnet.com"}>
                      <MdEmail />
                      <div>
                        <h6>E-mail</h6>
                        <a href="mailto:sales@belgiumwebnet.com">
                          {" "}
                          sales@belgiumwebnet.com
                        </a>
                      </div>
                    </div>
                    <div className="d-flex bw_contat_icon_inner">
                      <MdPhone />
                      <div>
                        <h6>Phone</h6>
                        <a href="tel:+1 (917) 767-7248"> +1 (917) 767-7248</a>
                        <br />
                        <a href="tel:+1 (646) 992-9024">+1 (646) 992-9024</a>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>




          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(Contactus);
