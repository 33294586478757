export const pointData = [
     [ 
          "transaction",
          "from/to",
          "city",
          "lat",
          "lng"
     ],
     [ 
       "1",
      "start",
      "United Arab Emirates",
      "23.739312",
      "54.027128",
   ],
     [ 
           "1",
          "end",
          "India",
          "28.66",
          "77.23"
     ],
     [ 
           "2",
          "start",
          "India",
           "28.66",
          "77.23"
     ],
     [ 
           "2",
          "end",
          "Australia",
          "-23.173189",
          "133.582091",
     ],
     [ 
           "3",
          "start",
          "Australia",
          "-23.173189",
          "133.582091",
     ],
     [ 
           "3",
          "end",
          "Canada",
          "59.837916",
          "-459.460811",
     ],
     [ 
       "4",
      "start",
      "United States",
          "38.112950",
          "-97.192125",
   ],
   [ 
       "4",
      "end",
      "Sweden",
     "60.795364",
     "15.602239",
   ],
   [ 
     "5",
    "start",
    "Sweden",
    "60.795364",
    "15.602239",
   ],
   [ 
     "5",
    "end",
    "United Kingdom",
    "51.641033",
    " -0.824955",
   ],
   [ 
     "6",
    "start",
    "United Kingdom",
     "51.641033",
     " -0.824955",
   ],
   [ 
     "6",
    "end",
    "Spain",
       "39.518344",
       " -3.316008",
   ],
   [ 
     "7",
    "start",
    "Spain",
    "39.518344",
    " -3.316008",
   ],
   [ 
     "7",
    "end",
    "Israel",
     "31.028666",
     "34.954987",
   ],
   [ 
     "8",
    "start",
    "Israel",
    "31.028666",
    "34.954987",
   ],
   [ 
     "8",
    "end",
    "United Arab Emirates",
    "23.739312",
    "54.027128",
   ],
   [ 
     "9",
    "start",
    "United Arab Emirates",
          "23.739312",
          "54.027128",
   ],
   [ 
     "9",
    "end",
    "India",
    "28.66",
    "77.23",
   ],
   [ 
     "10",
    "start",
    "Canada",
    "59.837916",
    "-459.460811",
   ],
   [ 
     "10",
    "end",
    "United States",
    "38.112950",
    "-97.192125",
   ],
  //  [ 
  //   "11",
  //  "start",
  //  "Canada",
  //  "59.837916",
  //  "-49.460811",
  // ],
  // [ 
  //   "11",
  //  "end",
  //  "Spain",
  //     "39.518344",
  //     " -3.316008",
  // ],
  // [ 
  //   "12",
  //  "start",
  //  "United Arab Emirates",
  //        "19.119312",
  //        "74.027128",
  // ],
  // [ 
  //   "12",
  //  "end",
  //  "India",
  //  "28.66",
  //  "77.23",
  // ],
  [ 
    "13",
   "start",
   "United States",
   "23.739312",
   "54.027128",
  ],
  [ 
    "13",
   "end",
   "India",
    "19.62067",
    "75.0822",
  ],
   ]
   export const  wc_data_static = [
     [
          "city",
          "lat",
          "lng",
          "country",
          "iso3",
          "iso2",
          "admin_name",
          "id"
     ],
     [
          "United Arab Emirates",
          "23.739312",
          "54.027128",
          "Japan",
          "JPN",
          "JP",
          "Tōkyō",
          "1392685764"
     ],
     [
          "United States",
          "38.112950",
          "-97.192125",
          "Indonesia",
          "United States",
          "United States",
          "Jakarta",
          "1360771077"
     ],
   [
     "United Kingdom",
     "51.641033",
     " -0.824955",
     "Japan",
     "JPN",
     "JP",
     "Tōkyō",
     "1392685764"
   ],
     [
       "Spain",
       "39.518344",
       " -3.316008",
       "Japan",
       "JPN",
       "JP",
       "Tōkyō",
       "1392685764"
   ],
   [
     "Sweden",
     "60.795364",
     "15.602239",
     "Japan",
     "JPN",
     "JP",
     "Tōkyō",
     "1392685764"
   ],
   [
     "Israel",
     "31.028666",
     "34.954987",
     "Israel",
     "AUS",
     "JP",
     "Tōkyō",
     "1392685764"
   ],
     [
          "India",
          "28.66",
          "77.23",
          "India",
          "IND",
          "IN",
          "Delhi",
          "135687260sadcsac4"
     ],
     [
       "Canada",
       "59.837916",
       "-459.460811",
       "canada",
       "CND",
       "CN",
       "Tōkyō",
       "1392685764"
     ],
     [
       "Australia",
       "-23.173189",
       "133.582091",
       "Japan",
       "AUS",
       "JP",
       "Tōkyō",
       "1392685764"
     ],
   ]