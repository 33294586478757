import React from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
import "../../Assets/css/footer.css";
import main_logo from "../../Assets/images/home/bel_final_white.png";
import Phone from "../../Assets/images/footer-img/phone.png";
import Location from "../../Assets/images/footer-img/location.png";
import Email from "../../Assets/images/footer-img/email.png";
import { FaFacebookF, FaInstagram, FaTwitter, FaPinterest, FaYoutube, FaLinkedinIn } from 'react-icons/fa';

const Footer = () => {
  return (
    <>
      <section id="myFooter" className="bwn_footer_section">
        <Container className="bw_custome_footer_container">
          <Row className="pb-2">
            <Col sm={12} md={6} lg={3}>
              <div className="bwn_footer_logo_box">
                <NavLink to="/"><Image src={main_logo} alt="Belgium Logo" /></NavLink>
              </div>
              <div className="bwn_footer_logo_subtext">
                <NavLink to="/about-us">
                  <p>We are the designers, marketers, innovators, strategists, and builders behind your virtual store.<br></br> Read More...</p>
                </NavLink>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="bwn_footer_quick_links_box bwn_footer_quick_links_margin">
                <h4>Quick Links</h4>
                <ul className="pl-0">
                  <li><NavLink to="/careers">Careers</NavLink></li>
                  <li><NavLink to="/services">Services</NavLink></li>
                  <li><NavLink to="/blogs">Blogs</NavLink></li>
                  <li><NavLink to="/privacy-policy">Privacy Policy</NavLink></li>
                  <li><NavLink to="/contacts">Contact Us</NavLink></li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="bwn_footer_quick_links_box bwn_footer_quick_links_box_tablate">
                <h4>Services</h4>
                <ul className="pl-0">

                  <li><NavLink to="/web-development-design">Web Design & Developement</NavLink></li>
                  <li><NavLink to="/social-media-marketing">Social Media Marketing</NavLink></li>
                  <li><NavLink to="/social-media-marketing-for-jewelers">Social Media Marketing For Jewelers</NavLink></li>
                  <li><NavLink to="/search-Engine-Optimization">Search Engine Optimization</NavLink></li>
                  <li><NavLink to="/pages/shopify-development">Shopify Development</NavLink></li>
                  <li><NavLink to="/pages/email-marketing">Email Marketing</NavLink></li>
                  <li><NavLink to="/pages/ppc">Paid Advertisement (PPC) </NavLink></li>
                  {/* <li><NavLink to="/diamond-jewelry-designs">Design Options</NavLink></li> */}
                  <li><NavLink to="/integrations">Integrations</NavLink></li>
                </ul>
              </div>
            </Col>
            <Col sm={12} md={6} lg={3}>
              <div className="bwn_get_in_touch_link_box bwn_footer_quick_links_margin">

                <h4>Get In Touch</h4>
                <div className="bwn_footer_address_box">
                  <a href="https://www.google.com/maps/place/Belgium+WebNet/@40.7569558,-73.9798343,15z/data=!4m5!3m4!1s0x0:0x8af09874060f51fc!8m2!3d40.7569558!4d-73.9798343" target="_blank" className="d-flex">
                    <div>
                      <Image src={Location} alt="Location Icon" />
                    </div>
                    <div>20 W 47th St, Suite 601<br></br>New York, NY 10036</div>
                  </a>
                </div>
                <div className="bwn_footer_contact_box">
                  <div className="d-flex">
                    <div>
                      <Image src={Phone} alt="Phone Icon" />
                    </div>
                    <div>
                      <a href="tel:+1 (917) 767-7248"> +1 (917) 767-7248</a><br></br>
                      <a href="tel:+1 (646) 992-9024"> +1 (646) 992-9024</a>
                    </div>
                  </div>
                </div>
                <div className="bwn_footer_address_box bwn_footer_mail_box">
                  <a href="mailto:sales@belgiumwebnet.com" target="_blank" className="d-flex">
                    <div>
                      <Image src={Email} alt="Email Icon" />
                    </div>
                    <div>sales@belgiumwebnet.com</div>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="bwn_footer_social_icon_box_new">
              <div className="bwn_footer_social_icon_inner_box d-flex">
                <a className="bwn_footer_iocn_circle bwn_footer_bg_insta" href="https://www.instagram.com/belgium_webnet/" target="_blank">
                  <FaInstagram />
                </a>
                <a className="bwn_footer_iocn_circle bwn_footer_bg_facebook" href="https://www.facebook.com/belgiumwebnet/" target="_blank">
                  <FaFacebookF />
                </a>
                <a className="bwn_footer_iocn_circle bwn_footer_bg_youtube" href="https://www.youtube.com/@belgiumwebnet6486" target="_blank">
                  <FaYoutube />
                </a>
                <a className="bwn_footer_iocn_circle bwn_footer_bg_linkedin" href="https://www.linkedin.com/company/belgium-webnet/" target="_blank">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </Row>
        </Container>
        <Container fluid>
          <Row className="p-0 bwn_footer_cpyright">
            <Col>
              <p>Copyright ©  {new Date().getFullYear()} Belgium WebNet. All Rights Reserved.</p>
            </Col>
          </Row>
        </Container>


      </section>
    </>
  );
};

export default Footer;
