import React from "react";
import "../../Assets/css/backToTopComp.css";
import ScrollToTop from "react-scroll-to-top";

const BackToTopComp = () => {
  return (
    <>
      <div className="bw_scroll_to_top">
        <ScrollToTop smooth width="20" height="20" />
      </div>
    </>
  );
};

export default React.memo(BackToTopComp);
