import React, { useState, useEffect } from "react";
import Home from "./Components/Front/Home";
import {
  BrowserRouter,
  Route,
  Switch,
  useParams
} from "react-router-dom";

import Header from "./Components/Header/Header";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Assets/css/main.css";
import "./Assets/css/media.css";
import Footer from "./Components/Footer/Footer";
import Careers from "./Components/Pages/Careers";
import Blog from "./Components/Pages/Blog";
import BackToTopComp from "./Components/Pages/BackToTopComp";
import DiamondJewelryDesigns from "./Components/Pages/DiamondJewelryDesigns";
import AOS from "aos";
import "aos/dist/aos.css";
import Contactus from "./Components/Pages/Contactus";
import Integrations from "./Components/Pages/Integrations";
import GoToTop from "./Components/Pages/GoToTop";
import BlogDetails from "./Components/Pages/BlogDetails";
import WebDevelopmentDesign from "./Components/Pages/WebDevelopmentDesign";
import CareerDetails from "./Components/Pages/CareerDetails";
import Services from "./Components/Pages/Services";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";
import SocialMediaMarketing from "./Components/Pages/SocialMediaMarketing";
import SocialMediaMarketingForJewelers from "./Components/Pages/SocialMediaMarketingForJewelers";
import SearchEngineOptimization from "./Components/Pages/SearchEngineOptimization";
import PlansPricingsSeo from "./Components/Pages/PlansPricingSeo";
import PlansPricingSmo from "./Components/Pages/PlansPricingSmo";
// import HairSalonWebDevelopment from "./Components/Pages/HairSalonWebDevelopment";
import Consultation from "./Components/Pages/Consultation";
import FreePlansSmo from "./Components/Pages/FreePlansSmo";
import FreeTrialSmo from "./Components/Pages/FreeTrialSmo";
import PaidPlansSmo from "./Components/Pages/PaidPlansSmo";
import Aboutus from "./Components/Pages/Aboutus";
import Portfolio from "./Components/Pages/Portfolio";
import PackageFormPayment from "./Components/Pages/PackageFormPayment";
import B2B from "./Components/Pages/NewPricing/B2B";
import shopifyWebsite from "./Components/Pages/NewPricing/ShopifyWebsite";
import ShopifyPlugin from "./Components/Pages/NewPricing/ShopifyPlugin";
import ListingAndPromotion from "./Components/Pages/NewPricing/ListingAndPromotion";
import EmailMarketing from "./Components/Pages/NewPricing/EmailMarketing";
import B2C from "./Components/Pages/NewPricing/B2C";
import Smm from "./Components/Pages/NewPricing/Smm";
// import HairSalonDesigns from "./Components/Pages/HairSalonDesigns";
import Redirection from "./Components/Redirection";
// import HairTheme1 from "./Components/Pages/HairSalonThemes/HairTheme1";
// import HairTheme2 from "./Components/Pages/HairSalonThemes/HairTheme2";
// import HairTheme3 from "./Components/Pages/HairSalonThemes/HairTheme3";
// import HairTheme4 from "./Components/Pages/HairSalonThemes/HairTheme4";
// import HairTheme5 from "./Components/Pages/HairSalonThemes/HairTheme5";
import PricingHeading from './Components/Pages/PricingHeading'
import AedPlansPricingsSeo from "./Components/Pages/AedPlansPricingSeo";
import AedPlansPricingSmo from "./Components/Pages/AedPlansPricingSmo";
import AedShopifyWebsite from "./Components/Pages/NewPricing/AedShopifyWebsite";
import AedShopifyPlugin from "./Components/Pages/NewPricing/AedShopifyPlugin";
import AedListingAndPromotion from "./Components/Pages/NewPricing/AedListingAndPromotion";
import AedEmailMarketing from "./Components/Pages/NewPricing/AedEmailMarketing";
import AedB2B from "./Components/Pages/NewPricing/AedB2B";
import AedB2C from "./Components/Pages/NewPricing/AedB2C";
import AedPricingHeading from "./Components/Pages/AedPricingHeading";
import ShopifyDevelopmentStaticPage from "./Components/Pages/ShopifyDevelopmentStaticPage";
import EmailMarketingStaticPage from "./Components/Pages/EmailMarketingStaticPage"
import ThemePreview from "./Components/Pages/ThemePreview";
import JckAppointment from "./Components/Pages/JckAppointment";
import Thankyou from "./Components/Pages/Thankyou";
import AboutAshishJangir from "./Components/Pages/AboutAshishJangir";
import NewPlans100 from "./Components/Pages/NewPlans100";
import RegisterHundredDollar from "./Components/Pages/RegisterHundredDollar";
import AboutSamiSaghie from "./Components/Pages/AboutSamiSaghie";
import Jangir from "./Components/Pages/Jangir";
import Sami from "./Components/Pages/Sami";
import Nivoda from "./Components/Pages/Integration-pages/Nivoda";
import MeetOurCto from "./Components/Pages/MeetOurCto";

// gbp pricing imports
import B2B_GBP from "./Components/Pages/GBPPricing/B2B_GBP";
import ShopifyWebsiteGBP from "./Components/Pages/GBPPricing/ShopifyWebsiteGBP";
import ShopifyPlugin_GBP from "./Components/Pages/GBPPricing/ShopifyPlugin_GBP";
import ListingAndPromotionGBP from "./Components/Pages/GBPPricing/ListingAndPromotionGBP";
import EmailMarketingGBP from "./Components/Pages/GBPPricing/EmailMarketingGBP";
import PlansPricingSmoGBP from "./Components/Pages/GBPPricing/PlansPricingSmoGBP";
import PlansPricingSeoGBP from "./Components/Pages/GBPPricing/PlansPricingSeoGBP";
import B2C_GBP from "./Components/Pages/GBPPricing/B2C_GBP";
import PricingHeadingGBP from "./Components/Pages/PricingHeadingGBP";
import CrGems from "./Components/Pages/Integration-pages/CrGems";
import CrGemsWithoutHeaderFooter from "./Components/Pages/Integration-pages/CrGemsWithoutHeaderFooter";
import Topnav from "./Components/Header/Topnav";
import PpcSaticPgae from "./Components/Pages/PpcSaticPgae";
import JckYes from "./Components/Pages/JckYes";
import JckNo from "./Components/Pages/JckNo";
import GuildnFacet from "./Components/Pages/Integration-pages/GuildnFacet";
function App() {

  AOS.init(
    {
      duration: 500,
      once: true,
    }
  );
  const [countryName, setCountryName] = React.useState("");

  useEffect(() => {
    // --------********United kingdom Geolocation data start*********-----------------

    // {"start_ip": "2a02:c7c:2c8a:900::", "end_ip": "2a02:c7c:2c8a:fff:ffff:ffff:ffff:ffff", "join_key": "2a02:c7c::", "city": "London", "region": "England", 
    // "country": "GB", "latitude": "51.50853", "longitude": "-0.12574", "postal_code": "E1W", "timezone": "Europe/London"}

    // --------********United kingdom Geolocation data end*********-----------------

    fetch("https://ipinfo.io/json")
        .then((response) => response.json())
        .then((data) => {
          setCountryName(data?.country)
        })
        .catch((error) => {
          console.error("Error fetching IP information:", error);
        });
  }, [])
  return (
    <>
      <BrowserRouter>
        <Header />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/about-us/ashish-jangir" component={AboutAshishJangir} />
          <Route path="/about-us/sami-saghie" component={AboutSamiSaghie} />
          <Route path="/services/uk" component={MeetOurCto} />
          <Route path="/jangir" component={Jangir} />
          <Route path="/sami" component={Sami} />
          <Route path="/about-us" component={Aboutus} />
          <Route exact path="/careers" component={Careers} />
          <Route path="/careers/:position" component={CareerDetails} />
          {/* <Route path="/diamond-jewelry-web-development" component={DiamondJewelryWebDevelopment} /> */}
          {/* <Route path="/hair-salon-web-development" component={HairSalonWebDevelopment} /> */}
          <Route exact path="/blogs" component={Blog} />
          <Route exact path="/blogs/:heading"><BlogDetails /></Route>
          <Route exact path="/diamond-jewelry-designs" component={DiamondJewelryDesigns} />
          <Route exact path="/diamond-jewelry-designs/:theme" component={ThemePreview} />
          {/* <Route path="/hair-salon-designs" component={HairSalonDesigns} /> */}
          <Route path="/contacts" component={Contactus} />
          <Route path="/integrations" component={Integrations} />
          <Route path="/web-development-design" component={WebDevelopmentDesign} />
          <Route path="/social-media-marketing" component={SocialMediaMarketing} />
          <Route path="/social-media-marketing-for-jewelers" component={SocialMediaMarketingForJewelers} />
          <Route path="/search-engine-optimization" component={SearchEngineOptimization} />
          <Route path="/services" component={Services} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/consultation" component={Consultation} />
          <Route path="/free-plans-smo" component={FreePlansSmo} />
          <Route path="/free-trial-smo" component={FreeTrialSmo} />
          <Route path="/paid-plans-smo" component={PaidPlansSmo} />
          {/* <Route path="/jck-coupon" component={JckCoupon} /> */}
          <Route path="/jck" component={JckAppointment} />
          <Route path="/jckyes" component={JckYes} />  
          <Route path="/jckno" component={JckNo} />  
          <Route path="/#" component={PackageFormPayment} />
          <Route path="/belgiumwebnet-portfolio" component={Portfolio} />
          {/* <Route path="/belgiumwebnet-portfolio" component={PortfolioPage}/> */}
          {/* <Route path="/diamond-jewelry-web-development-inr" component={DiamondJewelryWebDevelopmentInr} /> */}
          {/* <Route path="/plans-pricing-seo-inr" component={PlansPricingSeoInr} />
          <Route path="/plans-pricing-smo-inr" component={PlansPricingSmoInr} /> */}
          
          {/* <Route path="/plans-pricing-seo" component={PlansPricingsSeo} />
          <Route path="/plans-pricing-smo" component={PlansPricingSmo} /> */}
          {/* <Route path="/b2b-pricing" component={B2B} />
          <Route path="/shopify-website-pricing" component={shopifyWebsite} />
          <Route path="/shopify-plugin-pricing" component={ShopifyPlugin} />
          <Route path="/listing-and-promotion-of-nventory" component={ListingAndPromotion} />
          <Route path="/email-marketing" component={EmailMarketing} />
          <Route path="/b2c-pricing" component={B2C} /> */}

          <Route path="/b2b-pricing-aed" component={AedB2B} />
          <Route path="/shopify-website-pricing-aed" component={AedShopifyWebsite} />
          <Route path="/shopify-plugin-pricing-aed" component={AedShopifyPlugin} />
          <Route path="/plans-pricing-seo-aed" component={AedPlansPricingsSeo} />
          <Route path="/plans-pricing-smo-aed" component={AedPlansPricingSmo} />
          <Route path="/b2c-pricing-aed" component={AedB2C} />
          <Route path="/aed" component={AedPricingHeading} />
          <Route path="/listing-and-promotion-of-nventory-aed" component={AedListingAndPromotion} />
          <Route path="/email-marketing-aed" component={AedEmailMarketing} />
          {/* INR pricing */}
          <Route path="/plans-pricing-seo-inr" component={PlansPricingsSeo} />
          <Route path="/plans-pricing-smo-inr" component={PlansPricingSmo} />
          <Route path="/b2b-pricing-inr" component={B2B} />
          <Route path="/shopify-website-pricing-inr" component={shopifyWebsite} />
          <Route path="/shopify-plugin-pricing-inr" component={ShopifyPlugin} />
          <Route path="/listing-and-promotion-of-nventory-inr" component={ListingAndPromotion} />
          <Route path="/email-marketing-inr" component={EmailMarketing} />
          <Route path="/b2c-pricing-inr" component={B2C} />
{/* ---uk REdirection pricing */}
          <Route path="/b2b-pricing" component={countryName == "GB" ? B2B_GBP : B2B} />
          <Route path="/b2c-pricing" component={countryName == "GB" ? B2C_GBP : B2C} />
          <Route path="/shopify-website-pricing" component={countryName == "GB" ? ShopifyWebsiteGBP : shopifyWebsite} />
          <Route path="/shopify-plugin-pricing" component={countryName == "GB" ? ShopifyPlugin_GBP : ShopifyPlugin} />
          <Route path="/email-marketing" component={countryName == "GB" ? EmailMarketingGBP :EmailMarketing} />
          <Route path="/listing-and-promotion-of-nventory" component={countryName == "GB" ? ListingAndPromotionGBP :ListingAndPromotion} />
          <Route path="/plans-pricing-seo" component={countryName == "GB" ? PlansPricingSeoGBP :PlansPricingsSeo} />
          <Route path="/plans-pricing-smo" component={countryName == "GB" ? PlansPricingSmoGBP :PlansPricingSmo} />
          
           {/* GBP (pound) pricing */}
           <Route path="/plans-pricing-seo-gbp" component={PlansPricingSeoGBP} />
          <Route path="/plans-pricing-smo-gbp" component={PlansPricingSmoGBP} />
          <Route path="/b2b-pricing-gbp" component={B2B_GBP} />
          <Route path="/shopify-website-pricing-gbp" component={ShopifyWebsiteGBP} />
          <Route path="/shopify-plugin-pricing-gbp" component={ShopifyPlugin_GBP} />
          <Route path="/listing-and-promotion-of-nventory-gbp" component={ListingAndPromotionGBP} />
          <Route path="/email-marketing-gbp" component={EmailMarketingGBP} />
          <Route path="/b2c-pricing-gbp" component={B2C_GBP} />
          
          <Route path="/smm-pricing" component={Smm} />
          <Route path="/inr" component={PricingHeading} />
          <Route path="/gbp" component={PricingHeadingGBP} />
          <Route path="/pages/shopify-development" component={ShopifyDevelopmentStaticPage} />
          <Route path='/pages/email-marketing' component={EmailMarketingStaticPage} />
          <Route path='/pages/ppc' component={PpcSaticPgae} />
          <Route path='/thankyou' component={Thankyou} />
          <Route path='/nivoda' component={Nivoda} />
          <Route path='/guildfacet' component={GuildnFacet} />
          <Route path='/cr-gems' component={CrGems} />
          <Route path='/cr-gems-api-integration' component={CrGemsWithoutHeaderFooter} />
          <Route path='/instagram-pricing' component={NewPlans100} />

          <Route path='/register-instagram-setup' component={RegisterHundredDollar} />

          {/* <Route path="/404" component={PageNotFound} /> */}
          <Route path="*" component={Redirection} />

          {/* <Redirect from="*" to="/404" /> */}
        </Switch>
        <BackToTopComp />
        <GoToTop />
        <Footer />
      </BrowserRouter>
    </>
  );
}

export default App;
