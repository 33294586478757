import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const GoToTop = () => {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {

    // const scripts = document.createElement('script');
    // scripts.id = "hs-script-loader";
    // scripts.src = 'https://js.hs-scripts.com/8649560.js';
    // document.body.appendChild(scripts);


//  Google tag (gtag.js)
onTop();
const script = document.createElement('script');
script.src = "https://www.googletagmanager.com/gtag/js?id=GTM-P9MH6N4";
script.async = true;
document.body.appendChild(script);
// console.log(arguments)
window.dataLayer = window.dataLayer || [];
function gtag() { window.dataLayer.push(arguments); }
gtag('js', new Date());
gtag('config', 'GTM-P9MH6N4', { page_path: window.location.pathname });
// End Google tag (gtag.js)
if (routePath.pathname !=='/about-us/ashish-jangir') {
  chatMsg()
}
  }, [routePath]);

  function chatMsg() {
  const script = document.createElement('script');
  script.src = "//code.tidio.co/swjccokbgtmlivlarj7s42oka7aptbke.js";
  script.async = true;
  document.body.appendChild(script);
  }

  return null;
};

export default React.memo(GoToTop);
