
import React from 'react'
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import axios from 'axios';
import { baseURL } from "../../Helpers/request";
import { Image } from 'react-bootstrap';
import '../../Assets/css/portfolioPageMobileView.css'

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const PortfolioPageMobileView = () => {
    const [value, setValue] = React.useState(0);
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const [userdata, setUserdata] = React.useState([]);
    const [userdatacate, setUserdatacate] = React.useState([]);
    const [userdatacateactive, setUserdatacateactive] = React.useState('');
    const [c_id, setC_id] = React.useState(1);

    React.useEffect(() => {
        GetUserdata(c_id);
    }, [c_id]);
    React.useEffect(() => {
        GetUserdataCategory();
    }, []);
    const GetUserdata = (id) => {
        axios
            .get(`${baseURL}/home/clients/${id}`)
            .then((res) => {
                if (res.data.status == 1) {
                    setUserdata(res.data.data);
                    console.log(res);
                } else {
                    console.log(res.data.message);
                }
            })
            .catch((error) => {
                if (error) {
                    console.log("something went wrong");
                }
            });
    };
    const GetUserdataCategory = () => {
        axios
            .get(`${baseURL}/Client/getClientsCategory`)
            .then((res) => {
                if (res.data.status == 1) {
                    setUserdatacate(res.data.data);
                } else {
                    console.log(res.data.message);
                }
            })
            .catch((error) => {
                if (error) {
                    console.log("something went wrong");
                }
            });
    };

    return (
        <>
            <div className='bw_mobile_view_of_portfolio_logos mt-4'>
                <Tabs
                    sx={{
                        '& .MuiTabs-flexContainer': {
                            flexWrap: 'wrap',
                        },
                    }} aria-label="basic tabs example" centered>
                    {userdatacate.map((res, index) => (<Tab label={res.name} key={index} {...a11yProps(index)} className='bw_mobile_view_of_portfolio_logos_btn' onClick={(e) => { setC_id(res.id); setUserdatacateactive(index) }} style={{ border: userdatacateactive == index ? '1px solid #3D63C3' : '1px solid #000', color: userdatacateactive == index ? '#3d63c3' : '#001100' }} />))}
                    {/* // <Tab label="Item Two" {...a11yProps(1)} className='bw_mobile_view_of_portfolio_logos_btn' />
                    // <Tab label="Item Three" {...a11yProps(2)} className='bw_mobile_view_of_portfolio_logos_btn' />
                    // <Tab label="Item four" {...a11yProps(3)} className='bw_mobile_view_of_portfolio_logos_btn' />
                    // <Tab label="Item Five" {...a11yProps(4)} className='bw_mobile_view_of_portfolio_logos_btn' /> */}
                </Tabs>
                <TabPanel value={value} index={0}>
                    <div className="bwn_client_tab_logo_new">
                        <ul className="bw_client_logo_img_ul_new_design w-100 pl-0 mb-0">
                            {userdata.map((client) => (
                                <li>
                                    <div className='bw_new_client_logo_design_box'>
                                        <Image
                                            src={client.logo}
                                            onClick={() =>
                                                window.open(`${client.link}`, "_blank")
                                            }
                                            alt="Client Logo Image"
                                        />
                                    </div>

                                </li>
                            ))}
                        </ul>

                    </div>
                </TabPanel>
                {/* <TabPanel value={value} index={1}>
                    Item Two
                </TabPanel>
                <TabPanel value={value} index={2}>
                    Item Three
                </TabPanel>
                <TabPanel value={value} index={3}>
                    Item Three
                </TabPanel>
                <TabPanel value={value} index={4}>
                    Item Three
                </TabPanel> */}
            </div>
        </>
    )
}

export default PortfolioPageMobileView