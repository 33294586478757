import React, { useState } from "react";
// import "../../Assets/css/diamondJewelryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
// import "../../Assets/css/plansPricingSeo.css";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import CountUp from "react-countup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function TabPanel(props) {
  const { children, value, valueInner, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AedShopifyWebsite = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };
  const [currency, setCurrency] = useState("AED");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Belgium WebNet- Shopify Website Development
        </title>
        <meta
          name="description"
          content="Explore our web development plans and pricing for hair salons. Prices start at $100. We also offer expert digital marketing services to help you grow your business online. "
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section>
        <div className="bw_pricing_section">
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                    Shopify Website Development
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="bw_pricing_tabs_section">
            <Container className="bw_custome_container_pricing position-relative">
              <div className="bw_pricing_tabs_box bw_only_month_tab text-center position-relative">
                
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab label="Monthly" {...a11yProps(0)} />
                  <Tab label="Annually" {...a11yProps(1)} />
                </Tabs>
              </div>
              <div className="bwn_first_month_free_text text-center">
                  <div className="position-relative">
                    <button className="bwn_get_on_month_free_button">Annually Get 1 Month Free</button>
                    {/* <Image src={ArrowImpImg}></Image> */}
                  </div>
                </div>
              {/* Montly tab containe start */}
              {window.location.pathname.includes("-aed") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12} xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"AED"}>AED</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""}
              <TabPanel
                value={value}
                index={0}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                <h6>
                                  Package <br /> Features{" "}
                                </h6>
                              </div>
                            </th>

                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-aed") && currency == "AED" ? 499 : 200} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED" ? "AED " : "$"} suffix="/" /><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-aed") && currency == "AED" ? "AED 7499" : "$2499"}</h4>
                              </div>
                            </th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package ">
                              Monthly Subscription Charges for Support & Maintenance
                            </td>
                            <td>
                              <div>
                              {window.location.pathname.includes("-aed") && currency == "AED" ? "AED " : "$"}{window.location.pathname.includes("-aed") && currency == "AED" ? 499 : 200}/Month
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Installation of any Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Complete Store setup as per Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website designing as per selected Shopify theme

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Supplier Inventory integration*

                            </td>
                            <td>
                              <div>
                                1 Supplier

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway integration*</td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Logins</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment Form
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Product Share</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Drop Hint
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">App & Plugin Installation</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Third Party Chat Bot Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Social Media Feed Integration*  </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Reviews from Online Portals Integration*
                            </td>
                            <td>
                              <div>
                                Google
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics & Search Console Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>

                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    {/* <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="RHODIUM" {...a11yProps(0)} />
                      <Tab label="" {...a11yProps(1)} />
                    </Tabs> */}

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>Rhodium</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-aed") && currency == "AED" ? 499 : 200} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED" ? "AED " : "$"} suffix="/" /><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-aed") && currency == "AED" ? "AED 7499" : "$2499"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Monthly Subscription Charges for Support & Maintenance
                            </td>
                            <td>
                              <div>
                              {window.location.pathname.includes("-aed") && currency == "AED" ? 499 : 200}/Month
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Installation of any Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Complete Store setup as per Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website designing as per selected Shopify theme

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Supplier Inventory integration*

                            </td>
                            <td>
                              <div>
                                1 Supplier

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway integration*</td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Logins</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment Form
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Product Share</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Drop Hint
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">App & Plugin Installation</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Third Party Chat Bot Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Social Media Feed Integration*  </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Reviews from Online Portals Integration*
                            </td>
                            <td>
                              <div>
                                Google
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics & Search Console Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    {/* <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM</h6>
                              <h4>
                             <CountUp end={350} duration={0} className="bwn_price_count_up"   prefix={window.location.pathname.includes("-aed") && currency == "AED"  ? "":"$"} suffix="/"/><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Setup</td>
                            <td>$1999</td>
                          </tr>
                          <tr>
                            <td>Custom Homepage Design</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                           Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*
                            </td>
                            <td>
                              <div>
                                  3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Jewelry supplier's inventory integration*</td>
                            <td>
                              <div>
                                 3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Memo/Hold stones highlight</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Language</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Currency</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Dashboard after login</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Jewelry search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Wishlist & Cart</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Gateway integration*</td>
                            <td>
                              <div>
                              1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Single Jewelry upload via admin panel</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Sign up approval feature for website user</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Price Visibility on Login/Signup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Blog Page</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Social Media feed Integration</td>
                            <td>
                              <div>
                               Instagram
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Reviews Integration</td>
                            <td>
                              <div>
                             Google & Offline
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Website Cloud Backup</td>
                            <td>
                              <div>
                             Bi-Monthly
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel> */}
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                {/* <Row>
                  <Col>
                    <ul className="bwn_b2b_note_container bw_green_djdinr_list_style">
                      <li>Shopify monthly charges are not included in the above package.   </li>
                      <li>Shopify theme cost Is not included in the above package.</li>
                      <li>A separate custom quotation will be shared for any further requests in regards to design & development not covered under the selected Shopify theme. </li>
                      <li>A separate custom quotation will be shared for any alterations in the Number of Jewelry Suppliers, Payment Gateway, Social Media Channels, & Online Portals.</li>
                      <li>A separate custom quotation will be sent for every function not listed in your chosen plan.</li>
                      <li>Above prices are exclusive of GST.</li>
                    </ul>
                  </Col>
                </Row> */}
                {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/plans-pricing-seo"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      SEO Lead Generation Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Optimization Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
              </TabPanel>
              {/* Montly tab containe end */}
              {/* Annual tab containe start */}
              <TabPanel
                value={value}
                index={1}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                <h6>
                                  Package <br /> Features{" "}
                                </h6>
                              </div>
                            </th>

                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Rhodium</h6>
                                <h4>
                                  <CountUp end={window.location.pathname.includes("-aed") && currency == "AED" ? 499*11 : 2200} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED" ? "AED " : "$"} suffix="/" /><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-aed") && currency == "AED" ? "AED 7499" : "$2499"}</h4>
                              </div>
                            </th>

                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              Monthly Subscription Charges for Support & Maintenance
                            </td>
                            <td >
                              <div>
                              {window.location.pathname.includes("-aed") && currency == "AED" ? 499 : 200}/Month
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Installation of any Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Complete Store setup as per Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website designing as per selected Shopify theme

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Supplier Inventory integration*

                            </td>
                            <td>
                              <div>
                                1 Supplier

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway integration*</td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Logins</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment Form
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Product Share</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Drop Hint
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">App & Plugin Installation</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Third Party Chat Bot Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Social Media Feed Integration*  </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Reviews from Online Portals Integration*
                            </td>
                            <td>
                              <div>
                                Google
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics & Search Console Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>

                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    {/* <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="RHODIUM" {...a11yProps(0)} />
                      <Tab label="" {...a11yProps(1)} />
                    </Tabs> */}

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>Rhodium</h6>
                              <h4>
                                <CountUp end={window.location.pathname.includes("-aed") && currency == "AED" ? 499*11 : 2200} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-aed") && currency == "AED" ? "AED " : "$"} suffix="/" /><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package">
                              One-Time Setup Fees
                            </td>
                            <td>
                              <div>
                                {window.location.pathname.includes("-aed") && currency == "AED" ? "AED 7499" : "$2499"}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package bw_prcing_package ">
                              Monthly Subscription Charges for Support & Maintenance
                            </td>
                            <td>
                              <div>
                              {window.location.pathname.includes("-aed") && currency == "AED" ? 499 : 200}/Month
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Installation of any Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Complete Store setup as per Shopify theme
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Website designing as per selected Shopify theme

                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Supplier Inventory integration*

                            </td>
                            <td>
                              <div>
                                1 Supplier

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Payment Gateway integration*</td>
                            <td>
                              <div>
                                1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Logins</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Appointment Form
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Social Media Product Share</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Drop Hint
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">App & Plugin Installation</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Third Party Chat Bot Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Social Media Feed Integration*  </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Reviews from Online Portals Integration*
                            </td>
                            <td>
                              <div>
                                Google
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Google Analytics & Search Console Integration
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                {/* <Row>
                  <Col>
                    <ul className="bwn_b2b_note_container bw_green_djdinr_list_style">
                      <li>Shopify monthly charges are not included in the above package.   </li>
                      <li>Shopify theme cost Is not included in the above package.</li>
                      <li>A separate custom quotation will be shared for any further requests in regards to design & development not covered under the selected Shopify theme. </li>
                      <li>A separate custom quotation will be shared for any alterations in the Number of Jewelry Suppliers, Payment Gateway, Social Media Channels, & Online Portals.</li>
                      <li>A separate custom quotation will be sent for every function not listed in your chosen plan.</li>
                      <li>Above prices are exclusive of GST.</li>
                    </ul>
                  </Col>
                </Row> */}
                {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/plans-pricing-seo"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      SEO Lead Generation Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Optimization Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
              </TabPanel>
              {/* Annual tab containe end */}

            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(AedShopifyWebsite);
