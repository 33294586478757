import React from "react";
import "../../Assets/css/freePlansSmo.css";
import { Helmet } from "react-helmet";
import { Row, Container, Col, Image } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import Snowfall from 'react-snowfall'
import KApImage from '../../Assets/images/snow-img/kap.webp'

const FreePlansSmo = () => {

  return (
    <>
      <Helmet></Helmet>

      <div className="bw_free_plans_smo snowfall_main">
        {/* <div className="bw_all_banner bw_banner_pricing">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1
                  className="bw_banner_heading"
                >
                  Free Plans SMO
                </h1>
                <p className="bw_banner_sub_heading">
                Choose a plan to avail our services and get started with your transformation journey today.
                </p>
              </Col>
            </Row>
          </Container>
        </div> */}
         {/* <Snowfall    snowflakeCount={300} /> */}

        <div className="banner_img">
         <Container  className="bw_custome_container ">
          <Row>
            <Col sm={12} md={6}>
              <div className="bwn_banner_text_and_btn_outer_box position-relative">
                <div className="bwn_banner_fpsmo_get_started_btn_outer_box">
                  <h2>Get customized posts for FREE</h2>
                  <p>Especially for Belgium Diamonds customers</p>
                  <button className="bwn_banner_fpsmo_get_started_btn"> <Link  to="/free-trial-smo"> Subscribe NOW </Link> </button>
                </div>
                  
              </div>
               {/* <Col sm={12} md={6} ></Col> */}
            </Col>
          </Row>
         </Container>
      </div>
         <div className="snow_img">
          <div className="bw_freeplans_smo_bgcolor">
          <div>
            <h1 className="bw_free_plan_smo_heading ">LET'S GET STARTED!</h1>
          </div>

          <Container className="bw_custome_container mt-2">
            <Row>
              <Col sm={12} md={3} lg={3} className="px-md-0 border-right-0">
                <div className="bw_free_plans_box_inner kap">
                   <Image src={KApImage} alt="KAp image" className="kap_img"/>
                  <h3>FREEMIUM</h3>
                  <div className="bw_free_plans_pricing_box position-relative">
                    <span className="bw_free_price">0</span>
                    <span className="bw_free_currency bw_zero">USD</span>
                    <span className="bw_free_perMonth">/mo.</span>
                  </div>
                  <div className="bw_free_btn_box">
                    <Link
                      to={{
                                    pathname: "/free-trial-smo",
                                    state: { planName: "FREEMIUM" },
                                  }}
                      className="bw_start_my_subscription_btn bwn_animation_btn bwn_new_sub_btn"
                    >
                     Start my Subscription
                    </Link>
                  </div>
                  <div className="bw_free_box_subheading">
                    <p>What’s included:</p>
                    <ul className="list-unstyled">
                      <li>
                        <span>
                          5 Posts Per Month (Including Festive & Birthstone
                          Posts)
                        </span>
                      </li>
                      <li>
                        <span>Tutorials & Materials</span>
                      </li>
                      <li>
                        <span>Social Media Account Creation</span>
                      </li>
                      <li className="bw_free_font_waight">
                        <span>
                          1 Free Consultation Call With A Social Media Expert
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={3} lg={3} className="px-md-0">
                <div className="bw_free_plans_box_inner">
                  <h3>GOLD</h3>
                  <div className="bw_free_plans_pricing_box position-relative">
                    <span className="bw_free_price">250</span>
                    <span className="bw_free_currency">USD</span>
                    <span className="bw_free_perMonth">/mo.</span>
                  </div>
                  <div className="bw_free_btn_box">
                    <Link
                      to={{
                                    pathname: "/consultation",
                                    state: { planName: "GOLD" },
                                  }}
                      className="bw_start_my_subscription_btn"
                    >
                   Start my Subscription
                    </Link>
                  </div>
                  <div className="bw_free_box_subheading">
                    <p>What’s included:</p>
                    <ul className="list-unstyled">
                      <li>
                        <span>
                          10 Posts Per Month (Including Festive & Birthstone
                          Posts)
                        </span>
                      </li>
                      <li>
                        <span>Social Media Channels are Facebook, Instagram.</span>
                      </li>
                      <li>
                        <span>Setup of Social Media accounts</span>
                      </li>
                      <li>
                        <span>Social Media Account Optimization</span>
                      </li>
                      <li>
                        <span>Content Writing for Post & Captions</span>
                      </li>
                      <li>
                        <span>Custom Designing for Post.</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Video Editing for Posts</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Followers Growth strategy and implementation.</span>
                      </li>
                       <li className="bwn_free_plans_li_before_no">
                        <span>Story & Highlights cover management.</span>
                      </li>
                       <li className="bwn_free_plans_li_before_no">
                        <span>Ad Campaign management - Facebook</span>
                      </li>
                       <li className="bwn_free_plans_li_before_no">
                        <span>Ad Campaign management - Instagram</span>
                      </li>
                       <li className="bwn_free_plans_li_before_no">
                        <span>Customized Feed (Theme management)</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Insta Shop setup & management</span>
                      </li>
                      <li>
                        <span>Research for Trending Hashtags</span>
                      </li>
                      <li>
                        <span>Trending audio selection for Reels/Videos</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Audience analysis</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Comments & Like management</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Reply to Customers direct Messages (DM)</span>
                      </li>
                      <li>
                        <span>Festive Posts</span>
                      </li>
                       <li className="bwn_free_plans_li_before_no">
                        <span>Social Media Calendar</span>
                      </li>
                       <li>
                        <span>Monthly Consultation & Report</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={3} lg={3} className="px-md-0">
                <div className="bw_free_plans_box_inner">
                  <h3>PLATINUM</h3>
                  <div className="bw_free_plans_pricing_box position-relative">
                    <span className="bw_free_price">350</span>
                    <span className="bw_free_currency">USD</span>
                    <span className="bw_free_perMonth">/mo.</span>
                  </div>
                  <div className="bw_free_btn_box">
                    <Link
                      to={{
                                    pathname: "/consultation",
                                    state: { planName: "PLATINUM" },
                                  }}
                      className="bw_start_my_subscription_btn"
                    >
                     Start my Subscription
                    </Link>
                  </div>
                  <div className="bw_free_box_subheading">
                    <p>What’s included:</p>
                    <ul className="list-unstyled">
                      <li>
                        <span>
                          20 Custom-Designed Posts Per Month (Including Videos,
                          GIFs, and Festive & Birthstone Posts)
                        </span>
                      </li>
                      <li>
                        <span>
                          Social Media Channels are Facebook, Instagram, Pinterest.
                        </span>
                      </li>
                      <li>
                        <span>Setup of Social Media accounts</span>
                      </li>
                      <li>
                        <span>Social Media Account Optimization</span>
                      </li>
                      <li>
                        <span>Content Writing for Post & Captions</span>
                      </li>
                      <li>
                        <span>
                          Custom Designing for Post
                        </span>
                      </li>
                      <li>
                        <span>Video Editing for Posts</span>
                      </li>
                      <li>
                        <span>Followers Growth strategy and implementation</span>
                      </li>
                      <li>
                        <span>Story & Highlights cover management</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Ad Campaign management - Facebook</span>
                      </li>
                      <li>
                        <span>Ad Campaign management - Instagram</span>
                      </li>
                      <li>
                        <span>Customized Feed (Theme management)</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Insta Shop setup & management</span>
                      </li>
                      <li>
                        <span>Research for Trending Hashtags</span>
                      </li>
                      <li>
                        <span>Trending audio selection for Reels/Videos</span>
                      </li>
                      <li>
                        <span>Audience analysis</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Comments & Like management</span>
                      </li>
                      <li className="bwn_free_plans_li_before_no">
                        <span>Reply to Customers direct Messages (DM)</span>
                      </li>
                      <li>
                        <span>Festive Posts</span>
                      </li>
                      <li>
                        <span>Social Media Calendar</span>
                      </li>
                      <li>
                        <span>Monthly Consultation & Report</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={3} lg={3} className="px-md-0">
                <div className="bw_free_plans_box_inner border-0">
                  <h3>Rhodium</h3>
                  <div className="bw_free_plans_pricing_box position-relative">
                    <span className="bw_free_price">450</span>
                    <span className="bw_free_currency">USD</span>
                    <span className="bw_free_perMonth">/mo.</span>
                  </div>
                  <div className="bw_free_btn_box">
                    <Link
                      to={{
                                    pathname: "/consultation",
                                    state: { planName: "TITANIUM" },
                                  }}
                      className="bw_start_my_subscription_btn"
                    >
                     Start my Subscription
                    </Link>
                  </div>
                  <div className="bw_free_box_subheading">
                    <p>What’s included:</p>
                    <ul className="list-unstyled">
                      <li>
                        <span>
                          30 Custom-Designed Posts Per Month (Including Videos,
                          GIFs, and Festive & Birthstone Posts)
                        </span>
                      </li>
                      <li>
                        <span>
                          Social Media Channels are Facebook, Instagram, Pinterest, TikTok.
                        </span>
                      </li> 
                      <li>
                        <span>Setup of Social Media accounts</span>
                      </li>
                      <li>
                        <span>Social Media Account Optimization</span>
                      </li>
                      <li>
                        <span>Content Writing for Post & Captions</span>
                      </li>
                      <li>
                        <span>
                        Custom Designing for Post
                        </span>
                      </li>
                      <li>
                        <span>Video Editing for Posts</span>
                      </li>
                      <li>
                        <span>Followers Growth strategy and implementation</span>
                      </li>
                      <li>
                        <span>Story & Highlights cover management</span>
                      </li>
                      <li>
                        <span>Ad Campaign management - Facebook</span>
                      </li>
                      <li>
                        <span>Ad Campaign management - Instagram</span>
                      </li>
                      <li>
                        <span>Customized Feed (Theme management)</span>
                      </li>
                      <li>
                        <span>Insta Shop setup & management</span>
                      </li>
                      <li>
                        <span>Research for Trending Hashtags</span>
                      </li>
                      <li>
                        <span>Trending audio selection for Reels/Videos</span>
                      </li>
                      <li>
                        <span>Audience analysis </span>
                      </li>
                      <li>
                        <span>Comments & Like management</span>
                      </li>
                      <li>
                        <span>Reply to Customers direct Messages (DM)</span>
                      </li>
                      <li>
                        <span>Festive Posts</span>
                      </li>
                      <li>
                        <span>Social Media Calendar</span>
                      </li>
                      <li>
                        <span>Monthly Consultation & Report</span>
                      </li>
                    </ul>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        </div>

      </div>
    </>
  );
};

export default React.memo(FreePlansSmo);
