import React from "react";
import { Helmet } from "react-helmet";
import "../../Assets/css/webDevelopmentDesign.css";
import { Row, Col, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import WddImage1 from "../../Assets/images/web-development-and-design/design2.jpg";
import WddImage2 from "../../Assets/images/web-development-and-design/wdd2.webp";
import ServiceIcon from "../../Assets/images/web-development-and-design/green-services-icon.webp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import WebImg1 from "../../Assets/images/web-img/web1.png"
import WebImg2 from "../../Assets/images/web-img/web2.png"
import WebImg3 from "../../Assets/images/web-img/web3.png"
import WebImg4 from "../../Assets/images/web-img/web4.png"


const WebDevelopmentDesign = () => {
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Top Web Development Company For Jewelers | Belgium WebNet
        </title>
        <meta
          name="description"
          content="	Drive traffic to your jewelry store with a customized website made by our experts at Belgium WebNet-a web development company for jewelers. Book an appointment."
        ></meta>
        <meta name="keywords" content="web design company for jewelers"></meta>
      </Helmet>
      <section className="bw_wdd_section">
        <div className="bw_all_banner bw_wdd_banner">
          <Container className="bw_custome_container">
            <Row className=" w-100 m-auto">
              <Col>
                <h1 className="text-center text-white text-capitalize bw_wdd_heading">
                  Web Design & Development
                </h1>
                <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="/" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/contacts" className="text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_website_desing">
          <Container className="bw_custome_container">
            <Row>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="bw_website_deigns_innerbox1 order-xs-2 order-md-1"
              >
                <div>
                  <div>
                    <h2>
                      Web Design & Development
                    </h2>
                    <p>
                      Let us make your dream a reality! We develop seamless
                      experiences using out-of-the-box ideas and smooth
                      execution. Our designers offer a specialized website
                      development solution that is secure, responsive, and fit
                      for any screen size. Today, websites are vital to a
                      company's capacity to generate revenue. A powerful website
                      enables businesses to manage products, reviews,
                      promotions, and so much more.
                    </p>
                  </div>
                </div>
              </Col>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="bw_wdd_img_right_box order-xs-1 order-md-2"
              >
                <div>
                  <LazyLoadImage
                    src={WddImage1}
                    alt="Web Development &amp; design image"
                    className="bwn_website_design_image"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_why_choose_us">
          <Container className="bw_custome_container">
            <Row>
              <Col sm={6} md={7} lg={7}>
                <div>
                  <h2>
                    What Sets Us Apart?
                  </h2>
                  <ul
                    className="pl-0"
                  >
                    <li>
                      <p>
                        Aesthetic and custom websites with cutting-edge UI/UX
                        layouts.
                      </p>
                    </li>
                    <li>
                      <p>
                        Web development that is streamlined, quick, and
                        user-friendly.
                      </p>
                    </li>
                    <li>
                      <p>SEO-friendly content that is easy to adapt and edit.</p>{" "}
                    </li>
                    <li>
                      <p>Appealing websites that visually attract users.</p>
                    </li>
                    <li>
                      <p>Websites with maximum traffic and global reach.</p>{" "}
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col sm={6} md={5} lg={5} className="bw_wdd_imagebox2">
                <div>
                  <LazyLoadImage
                    src={WddImage2}
                    alt="web Development and design Image"
                    className="img-fluid"
                  />
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bw_faq_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  Why Choose Our Web Designing Services
                </h2>
              </Col>
            </Row>
            <Row className="mb-lg-4">
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={WebImg1} alt="Service Icon" />
                  <p>The website is the face and identity of a company.</p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={WebImg2} alt="Service Icon" />
                  <p>
                    A well-designed site will attract visitors and increase
                    business.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={WebImg3} alt="Service Icon" />
                  <p>
                    A good website design with an excellent layout is engaging.
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={WebImg4} alt="Service Icon" />
                  <p>
                    Our Designing is consistent for your target market to
                    recognize.
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  FAQ’S
                </h2>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col className="px-md-0">
                <div
                  className="bw_wdd_faq_accordian"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What Is The Turnaround Time For A New Website?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        The estimated time required to build a website can be
                        accurately determined considering its features and
                        clients' expectations. Initial investment and the
                        client's responsiveness will also contribute to deciding
                        how quickly things will get completed. Operational
                        requirements can be crucial in the case of complex
                        websites as it takes more time to develop.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Do We Offer Website Maintenance?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Indeed, our team handles everything. All of our clients
                        receive continuing support from us. Our services cover
                        tracking visitor's traffic and search engine rankings of
                        a website, optimizing database and removing junk,
                        evaluating the loading speed of website pages,
                        scrutinizing broken links, addressing search console
                        issues, and assessing SEO content strategy.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Will my website be mobile-friendly?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Yes, your website will be mobile-friendly. We build
                        mobile-friendly websites by designing them using a
                        mobile-first approach. Our websites are frictionless and
                        work smoothly for mobile users. Our websites are
                        designed to reformat and restructure themselves for
                        different screen sizes.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        Are your websites SEO-friendly?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Yes! We make sure your website ranks higher in search
                        results. We design and build 100% SEO friendly websites
                        to drive more traffic to your page. We create website
                        content by conducting keyword research and targeting
                        valuable keywords, optimizing header tags, meta
                        descriptions, image alt tags to boost site clicks, using
                        internal linking to help search engines find the
                        website, etc.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel5" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What graphic design services do you provide?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        We design everything from logos to trade show displays.
                        Every brand needs an effective visual strategy showcased
                        using a visually appealing design. Our creative team
                        designs attractive and compelling pieces to make your
                        brand stand out. Get in touch with us to discuss your
                        project and design requirements.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(WebDevelopmentDesign);
