import React, { useEffect, useState } from 'react'
import { Col, Container, Image, Row } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import '../../Assets/css/aboutus.css'
import '../../Assets/css/meetOurCto.css'

import AshishPorfileImg from "../../Assets/images/about-us/ashish.webp";
import PhoneImg from "../../Assets/images/about-us/phone.png";
import EmailImg from "../../Assets/images/about-us/email.png";
import InstagramImg from '../../Assets/images/social-img/instagram.png'
import WhatsappImg from '../../Assets/images/social-img/whatsapp.png'
import LinkedinImg from '../../Assets/images/social-img/linkedin.png'
import title from '../../Assets/images/meet-cto-img/london.png'
import title_mb from '../../Assets/images/meet-cto-img/london-mobile.png'
import counrties from '../../Assets/images/about-us/counrties.png'
import { isMobile, isMobileOnly } from 'react-device-detect';
// import NewBranding from '../Front/NewBranding'
import { useHistory } from 'react-router-dom'
import NewBandingUk from '../Front/NewBandingUk'


const MeetOurCto = () => {
    const history = useHistory()
    const [isExpanded, setIsExpanded] = useState(false);
  
    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };
    const content = "I am Ashish Jangir, CTO and Co-founder of Belgium WebNet. With an illustrious 10-year journey in the industry, my initial exposure to the world of Diamonds & Jewelry came during my tenure as a professional in the field. Surrounded by the world of jewelry and diamonds, together with my team we envisioned a way to elevate the industry's growth. After extensive brainstorming and strategic planning, Belgium WebNet was born. Today, Belgium WebNet takes pride in its team of 60+ talented professionals, dedicated to serving more than 150 clients across the Diamond & Jewelry sector in various countries like the USA, UK, and Dubai. Drawing from our vast on-field experience, we understand the immense possibilities that digital transformation can bring to this industry. We look forward to the opportunity of working with you! Book an appointment with me, and let's create a bright digital future for your jewelry business."
    const content1 = "I am Ashish Jangir, CTO and Co-founder of Belgium WebNet. With an illustrious 10-year journey in the industry, my initial exposure to the world of Diamonds & Jewelry came during my tenure as a professional in the field. Surrounded by the world of jewelry and diamonds, together with my team we envisioned a way to elevate the industry's growth. After extensive brainstorming and strategic planning, Belgium WebNet was born. Today, Belgium WebNet takes pride in its team of 60+ talented professionals, dedicated to serving more than 150 clients across the Diamond & Jewelry sector in various countries like the USA, UK, and Dubai. Drawing from our vast on-field experience, we understand the immense possibilities that digital transformation can bring to this industry. We look forward to the opportunity of working with you! Book an appointment with me, and let's create a bright digital future for your jewelry business."

    const displayContent = isExpanded ? content : content.slice(0, 111);
    const displayContent1 = isExpanded ? content1 : content1.slice(0, 269);

  




    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;
        document.body.appendChild(script);
        return () => {
            document.body.removeChild(script);
           
        };

    }, []);

   



    const year = new Date().getFullYear()
    const month = new Date().getMonth()

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>
                      Best Web Design & Digital Marketing Agency In UK | Belgium WebNet
                </title>
                <meta
                    name="description"
                    content="Belgium WebNet specialises in website development & digital marketing services for jewellers. Book a prior appointment to receive 20% off on all our services."
                ></meta>
                <meta name="keywords" content=""></meta>
            </Helmet>
            <section className='bw_marquee_section'>
                    <Container fluid>
                    <Row>
                            <Col sm={12} md={12} lg={12} className='p-0'>
                                <div className='bw_special_sevices_marque_tag'>
                                      <marquee direction="left" loop=""><strong>20% Off </strong> on all The services on prior appointments.</marquee> 
                                </div>
                            </Col>    
                        </Row>
                    </Container>
                </section>
            <div className='bw_about_aash bw_MeetOurCto_section'>
               
                <Container className='bw_custome_container_header1 bw_custome_container_ash_about '>
                    <Row className='bw_new_ash_About_row mt-3'>
                        <Col xs={12} sm={12} md={4} lg={4} className='bw_new_ash_About_col_3'>
                            <div className='bw_ash_profile_box1 mt-0'>

                                {/* <Row>
                                    <Col xs={4} sm={4} md={12} lg={12} className='bw_ashi_img_pading_mobile_view'> */}
                                        <div className='bw_img_assh_profile'>
                                            <Image src={AshishPorfileImg} alt="Ashish Jangir Profile Photo" className=' img-fluid' />
                                        </div>
                                    {/* </Col>
                                </Row> */}
                            </div>
                        </Col>
                        <Col sm={12} md={8} lg={8} className='d-flex align-items-center'>
                            <div className='bw_meet_ashi_text'>
                                <h6>Meet Our CTO,</h6>
                                <h1>Ashish Jangir</h1>
                                 {isMobileOnly ?
                        <img className='m-auto d-table img-fluid' src={title_mb} alt='Ashish Jangir - Book-An-Appointment - Belgium Webnet' /> :
                        <img className='d-table img-fluid' src={title} alt='Ashish Jangir - Book-An-Appointment - Belgium Webnet' />
                    }
                               
                            </div>
                        </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                    <Col sm={12} md={12} lg={12} className='p-0'>
                       <div class="bw_new_branding_main_title_offer text-center m-auto">
                        <h2>What We Offer</h2>
                        </div>
                        </Col>
                    </Row>
                    <NewBandingUk/>

                    <Row>
                    <Col sm={12} md={12} lg={12} className='p-0'>
                        <div className='bw_meet_enjoy_20_off'>
                            <div className='m-auto text-center'>
                            <h2>Enjoy 20% Off on all our services
on prior appointments.</h2>
<div class="bw_btb_border_white" onClick={() => history.push("/about-us/ashish-jangir")}><button type="button" class="bw_custom_btn btn btn-primary" >Book An Appointment</button></div>
                            </div>


                        </div>
                    </Col>
                    </Row>
                </Container>
            </div>

            <div className='client-country text-center'>
                <h3>Our Clients around the world</h3>
                <img className='img-fluid' src={counrties} alt='Our Clients' />
            </div>
        </>
    )
}

export default MeetOurCto