import React from "react";
import { Helmet } from "react-helmet";
import "../../Assets/css/webDevelopmentDesign.css";
import { Row, Col, Container } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { BsChevronRight } from "react-icons/bs";
import WddImageSmo from "../../Assets/images/web-development-and-design/design1.jpg";
import WddImage2 from "../../Assets/images/web-development-and-design/wdd2.webp";
import ServiceIcon from "../../Assets/images/web-development-and-design/green-services-icon.webp";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import { LazyLoadImage } from "react-lazy-load-image-component";
import SmmImg1 from '../../Assets/images/smm-img/smm1.png'
import SmmImg2 from '../../Assets/images/smm-img/smm2.png'
import SmmImg3 from '../../Assets/images/smm-img/smm3.png'
import SmmImg4 from '../../Assets/images/smm-img/smm4.png'

const SocialMediaMarketing = () => {
  const [expanded, setExpanded] = React.useState("panel2");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        The Best Social Media Marketing Company For Jewelers
        </title>
        <meta
          name="description"
          content="Social media marketing for jewelry is essential. We are the best social media marketing company for jewelers to reach out to their target customers efficiently."
        ></meta>
        <meta name="keywords" content="social media marketing for jewelry,  best social media marketing company for jewelers"></meta>
      </Helmet>
      <section className="bw_wdd_section">
        <div className="bw_all_banner bw_wdd_banner">
          <Container className="bw_custome_container">
            <Row>
              <Col>
                <h1 className="text-center text-white text-capitalize">
                  Social Media Marketing
                </h1>
                <ul className=" d-flex bw_Contact_link text-center pl-0">
                  <li>
                    <NavLink to="/" className="text-white">
                      Home
                    </NavLink>
                  </li>
                  <li>
                    <BsChevronRight className="text-white" />
                  </li>
                  <li>
                    <NavLink to="/contacts" className="text-white">
                      Contact
                    </NavLink>
                  </li>
                </ul>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_website_desing">
          <Container className="bw_custome_container">
            <Row>
              <Col
                sm={12}
                md={6}
                lg={6}
                className="bw_website_deigns_innerbox1"
              >
                <div>
                  <div>
                    <h2>
                    Social Media Marketing
                    </h2>
                    <p>
                      We harness the power of social media networking platforms
                      to build a brand image. We benefit existing and potential
                      buyers with the appropriate jewelry merchandise to keep
                      them hooked to your products and services. With the help
                      of several social media forums, we strive to increase
                      audience engagement and your brand's visibility. By
                      rebuilding the image of your jewelry store online, we
                      ensure your customers reach you hassle-free.
                    </p>
                  </div>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} className="bw_wdd_img_right_box">
                <div>
                  <LazyLoadImage
                    src={WddImageSmo}
                    alt="Web Development &amp; design image"
                    className="img-fluid mb-xs-2"
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_why_choose_us">
          <Container className="bw_custome_container">
            <Row>
              <Col sm={6} md={7} lg={7}>
                <div>
                  <h2>
                    What Sets Us Apart?
                  </h2>
                  <ul
                    className="pl-0"
                  >
                    <li>
                      <p>Gain precious insights about your brand.</p>
                    </li>
                    <li>
                      <p>
                        Tools with optimum resources that generate organic
                        traffic.
                      </p>
                    </li>
                    <li>
                      <p>
                        Build brand awareness by getting noticed using
                        eye-catching visuals and unique content.
                      </p>{" "}
                    </li>
                    <li>
                      {" "}
                      <p>
                        Generate revenue with the help of our research,
                        marketing, and advertising strategies.
                      </p>
                    </li>
                    <li>
                      <p>
                        Boost traffic by improving engagement rates on social
                        media channels.
                      </p>{" "}
                    </li>
                  </ul>
                </div>
              </Col>
              {/* <Col sm={6} md={5} lg={5} className="bw_wdd_imagebox2">
                <div>
                  <LazyLoadImage
                    src={WddImage2}
                    alt="web Development and design Image"
                    className="img-fluid"
                  />
                </div>
              </Col> */}
            </Row>
          </Container>
        </div>
        <div className="bw_faq_section">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  Why Choose Our SMM Services
                </h2>
              </Col>
            </Row>
            <Row className="mb-lg-4">
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SmmImg1} alt="Service Icon" />
                  <p>Social Media Marketing that improves brand awareness and engagement. </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SmmImg2} alt="Service Icon" />
                  <p>
                   Improves search engine rankings and builds better customer satisfaction
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SmmImg3} alt="Service Icon" />
                  <p>
                 Helps understand your target customers’ interests
                  </p>
                </div>
              </Col>
              <Col sm={12} md={6} lg={6} xl={3}>
                <div
                  className="bw_wdd_dervices_box"
                >
                  <LazyLoadImage src={SmmImg4} alt="Service Icon" />
                  <p>
                    Helps drive traffic to your website
                  </p>
                </div>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col>
                <h2>
                  FAQ’S
                </h2>
              </Col>
            </Row>
            <Row className="w-100 m-auto">
              <Col className="px-md-0">
                <div
                  className="bw_wdd_faq_accordian"
                >
                  <Accordion
                    expanded={expanded === "panel1"}
                    onChange={handleChange("panel1")}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel1" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        How Social Media Assists Growth?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        By choosing to target people who match your current
                        client base or people based on how they engage with your
                        company's website. Social media is a powerful tool for
                        reaching a wide audience since it enables businesses to
                        expand their reach and avoid wasting their time or
                        resources on people who don't care about their brand.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel2"}
                    onChange={handleChange("panel2")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel2" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel2a-content"
                      id="panel2a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        How Frequently Should You Post On Social Media?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        The optimal frequency for Social media posting depends
                        on your target audience. You can post once a day, maybe
                        once a week, or on alternate days of the week. The
                        result is dependent on the interactivity and activeness
                        of your audience. We focus on the quality of your social
                        media content rather than prioritizing the quantity of
                        social media posts.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel3"}
                    onChange={handleChange("panel3")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel3" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel3a-content"
                      id="panel3a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What Kind Of Content Works Best For Social Media?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        The content you post vastly depends on your business
                        type and the substantial preferences of your target
                        audience. Posting content that attracts your audience
                        and helps build engagement would be an ideal pattern for
                        you to follow. You can also implement some of our proven
                        social media strategies to get results and conclude the
                        type of content your audience usually prefers.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel4"}
                    onChange={handleChange("panel4")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel4" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel4a-content"
                      id="panel4a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        How To Effectively Boost Follower Count?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Growth is proportional to strategic planning and
                        posting. Every post should align with the interest of
                        your target audience, and that is how you can gradually
                        increase the follower count. Subscribe to our social
                        media plan to see noticeable growth on your social media
                        channels.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion
                    expanded={expanded === "panel5"}
                    onChange={handleChange("panel5")}
                    defaultExpanded={true}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === "panel5" ? (
                          <AiOutlineMinus />
                        ) : (
                          <AiOutlinePlus />
                        )
                      }
                      aria-controls="panel5a-content"
                      id="panel5a-header"
                    >
                      <Typography className="bw_accordian_heading">
                        What Is The Average Visibility Rate For Each Post On
                        Instagram?
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails className="border-top">
                      <Typography className="bw_accordian_ans">
                        Instagram has not officially commented on the statistic,
                        but several users have noticed that their posts do not
                        reach most of their followers. Due to Instagram giving
                        priority to posts from your regular contact accounts,
                        this reach is less than 10%.
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(SocialMediaMarketing);
