import React from 'react'
import { useParams } from 'react-router-dom'
import '../../Assets/css/url-responsive.css';
import { DesignThemeData } from './DiamondJewelryDesigns';
import Logo from '../../Assets/images/home/bw-circle-logo.png';
import {MdOutlineDesktopWindows} from 'react-icons/md';
import {BsTablet} from 'react-icons/bs';
import {VscDeviceMobile} from 'react-icons/vsc';
import {AiOutlineDownload} from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useState  } from 'react';
import { Container, Form } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import {GrClose} from 'react-icons/gr';
import { useEffect } from 'react';



const ThemePreview = () => {
   
    
    let {theme} = useParams();
    const history = useHistory();
    

    const [currentWidth , setCurrentWidth] = useState("100%");
    const [toggle , setToggle] = useState(1)
    const [iframeKey, setIframeKey] = useState(0);
    const [themeValue , setThemeValue] = useState(theme)
    const [showSwitcher, setShowSwitch] = useState(true);
   

    // const getUrl = DesignThemeData.filter(({name}) => name === theme)
    // const {url} = getUrl[0];
    
    // use the above url in iframe for dynamic url


    const handleChange = (e) => {
        const newUrl = `/diamond-jewelry-designs/${e.target.value}`;
        history.push(newUrl);
        setThemeValue(e.target.value);
    }

    const handleToggle = (index , width) => {
      setToggle(index)
      setCurrentWidth(width)
      setIframeKey((prevKey) => prevKey + 1);
    }

    useEffect(() => {
        document.getElementById("myHeader").style.display = "none";
        document.getElementById("myHeader2").style.display = "none";
        document.getElementById("myFooter").style.display = "none";
    }, [])

    return (
        <div>
            {
            //   !crossHeader ?
            <div className={`${!showSwitcher && 'hidden-switcher'} switcher-wrapper position-relative`}>
              <div className='switcher-header fixed-top shadow-sm'>
              <Container>
                  <div className='switcher-inner'>
                      <div className='logo'>
                          <img src={Logo} alt="Logo" />
                      </div>
  
                      <div className='theme-selector position-relative'>
                          <Form.Select aria-label="Default select example" value = {themeValue} onChange={handleChange}>
                              {DesignThemeData.map((theme , index) => (
                                  <option key = {index} value = {theme.name}>{theme.name}</option>
                              ))}
                          </Form.Select>
                      </div>
  
                      <div className='responsive position-relative'>
                          <ul className='list-unstyled'>
                              <li className={toggle === 1 ? "active" : ""} onClick={() => handleToggle(1 , "100%")}><MdOutlineDesktopWindows /></li>
                              <li className={toggle === 2 ? "active" : ""} onClick={() => handleToggle(2, "768px")}><BsTablet /></li>
                              <li className={`${toggle === 3 ? "active" : ""} rotate-90`}  onClick={() => handleToggle(3 , "1024px")}><BsTablet /></li>
                              <li className={toggle === 4 ? "active" : ""} onClick={() => handleToggle(4 , "343px")}><VscDeviceMobile /></li>
                              <li className={`${toggle === 5 ? "active" : ""} rotate-90`} onClick={() => handleToggle(5 , "844px")}><VscDeviceMobile /></li>
                          </ul>
                      </div>
  
                      <div className='links position-relative'>
                          <Link to="/" className='btn btn-primary'><span></span> <span>Start Consulting</span></Link>
                      </div>
                      
                  </div>
              </Container>
          </div> 
          <div className='close_btn'>
                          {/* <button onClick = {() => setCrossHeader(!crossHeader)}>X</button> */}
                          <button onClick={() => setShowSwitch(!showSwitcher)}><GrClose /></button>
                      </div>
                      </div>
        //   : null
            }
            <iframe key={iframeKey} style = {{width : currentWidth }} className='themePreviewUrl' src= "https://showcase.demobw.com/"></iframe>
        </div>
    )
}

export default ThemePreview;
