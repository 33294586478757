import React from "react";
import "../../Assets/css/careers.css";
import { Col, Container, Form, Row, Button } from "react-bootstrap";
import { HiOutlineSearch } from "react-icons/hi";
import { MdLocationOn } from "react-icons/md";
import { IoIosArrowForward } from "react-icons/io";
import { NavLink } from "react-router-dom";
import Helmet from "react-helmet";
import axios from "axios";
import { baseURL } from "../../Helpers/request";
import swal from "sweetalert";
import "../../Assets/css/Sweetalert.css";

function Careers() {
  const [careerlist, setCareerlist] = React.useState([]);
  const [searchField, setSearchField] = React.useState("");
  const [careerlength, setCareerlength] = React.useState(9);
  React.useEffect(() => {
    GetCareerlist();
  }, []);
  const GetCareerlist = () => {
    axios
      .get(`${baseURL}/career/listing`)
      .then((career) => {
        if (career.data.status == 1) {
          setCareerlist(career.data.data);
        } else {
          swal(career.data.message, { icon: "warning" });
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
        }
      });
  };

  const filteredCareers = careerlist.filter((person) => {
    return person.title.toLowerCase().includes(searchField.toLowerCase());
  });

  const searchFunction = (e) => {
    if (e.target.value.length > 0) {
      setCareerlength(careerlist.length)
    } else {
      setCareerlength(9)
    };
    setSearchField(e.target.value);
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Job Openings - Belgium Webnet</title>
        <meta
          name="description"
          content="We provide the best opportunities and have ideal job openings to help you jumpstart your careers. Get in touch with us to find a job that suits you perfectly."
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_careers_section">
        <div className="bw_all_banner">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1 className="bw_banner_heading">Job Opportunities For You</h1>
                <p className="bw_banner_sub_heading">
                  Belgium Webnet keeps employees in the heart and has an open working environment with complete transparency and freedom in the employment culture.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_excited_to_work">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <div className="bw_excited_to_work_inner">
                  <h2>Come Work With Us</h2>
                  <p>
                    Let’s create an innovative and prosperous space together! We are on a quest for people like you who have similar interests.
                  </p>
                </div>
                <div
                  className="bw_car_search_box position-relative m-auto"
                >
                  <Form.Control
                    type="search"
                    row={true}
                    placeholder="Search"
                    onChange={searchFunction}
                    fullwidth
                  />
                  <HiOutlineSearch />
                </div>
              </Col>
            </Row>
            {filteredCareers.length ? <Row className="bw_car_tech_padding">
              {filteredCareers.map((career) => (
                <Col
                  sm={12}
                  md={6}
                  lg={4}
                  key={career.id}
                >
                  <NavLink
                    to={`/careers/${career.title
                      .toLowerCase()
                      .replaceAll(" ", "-")
                      .replaceAll("/", "_")}`}
                    className="text-decoration-none"
                    onClick={() => localStorage.setItem('Job_id', career.id)}
                  ><div className="bw_car_tech_box">
                      <h4>{career.title}</h4>
                      <p>{`Experience : ${career.experience}`}</p>
                      <p>
                        <MdLocationOn className="bw_map_svg" />
                        {career.location}
                      </p>
                      <div className="bw_car_tech_next_box">
                        <IoIosArrowForward />
                      </div>
                    </div>
                  </NavLink>
                </Col>
              ))}
            </Row> : <Row className="bw_car_tech_padding"><div className="text-dark text-center">No data Available!</div></Row>}

          </Container>
        </div>

        {/* {careerlength <= 9 ? <Row>
              <div className="bw_btb_border_black text-center m-auto pb-2">
                <Button className="bw_custom_btn text-uppercasee bw_btn_load_more" onClick={(e)=>setCareerlength(careerlist.length)}>
                  Load More
                </Button>
              </div>
            </Row> : ''} */}
      </section>
    </>
  );
}

export default React.memo(Careers);
