import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import LeftIcon from "../../Assets/images/home/left_icon.png"
import Review1 from "../../Assets/images/review-img/review1.png"
import Review2 from "../../Assets/images/review-img/review2.png"
import Review3 from "../../Assets/images/review-img/review3.png"
import Review4 from "../../Assets/images/review-img/review4.png"
import Review5 from "../../Assets/images/review-img/review5.png"
import RightIcon from "../../Assets/images/home/right_arrow.png"
import GoogleReview from "../../Assets/images/home/googleReview.png"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Rating from '@mui/material/Rating';
import axios from "axios";
import { baseURL } from "../../Helpers/request";
import swal from "sweetalert";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ReviewSlider = () => {
    //const [value, setValue] = React.useState(5);
    const [review,setReview] = useState([])
     
    useEffect(()=>{
        getReview()
    },[])

    const getReview = async() =>{
        await axios.get(`${baseURL}/review/listing`).then((res) => {
       
            if (res && res.data && res.data.status === 1) {
              if(res.data.data.length > 0){
 setReview(res && res.data && res.data.data);
              }
             
            } else {
              swal(res && res.data && res.data.data.message, { icon: "warning" });
            }
          })
          .catch((error) => {
            if (error) {
              swal("something went wrong", { icon: "error" });
            }
          });
    }

    const settings = {
        infinite: true,
        speed: 1000,
        autoplay:true,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <LazyLoadImage src={RightIcon} />,
        prevArrow: <LazyLoadImage src={LeftIcon} />,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                  slidesToScroll: 1,
                  infinite: false,
                }
              },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                infinite: false,
              }
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1
              }
            }
          ]
    };
    
    return (
        <>
            <section className="bw_google_review_section">
            
                {/* <Container className="bw_custome_container">
                    <Row>
                        <Col lg={12} md={12} xs={12} className="mr-auto">
                            <div className="bw_google_review_title">
                                <h2>
                                    Relationships based <br /> on trust.
                                </h2>
                            </div>
                        </Col>

                        <Col lg={12} md={12} xs={12} >

                            <div className="bw_brand_slider_right_slide_main google_review_slider_main">
                                <Slider {...settings}>
                                    {review.map(res => <Col>
                                        <div className="google_review_slider_box">
                                            <div className="google_review_slider_box_rating">
                                            
                                                <Rating name="read-only" value={res.value} size="large" readOnly />
                                            </div>
                                           
                                            <ReactReadMoreReadLess
                                            charLimit={200}
                                            readMoreText={"Read more"}
                                            readLessText={"Read less"}
                                            readMoreClassName="read-more-less--more"
                                            readLessClassName="read-more-less--less"
                                          >
                                           {`"${res.content}"`}
                                          </ReactReadMoreReadLess>
                                            <h4> {res.name}</h4>
                                            <LazyLoadImage src={res.image} alt={res.name}/>
                                        </div>
                                    </Col>)}
                                </Slider>
                            </div>

                        </Col>

                    </Row>
                </Container> */}
                 <Container className="bw_custome_container">
                    <Row>
                        <Col lg={12} md={12} xs={12} className="mr-auto">
                            <div className="bw_google_review_title">
                                <h2>
                                    Relationships based on trust
                                </h2>
                            </div>
                        </Col>

                        <Col lg={12} md={12} xs={12} >

                            <div className="bw_brand_slider_right_slide_main google_review_slider_main">
                                <Slider {...settings}>
                                   
                                       <Col>
                                        <div className="google_review_slider_box">
                                            <div className="google_review_slider_box_rating">
                                            
                                                <Rating name="read-only" value={5} size="large" readOnly />
                                            </div>
                                            <p>Absolutely the best Web Company to work with. Salt of the earth group of individuals and their follow through is impeccable. Quality of work is unmatched...</p>
                                            <a href="https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,," target="_blank" className="bwn_review_nevigate_link">Read More</a>
                                            <h4>Kae Louise</h4>
                                            <Image src={Review1} alt="User Review Image" className="bwn_review_user_image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                            <LazyLoadImage src={GoogleReview} alt="image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                        </div>
                                    </Col>
                                       <Col>
                                        <div className="google_review_slider_box">
                                            <div className="google_review_slider_box_rating">
                                            
                                                <Rating name="read-only" value={5} size="large" readOnly />
                                            </div>
                                             <p>Thank you Anmol for creating my beautiful new website! I love how well it works from my cell phone and, today I found a gorgeous pair of earrings there...</p>
                                                <a href="https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,," target="_blank" className="bwn_review_nevigate_link">Read More</a>
                                            <h4>Nicci Torrente </h4>
                                             <Image src={Review2} alt="User Review Image" className="bwn_review_user_image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                            <LazyLoadImage src={GoogleReview} alt="image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                        </div>
                                    </Col>
                                       <Col>
                                        <div className="google_review_slider_box">
                                            <div className="google_review_slider_box_rating">
                                            
                                                <Rating name="read-only" value={5} size="large" readOnly />
                                            </div>
                                             <p>Belgium WebNet has provided our company, Taylor LaRue Designs Fine Jewelry, a positive working experience. They're delivered a fast turnaround on our new website's...</p>
                                                <a href="https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,," target="_blank" className="bwn_review_nevigate_link">Read More</a>
                                            <h4>J McLaurin </h4>
                                             <Image src={Review3} alt="User Review Image" className="bwn_review_user_image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                            <LazyLoadImage src={GoogleReview} alt="image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                        </div>
                                    </Col> 
                                    <Col>
                                        <div className="google_review_slider_box">
                                            <div className="google_review_slider_box_rating">
                                            
                                                <Rating name="read-only" value={5} size="large" readOnly />
                                            </div>
                                             <p>From concept to go-live, the Belgium team was amazing. They built me a beautiful site at a super competitive price and were always quick to respond and address..</p>
                                                <a href="https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,," target="_blank" className="bwn_review_nevigate_link">Read More</a>
                                            <h4>Andrew Gomez </h4>
                                             <Image src={Review4} alt="User Review Image" className="bwn_review_user_image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                            <LazyLoadImage src={GoogleReview} alt="image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="google_review_slider_box">
                                            <div className="google_review_slider_box_rating">
                                            
                                                <Rating name="read-only" value={5} size="large" readOnly />
                                            </div>
                                             <p>OMG!!! I wish there were more stars - 5 just doesn't cut it! We, the Owners at Cultured Brilliance, couldn't be happier with our website experience.</p>
                                                <a href="https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,," target="_blank" className="bwn_review_nevigate_link">Read More</a>
                                            <h4>Kathleen Dolan </h4>
                                             <Image src={Review5} alt="User Review Image" className="bwn_review_user_image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                            <LazyLoadImage src={GoogleReview} alt="image" onClick={()=>window.open("https://www.google.com/search?q=belgium+webnet+goolge+review&oq=belgium&aqs=chrome.1.69i60j69i59j46i433i512j0i131i433i512j69i60l3j69i65.11206j1j4&sourceid=chrome&ie=UTF-8#lrd=0x89c2590a50b3a235:0x8af09874060f51fc,1,,,", "_blank")}/>
                                        </div>
                                    </Col>
                
                                </Slider>
                            </div>

                        </Col>

                    </Row>
                </Container>
            </section>
        </>
    );
};

export default React.memo(ReviewSlider);
