import React from "react";
import "../../Assets/css/blog.css";
import { Helmet } from "react-helmet";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { HiOutlineSearch } from "react-icons/hi";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import axios from "axios";
import swal from "sweetalert";
import { baseURL } from "../../Helpers/request";
const Blog = () => {
  const [searchField, setSearchField] = React.useState("");
  const [search, setSearch] = React.useState(false);
  const [blog, setBlog] = React.useState([]);
  const [blog1, setBlog1] = React.useState([]);
  const [blog2, setBlog2] = React.useState([]);
  const [blog3, setBlog3] = React.useState([]);
  const [blogurl1, setBlogurl1] = React.useState("");
  const [blogurl2, setBlogurl2] = React.useState("");
  const [blogurl3, setBlogurl3] = React.useState("");
  React.useEffect(() => {
    getBlog();
  }, []);
  const getBlog = () => {
    axios
      .get(`${baseURL}/blog/listing`)
      .then((blog) => {
        if (blog.data.status == 1) {
          setBlog(blog.data.data);
          setBlog1(blog.data.data[0]);
          setBlog2(blog.data.data[1]);
          setBlog3(blog.data.data[2]);
          setBlogurl1(
            `/blogs/${blog.data.data[0].blog_url}`
          );
          setBlogurl2(
            `/blogs/${blog.data.data[1].blog_url}`
          );
          setBlogurl3(
            `/blogs/${blog.data.data[2].blog_url}`
          );
        } else {
          swal(blog.data.message, { icon: "warning" });
        }
      })
      .catch((error) => {
        if (error) {
          swal("something went wrong", { icon: "error" });
        }
      });
  };
  const filteredBlogs = blog.filter((blog) => {
    return blog.heading.toLowerCase().includes(searchField.toLowerCase());
  });
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Blogs | Belgium WebNet</title>
        <meta
          name="description"
          content="Explore expert insights and trends in jewelry business growth with Belgium WebNet blogs. Uncover strategies for web development, digital marketing, and more!"
        ></meta>
        <meta name="keywords" content="belgium webnet blogs"></meta>
      </Helmet>
      <section className="bw_blog_section">
        <div className="bw_all_banner">
          <Container className="bw_custome_container">
            <Row>
              <Col>
                <h1 className="bw_banner_heading">Blog</h1>
                <p className="bw_banner_sub_heading">
                An Insight Into Our Digital Space!
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <Container className="bw_custome_container bw_blogs_container">
          <Row>
            <Col>
              <div
                className="bw_car_search_box position-relative m-auto"
              >
                <Form.Control
                  type="search"
                  row
                  value={searchField}
                  placeholder="Search"
                  onChange={(e) => {
                    setSearchField(e.target.value);
                    if (e.target.value.length > 0) {
                      setSearch(true);
                    } else {
                      setSearch(false);
                    }
                  }}
                  fullwidth
                />
                <HiOutlineSearch />
              </div>
            </Col>
          </Row>
          {search ? (
            ""
          ) : (
            <>
              <Row className="w-100 m-auto">
                <Col className="pl-md-0">
                  <div
                    className="bw_recent_blog_heading"
                  >
                    <h2>Recent</h2>
                  </div>
                </Col>
              </Row>
              <Row className="w-100 m-auto">
                <Col
                  sm={12}
                  md={12}
                  lg={7}
                  className="pr-lg-4 pl-lg-0 mb-md-5"
                >
                  <div className="bw_recent_blog1">
                    <NavLink to={blogurl1}>
                    {
                      blog1.image &&  <LazyLoadImage height={200} src={blog1.image} alt="Blog Image" />
                    }
                     
                    </NavLink>
                    <h6 className="bw_blog_post_date">
                      {blog1.name}
                      <span className="bw_post_date"> {blog1.date}</span>
                    </h6>
                    <NavLink to={blogurl1}>
                      <h4>{blog1.heading}</h4>
                    </NavLink>
                    <p className="datalimit" dangerouslySetInnerHTML={{ __html: blog1.content }}/>
                    {/* <p dangerouslySetInnerHTML={{ __html: blog1.content }}/> */}
                  </div>
                </Col>
                <Col
                  sm={12}
                  md={12}
                  lg={5}
                  className="mb-md-4"
                >
                  <Row className="bw_blog_post_inner_box">
                    <Col sm={6} md={6} lg={5} className="p-lg-0">
                      <NavLink to={blogurl2}>
                      {
                        blog2.image &&
                        <LazyLoadImage height={200} src={blog2.image} alt="Blog Image" />
                      }
                     
                      </NavLink>
                    </Col>
                    <Col sm={6} md={6} lg={7}>
                      <div className="bw_bpost_box_inner">
                        <h6 className="bw_blog_post_date">
                          {blog2.name}{" "}
                          <span className="bw_post_date">{blog2.date}</span>
                        </h6>
                        <NavLink to={blogurl2}>
                          <h4>{blog2.heading}</h4>
                        </NavLink>
                        <p className="datalimit" dangerouslySetInnerHTML={{ __html: blog2.content }}/>
                        {/* <p dangerouslySetInnerHTML={{ __html: blog2.content }}/> */}
                      </div>
                    </Col>
                  </Row>
                  <Row className="bw_blog_post_inner_box mt-4">
                    <Col sm={6} md={6} lg={5} className="p-lg-0">
                      <NavLink to={blogurl3}>
                      {
                        blog3.image &&
                        <LazyLoadImage src={blog3.image} alt="Blog Image" />
                      }
                      </NavLink>
                    </Col>
                    <Col sm={6} md={6} lg={7}>
                      <div className="bw_bpost_box_inner">
                        <h6 className="bw_blog_post_date">
                          {blog3.name}{" "}
                          <span className="bw_post_date">{blog3.date}</span>
                        </h6>
                        <NavLink to={blogurl3}>
                          <h4>{blog3.heading}</h4>
                        </NavLink>
                        <p className="datalimit" dangerouslySetInnerHTML={{ __html: blog3.content }}/>
                        {/* <p>{blog3.content}</p> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </>
          )}
          <Row className="bw_all_blogpost w-100 m-auto">
            <Col
              className="pl-md-0"
            >
              <h2>{search ? "Search blog posts" : "All blog posts"}</h2>
            </Col>
          </Row>
          <Row className="bw_all_blog_posts w-100 m-auto">
            {filteredBlogs.map((res, index) => (
              <Col sm={12} md={6} lg={4} className="pl-lg-0">
                <div
                  className="bw_all_blog_post_img"
                >
                  <NavLink
                    to={`/blogs/${res.blog_url}`}
                  >
                  {
                    res.image &&
                    <LazyLoadImage height={200} src={res.image} alt="Blog Image" />
                  }
                   
                  </NavLink>
                  <div className="bw_bpost_box_inner">
                    <h6 className="bw_blog_post_date">
                      {res.name}{" "}
                      <span className="bw_post_date">{res.date}</span>
                    </h6>
                    <NavLink
                      to={`/blogs/${res.blog_url}`}
                    >
                      <h4>{res.heading}</h4>
                    </NavLink>
                    <p className="datalimit" dangerouslySetInnerHTML={{ __html: res.content }}/>
                    {/* <p dangerouslySetInnerHTML={{ __html: res.content }} /> */}
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
    </>
  );
};

export default React.memo(Blog);
