import React from "react";
import "../../../Assets/css/diamondJewelryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Row } from "react-bootstrap";
import "../../../Assets/css/plansPricingSmo.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import { NavLink } from "react-router-dom";
import Social1 from "../../../Assets/images/prcing-smo/fb.png";
import Social2 from "../../../Assets/images/prcing-smo/insta.png";
import Social3 from "../../../Assets/images/prcing-smo/pint.png";
import Social4 from "../../../Assets/images/prcing-smo/twitter.png";
import Social5 from "../../../Assets/images/prcing-smo/youtube.png";
import Social6 from "../../../Assets/images/prcing-smo/yelp.png";
import { useHistory } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import CountUp from "react-countup";
function TabPanel(props) {
  const { children, value, valueInner, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Smm = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Belgium WebNet- View Social Media Marketing Pricing</title>
        <meta
          name="description"
          content="Belgium WebNet offers affordable and result-oriented social media marketing services. Our social media experts implement the latest strategies to help you grow. "
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section>
        <div className="bw_pricing_section">
          {/* Banner code Start */}
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                    Social Media Marketing
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          {/* Banner code end */}
          {/* Tab start */}
          <div className="bw_pricing_tabs_section">
            <Container className="bw_custome_container_pricing">
              <div className="bw_pricing_tabs_box bw_only_month_tab text-center">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab label="Monthly" {...a11yProps(0)} />
                  <Tab label="Annually" {...a11yProps(1)} />
                </Tabs>
              </div>
              {/* Monthly tabs containe Start */}
              <TabPanel
                value={value}
                index={0}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                                <CountUp end={150} duration={0} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                <CountUp end={300} duration={0} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center  h-auto">
                                <h6>Titanium</h6>
                                <h4>
                                 <CountUp end={450} duration={0} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td className="bw_prcing_package">
                              Social Media Channels
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social4}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social5}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social6}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Creative Designing
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Content Writing
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Number Of Posts
                            </td>
                            <td>10 Per Month</td>
                            <td>20 Per Month</td>
                            <td>30 Per Month</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Working On Followers Growth
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              FB Pixel Setup
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Story Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Cover Page Management{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Ad Campaign management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Theme Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Business Page Optimization
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Trending Hashtags
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Audience Analysis
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Comments And Like Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Reply To Customers Reviews
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              {" "}
                              Complimentary Ad Campaign
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label="TITANIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                        <CountUp end={150} duration={0} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td>Social Media Channels</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Creative Designing</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Content Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Number Of Posts</td>
                            <td>10 Per Month</td>
                          </tr>
                          <tr>
                            <td>FB Pixel Setup</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Story Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Cover Page Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ad Campaign management</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Theme Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Business Page Optimization</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Trending Hashtags</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Audience Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Comments And Like Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Reply To Customers Reviews</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Report</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Ad Campaign</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                              <CountUp end={300} duration={0} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td>Social Media Channels</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                  className=""
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Creative Designing</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Content Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Number Of Posts</td>
                            <td>20 Per Month</td>
                          </tr>
                          <tr>
                            <td>FB Pixel Setup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Story Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Cover Page Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ad Campaign management</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Theme Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Business Page Optimization</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Trending Hashtags</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Audience Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Comments And Like Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Reply To Customers Reviews</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Report</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Ad Campaign</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>TITANIUM</h6>
                              <h4>
                              <CountUp end={450} duration={0} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td>Social Media Channels</td>
                            <td>
                              <div className="d-md-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social4}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social5}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social6}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Creative Designing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Content Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Number Of Posts</td>
                            <td>30 Per Month</td>
                          </tr>
                          <tr>
                            <td>FB Pixel Setup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Story Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Cover Page Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ad Campaign management</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Theme Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Business Page Optimization</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Trending Hashtags</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Audience Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Comments And Like Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Reply To Customers Reviews</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Report</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Ad Campaign</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
              </TabPanel>
              {/* Monthly tabs containe end */}
              {/* Annual tabs containe start */}
              <TabPanel
                value={value}
                index={1}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_new_mont_hading_box bw_pfeture_text_margin">
                                <h6>
                                  Package
                                  <br /> Features{" "}
                                </h6>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Gold</h6>
                                <h4>
                           <CountUp end={1650} duration={.5} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                               <CountUp end={3300} duration={.5} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Anuum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box text-center h-auto">
                                <h6>Titanium</h6>
                                <h4>
                                <CountUp end={4950} duration={.5} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td className="bw_prcing_package">
                              Social Media Channels
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social4}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social5}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social6}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Creative Designing
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Content Writing
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Number Of Posts
                            </td>
                            <td>10 Per Month</td>
                            <td>20 Per Month</td>
                            <td>30 Per Month</td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Working On Followers Growth
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              FB Pixel Setup
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Story Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Cover Page Management{" "}
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Ad Campaign management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                              Theme Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Business Page Optimization
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Trending Hashtags
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Audience Analysis
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Comments And Like Management
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Reply To Customers Reviews
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              Monthly Report
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                              {" "}
                              Complimentary Ad Campaign
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table anuual tab*/}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="GOLD" {...a11yProps(0)} />
                      <Tab label="PLATINUM" {...a11yProps(1)} />
                      <Tab label="TITANIUM" {...a11yProps(2)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>GOLD</h6>
                              <h4>
                              <CountUp end={1650} duration={.5} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td>Social Media Channels</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Creative Designing</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Content Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Number Of Posts</td>
                            <td>10 Per Month</td>
                          </tr>
                          <tr>
                            <td>FB Pixel Setup</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Story Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Cover Page Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ad Campaign management</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Theme Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Business Page Optimization</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Trending Hashtags</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Audience Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Comments And Like Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Reply To Customers Reviews</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Report</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Ad Campaign</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                               <CountUp end={33500} duration={.5} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td>Social Media Channels</td>
                            <td>
                              <div className="d-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                  className=""
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Creative Designing</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Content Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Number Of Posts</td>
                            <td>20 Per Month</td>
                          </tr>
                          <tr>
                            <td>FB Pixel Setup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Story Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Cover Page Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ad Campaign management</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Theme Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Business Page Optimization</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Trending Hashtags</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Audience Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Comments And Like Management</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Reply To Customers Reviews</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Report</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Ad Campaign</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={2}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>TITANIUM</h6>
                              <h4>
                                 <CountUp end={4950} duration={.5} className="bwn_price_count_up" prefix="$" suffix="/"/><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bw_table_socialImages">
                            <td>Social Media Channels</td>
                            <td>
                              <div className="d-md-flex justify-content-center">
                                <LazyLoadImage
                                  src={Social1}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social2}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social3}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social4}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social5}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                                <LazyLoadImage
                                  src={Social6}
                                  alt="Social Media Image"
                                  className="mb-1"
                                />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Creative Designing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Content Writing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Number Of Posts</td>
                            <td>30 Per Month</td>
                          </tr>
                          <tr>
                            <td>FB Pixel Setup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Story Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Cover Page Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Ad Campaign management</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Theme Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Video Editing</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Business Page Optimization</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Trending Hashtags</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Audience Analysis</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Comments And Like Management</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Reply To Customers Reviews</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Monthly Report</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Festive Posts</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Complimentary Ad Campaign</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
              </TabPanel>
              {/* Annual tabs containe end */}

              {/* Bottom navigation button code start */}
              <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/diamond-jewelry-web-development"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      Website Development Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-seo"
                      className="bw_prcing_btn mb-2"
                    >
                      Search Engine Optimization Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row>
              {/* Bottom navigation button code end */}
            </Container>
          </div>
          {/* Tab end */}
        </div>
      </section>
    </>
  );
};

export default React.memo(Smm);
