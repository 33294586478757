import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import DesignThemeImg1 from "../../Assets/images/design-theme-img/lake-hillier.webp";
import DesignThemeImg4 from "../../Assets/images/design-theme-img/devils-marble.webp";
import DesignThemeImg6 from "../../Assets/images/design-theme-img/galata-tower.webp";
import DesignThemeImg7 from "../../Assets/images/design-theme-img/grand-canyon.webp";
import DesignThemeImg9 from "../../Assets/images/design-theme-img/hanging-garden.webp";
import DesignThemeImg11 from "../../Assets/images/design-theme-img/lake-retba.webp";
import DesignThemeImg12 from "../../Assets/images/design-theme-img/marble-caves.webp";
import DesignThemeImg13 from "../../Assets/images/design-theme-img/mount-bromo.webp";
import DesignThemeImg15 from "../../Assets/images/design-theme-img/shilin-stone.webp";
import DesignThemeImg18 from "../../Assets/images/design-theme-img/havana.webp";
import DesignThemeImgh1 from "../../Assets/images/design-theme-img/hair-salon/h1.webp";
import DesignThemeImgh2 from "../../Assets/images/design-theme-img/hair-salon/h2.webp";
import DesignThemeImgh3 from "../../Assets/images/design-theme-img/hair-salon/h3.webp";
import DesignThemeImgh4 from "../../Assets/images/design-theme-img/hair-salon/h4.webp";
import DesignThemeImgh5 from "../../Assets/images/design-theme-img/hair-salon/h5.webp";
import LeftIcon from "../../Assets/images/home/left_icon.png";
import RightIcon from "../../Assets/images/home/right_arrow.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { NavLink } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";

const ProjectSlider = () => {
  const Data = [
    {
      content:
        "Newyork is an effective, unique, custom-designed theme with a clear, informative hierarchy that showcases relevant content to smoothen your online shopping experience. ",
      name: "New York",
      image: `${DesignThemeImg1}`,
      url: "https://newyork.demobw.com/",
    },

    {
      content:
        "Choosing a pre-designed theme is a hassle-free task. The Macau theme ensures your website and the featuring products look unique and captivating.",
      name: "Macau",
      image: `${DesignThemeImg4}`,
      url: "https://macau.demobw.com/",
    },
    {
      content:
        "Design is the pioneering feature to consider when one chooses a theme for a website. Different people use different mobile phones, laptops, or tablets to view a website.  ",
      name: "London",
      image: `${DesignThemeImg13}`,
      url: "https://london.demobw.com/",
    },
    {
      content:
        "Paris is a powerful theme having a distinctive and creative touch. The design facilitates the easy showcasing of products and services for users.  ",
      name: "Paris",
      image: `${DesignThemeImg11}`,
      url: "https://paris.demobw.com/",
    },

    {
      content:
        "Dubai is a tailored theme that exhibits a perfect concoction of radiant and subtle arts. A design that does not just offer a beautiful outlook to the site but an equally smooth shopping experience. ",
      name: "Dubai",
      image: `${DesignThemeImg6}`,
      url: "https://dubai.demobw.com/",
    },
  
    {
      content:
        "Our latest theme is social media-friendly. In today’s scenario, social media is the most powerful tool to attract customers and connect with them.  ",
      name: "Venice",
      image: `${DesignThemeImg12}`,
      url: "https://venice.demobw.com/",
    },
    {
      content:
        "The Rome theme is one of the most user-friendly website themes. It has minimal creations with the best of the kind imagery, backgrounds, simple fonts, vivid colors, and a pleasant layout. ",
      name: "Rome",
      image: `${DesignThemeImg7}`,
      url: "https://rome.demobw.com/",
    },
   
    {
      content:
        "Tokyo is a strong and fully responsive jewelry website design.  ",
      name: "Tokyo",
      image: `${DesignThemeImg15}`,
      url: "https://tokyo.demobw.com/",
    },
    {
      content:
        " Begin something fresh with our Miami theme! The Miami theme is impressive and easy to use. ",
      name: "Miami",
      image: `${DesignThemeImg9}`,
      url: "https://miami.demobw.com/",
    },
   
    {
      content: "Havana is all about showcasing your best. If you believe you can flaunt your artists and services, this theme is just the right match for your business.    ",
      name: "Havana",
      image: `${DesignThemeImg18}`,
      url: "https://havana.demobw.com/",
    },
  ];
  const project = {
    className: "center",
    infinite: false,
    Nav: true,
    slidesToShow: 2.5,
    speed: 500,
    rows: 1,
    slidesPerRow: 1,
    nextArrow: <LazyLoadImage src={RightIcon} alt="Right Icon" />,
    prevArrow: <LazyLoadImage src={LeftIcon} alt="Left Icon" />,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1.8,
          slidesToScroll: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          speed: 400,
          autoplay: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.5,
          slidesToScroll: 1,
          speed: 400,
          autoplay: true,
        },
      },
    ],
  };
  return (
    <>
      <section className="bw_project_slider_section">
        <Container fluid>
          <Row>
            <Col
              xl={3}
              lg={12}
              md={12}
              xs={12}
              className="offset-md-0 offset-lg-0 offset-xl-1"
            >
              <div className="bw_project_slider_text">
                <h2>Our Designs</h2>
                <p>
                  Custom layout of your website that translates the brand tone
                  of your business. We offer a variety of themes that are
                  smoothly maintained and well-designed. Contact us for an
                  investment that matches your brand and one that lasts forever.
                </p>
                <div className="bw_btb_border_black mt-2 bwn_desktop_view_all_btn">
                  <NavLink
                    to="/diamond-jewelry-designs"
                    className="text-decoration-none bw_custom_btn"
                  >
                    View All
                  </NavLink>
                </div>
              </div>
            </Col>
            <Col xl={8} lg={12} md={12} xs={12} className="pr-md-0 pl-md-0">
              <div
                className="bw_brand_slider_right_slide_main bw_project_slider_main"
              >
                <Slider {...project}>
                  {Data.map((res, ind) => (
                    <Col key={ind}>
                      <NavLink to="#" onClick={() => window.open(res.url, "_blank")}>
                        <div className="bw_project_slider_item">
                          <div className="bw_product_img_outer_box">
                            <LazyLoadImage
                              src={`${res.image}`}
                              alt="Product Image"
                            />
                          </div>
                          <div className="bw_project_slider_item_text">
                            <div className="bw_project_slider_item_text_title">
                              <h4> {res.name} </h4>
                            </div>
                            <div className="bw_project_slider_item_text_paragraph">
                              <p>{res.content}</p>
                            </div>
                          </div>
                        </div>
                      </NavLink>
                    </Col>
                  ))}
                </Slider>
              </div>
              <div className="text-center bwn_view_all_mobile_btn">
                <div className="bw_btb_border_black mt-2">
                  <NavLink
                    to="/diamond-jewelry-designs"
                    className="text-decoration-none bw_custom_btn"
                  >
                    View All
                  </NavLink>
                </div>
              </div>

            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default React.memo(ProjectSlider);
