import React from 'react'
import { Row, Col, Container } from "react-bootstrap";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Link } from "react-router-dom"
import "../../Assets/css/ui.css"
import PlanImg from "../../Assets/images/social-img/pricing.webp"
export default function PlanCard() {
    return (
        <section className="bg-blue planCard">
            <Container className='h-100'>
                <Row className='text-white h-100'>
                    <Col lg={6} className='my-auto text-center text-lg-left'>
                        <h3 className='fs-20 fw-semibold text-white'>Get Personalized Social Media Marketing plan,</h3>
                        <h2 className='mb-3'> Starting at just  <span className='fw-900'> $250/ </span> per month. </h2>
                        <Link to="/plans-pricing-smo">
                            <button className='btn-outline-white'> Explore Our Pricing Plans </button>
                        </Link>
                    </Col>
                </Row>
                <div className='imgBox image-cover d-none d-lg-block'>
                    <LazyLoadImage
                        src={PlanImg}
                        alt="Web Development"
                        className="img-fluid mb-xs-2"
                    />
                </div>
            </Container>
        </section>
    )
}


