
import React, { useReducer, useRef, useEffect, useState, useMemo } from "react";
import Globe from 'react-globe.gl';
// import * as XLSX from 'xlsx';
// import countries from './countries'
import backimg from './images/whiteback.png'
import globimg from './images/whiteglobimg.png'
import logo from './images/Location-bw1.png'
// import * as d3 from "https://cdn.jsdelivr.net/npm/d3@7/+esm";
import countries from "./countries";
import { pointData, wc_data_static } from "./dotdata";
import { Button, Col, Container, Row } from "react-bootstrap";
import '../Assets/css/globeStyle.css'
import { useHistory } from "react-router-dom";
const initialState = "unloaded";

function reducer(state, action) {
  switch (action.type) {
    case 'load':
      return "loaded";
    default:
      return state;
  }
}
function GlobeComponent() {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [places, setPlaces] = useState([]);
  const [arcs, setArcs] = useState();
  const history = useHistory()
  const [hoverD, setHoverD] = useState();

  useEffect(() => {
    (async () => {
      if (state === "unloaded") {
        var lon_ = []
        var lat_ = []
        var pop = []
        var cities = []
        var places_ = [];
        wc_data_static.map(data => {
          lon_.push(data[2]);
          lat_.push(data[1]);
          pop.push("0.25");
          cities.push(data[0]);
          return 1;
        });
        lon_.shift();
        lat_.shift();
        pop.shift();
        cities.shift();
        cities.forEach((city, index) => {
          places_.push({
            lat: lat_[index],
            lng: lon_[index],
            size: pop[index],
            city: city

          }
          )
        });

        var arc_start_lat = [];
        var arc_start_lon = [];
        var arc_end_lon = [];
        var arc_end_lat = [];
        var start_city = [];
        var end_city = [];
        var _currentTransaction = ""
        pointData.map(data => {
          if (_currentTransaction === data[0]) {
            if (data[1] === "end") {
              end_city.push(data[2]);
              arc_end_lat.push(data[3]);
              arc_end_lon.push(data[4]);
            }
          } else {
            if (data[1] === "start") {
              start_city.push(data[2]);
              arc_start_lat.push(data[3]);
              arc_start_lon.push(data[4]);
              _currentTransaction = data[0];
            }
          }
          return 1;


        });
        var transaction = [];
        end_city.forEach((city, index) => {
          transaction.push({
            startlat: arc_start_lat[index],
            startlng: arc_start_lon[index],
            endlat: arc_end_lat[index],
            endlng: arc_end_lon[index],
            label: start_city[index] + " to " + city
          }
          )
        });
        setArcs(transaction);
        setPlaces(places_);
        dispatch({ type: "load" });
      }
      else if (state === "loaded") {

      }

    })();
  });

  const globeEl = useRef()
  useEffect(() => {
    // Auto-rotate
    globeEl.current.controls().autoRotate = true;
    globeEl.current.controls().autoRotateSpeed = 3;
    globeEl.current.controls().enableZoom = false;
    globeEl.current.pointOfView({
      lat: 49.38,
      lng: 41.0,
      altitude: 2.5,
    })
  }, [])
  // const colorScale = d3.scaleSequentialSqrt(d3.interpolateYlOrRd);
  // const maxVal = useMemo(
  //   () => Math.max(...countries.features.map()),
  //   [countries]
  // );
  // colorScale.domain([0, maxVal]);
  const OPACITY = 0.8;
  return <>
    <div className="bw_globel_section">
      <Container className="bw_custome_container ">
        <Row className="w-100 m-auto align-items-center">
          <Col xl={6} lg={6} md={6} sm={12} xs={12}>
            <div
              className="bw_about_comany_title_text"
            >

              <h2>Our Clients Around The World </h2>
              <p>
                Belgium WebNet's success stems from its highly skilled technology and professionals, with extensive solutions and sector expertise, working closely with clients onshore and offshore.
                We have been in the market for over 3 years, and our services are commercialized in 4 major countries worldwide, including the USA, the UK, Dubai & India.
              </p>

              <div className="bw_btb_border_black">
                <Button className="bw_custom_btn" onClick={() => history.push("/contacts")}>
                  Contact Us
                </Button>
              </div>


            </div>
          </Col>
          <Col xs={12} sm={12} md={6} lg={6} xl={6} className="BW_mapGloble">
            <div className="innerGlobeWrp">
              <Globe
                zoomOnScroll={false}
                className="bw_globe_home_page"
                ref={globeEl}
                globeImageUrl={globimg}
                // backgroundImageUrl={backimg}
                backgroundColor="#0000"
                width={900}
                height={600}
                animateIn={true}
                showAtmosphere={true}
                // arcsData={arcs}
                // arcStartLat={d => +d.startlat}
                // arcStartLng={d => +d.startlng}
                // arcEndLat={d => +d.endlat}
                // arcEndLng={d => +d.endlng}
                // arcDashLength={1}
                // arcDashGap={0.4}
                // arcLabel={d=>+d.label}
                // arcDashInitialGap={() => Math.random()}
                // arcDashAnimateTime={3000}
                // arcColor={d => [`rgba(0,0,255, ${OPACITY})`, `rgba(255, 0, 0, ${OPACITY})`]}
                // arcColor={() => "red"}
                // arcsTransitionDuration={0}
                // arcStroke={0.3}
                // pointsData={places.slice(0,20000)}
                // pointColor={() => "white"}
                // pointAltitude={.01}
                polygonsData={countries.features.filter(d => d.properties.ISO_A2 !== 'AQ')}
                polygonLabel={({ properties: d }) => `
                          <div className="Tooltip_glb">
                          <img src=${logo} ></img>
                          <br />
                          <b className="bg-dark">${d.ADMIN} </b> 
                          </div>
                        `}
                polygonStrokeColor={() => "white"}
                polygonSideColor={() => '#214190'}
                polygonAltitude={d => d === hoverD ? 0.04 : 0.008}
                polygonCapColor={() => '#214190'}
                pointLabel={"city"}
                pointRadius={2}
                onPolygonClick={() => globeEl.current.controls().autoRotate = false}         //----------- Auto REotation off
                onPolygonHover={setHoverD}
                polygonCapCurvatureResolution={8}
                polygonsTransitionDuration={200}
              // hexPolygonsData={countries.features}
              // arcAltitude={0.06}
              // hexPolygonResolutio={12}
              // hexPolygonMargin={0}
              // hexPolygonColor={() => `#${Math.round(Math.random() * Math.pow(2, 24)).toString(16).padStart(6, '2')}`}
              // hexPolygonColor={() =>"#597dff"}
              //   hexPolygonLabel={({ properties: d }) => `
              //   <b>${d.ADMIN} </b> <br />
              //    <img src=${logo}></img>
              // `}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>

  </>
}
export default GlobeComponent