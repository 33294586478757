import React, { useState } from "react";
// import "../../Assets/css/diamondJewelleryWebDevelopment.css";
import Helmet from "react-helmet";
import { Button, Col, Container, Image, Row } from "react-bootstrap";
// import "../../Assets/css/plansPricingSeo.css";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "react-bootstrap/Table";
import { FiCheck } from "react-icons/fi";
import ClearIcon from "@mui/icons-material/Clear";
import CountUp from "react-countup";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';

function TabPanel(props) {
  const { children, value, valueInner, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  valueInner: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const B2CGBP = () => {
  const history = useHistory();
  const handleClick = () => history.push("/package-form");

  const [value, setValue] = React.useState(0);
  const [valueInner, setValueInner] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const handleChangenew = (event, newValueInner) => {
    setValueInner(newValueInner);
  };
  const [currency ,setCurrency] = useState("GBP");

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
        Best B2B Website Development Company | Belgium WebNet
        </title>
        <meta
          name="description"
          content="Subscribe Belgium WebNet's B2B website development solutions and expertise to grow your jewelry business with impactful results. Schedule an appointment now! "
        ></meta>
        <meta name="keywords" content="b2b website development company"></meta>
      </Helmet>
      <section>
        <div className="bw_pricing_section">
          <div className="bw_all_banner bw_banner_pricing">
            <Container className="bw_custome_container">
              <Row className="w-100 m-auto">
                <Col>
                  <h1 className="bw_banner_heading">
                B2B Website Development
                  </h1>
                  <p className="bw_banner_sub_heading">
                    Choose a plan to avail our services and get started with
                    your transformation journey today.
                  </p>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="bw_pricing_tabs_section">
            <Container className="bw_custome_container_pricing position-relative">
             
              <div className="bw_pricing_tabs_box bw_only_month_tab text-center">
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                  centered
                >
                  <Tab label="Monthly" {...a11yProps(0)}/>
                  <Tab label="Annually" {...a11yProps(1)} />
                </Tabs>
              </div>
              <div className="bwn_first_month_free_text text-center">
                  <div className="position-relative">
                        <button className="bwn_get_on_month_free_button">Annually Get 1 Month Free</button>
                        {/* <Image src={ArrowImpImg}></Image> */}
                  </div>
              </div>
              {/* Montly tab containe start */}
            {/* {window.location.pathname.includes("-gbp") ? <Row className="w-100 m-0">
               <Col xl={1} lg={2} md={12} xs={10} className="inr_input_main">
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Currency</InputLabel>
                    <Select
                      size={"small"}
                      inputProps={{ MenuProps: { disableScrollLock: true } }}
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={currency}
                      label="Currency"
                      onChange={(e) => setCurrency(e.target.value)}
                    >
                      <MenuItem value={"INR"}>INR</MenuItem>
                      <MenuItem value={"USD"}>USD</MenuItem>
                      <MenuItem value={"GBP"}>GBP</MenuItem>
                    </Select>
                  </FormControl>

                </Col>

              </Row> : ""} */}
              <TabPanel
                value={value}
                index={0}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                <h6>
                                  Package <br /> Features{" "}
                                </h6>
                              </div>
                            </th>

                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                 <CountUp end={ window.location.pathname.includes("-gbp") && currency == "INR"? 17500 : currency == "GBP" ? 199 : 250} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹" : currency == "GBP" ? "£" : "$"} suffix="/"/><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹100000" : currency == "GBP" ? "£1499": "$1999"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                                    <CountUp end={window.location.pathname.includes("-gbp") && currency == "INR"  ? 20000 : currency == "GBP" ? 275: 350} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹" : currency == "GBP" ? "£" :"$"} suffix="/"/><span>Month</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{ window.location.pathname.includes("-gbp") && currency == "INR"  ? " ₹125000" : currency == "GBP" ? "£2299":"$2999"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                            Custom Homepage Design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*

                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             Jewellery supplier's inventory integration*
                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                                <div>
                                3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             Memo/Hold stones highlight
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Multi Language</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                           Multi Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">B2B Dashboard after login</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">B2B Jewellery search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Wishlist & Cart
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Payment Gateway integration*  </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                              1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Single Jewellery upload via admin panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                        Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Sign up approval feature for website user
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Price Visibility on Login/Signup
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Blog Page</td>
                            <td>
                             <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Social Media feed Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                           <tr className="border-bottom">
                            <td className="bw_prcing_package">Reviews Integration</td>
                            <td>
                              <div>
                                Offline
                              </div>
                            </td>
                            <td>
                              <div>
                               Google & Offline 
                              </div>
                            </td>
                          </tr>
                           <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud Backup</td>
                            <td>
                              <div>
                                  Monthly
                              </div>
                            </td>
                            <td>
                             <div>
                                    Bi-Monthly
                              </div>
                            </td>
                          </tr>
                           <tr className="border-bottom">
                            <td className="bw_prcing_package">SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      
                      </Table>
                    </Col>
                  </Row>

                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="PLATINUM" {...a11yProps(0)} />
                      <Tab label="RHODIUM" {...a11yProps(1)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                               <CountUp end={ window.location.pathname.includes("-gbp") && currency == "INR"  ? 17500 : currency == "GBP" ? 199 :250} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹": currency == "GBP" ? "£" :"$"} suffix="/"/><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Setup</td>
                            <td>{window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹100000" : currency == "GBP" ? "£1499" :"$1999"}</td>
                          </tr>
                          <tr>
                            <td>Custom Homepage Design</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                           Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*
                            </td>
                            <td>
                              {" "}
                              <div>
                              1 Supplier

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Jewellery supplier's inventory integration*</td>
                            <td>
                              <div>
                                 1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Memo/Hold stones highlight</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Language</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Currency</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Dashboard after login</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Jewellery search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Wishlist & Cart</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Gateway integration*</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Single Jewellery upload via admin panel</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Sign up approval feature for website user</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Price Visibility on Login/Signup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Blog Page</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Social Media feed Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Reviews Integration</td>
                            <td>
                              <div>
                               Offline
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Website Cloud Backup</td>
                            <td>
                              <div>
                              Monthly
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM</h6>
                              <h4>
                             <CountUp end={window.location.pathname.includes("-gbp") && currency == "INR"  ? 20000 : currency == "GBP" ? 275:350} duration={0} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹": currency == "GBP" ? "£":"$"} suffix="/"/><span>Month</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Setup</td>
                            <td>{window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹125000" : currency == "GBP" ? "£2299": "$2999"}</td>
                          </tr>
                          <tr>
                            <td>Custom Homepage Design</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                           Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*
                            </td>
                            <td>
                              <div>
                                  3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Jewellery supplier's inventory integration*</td>
                            <td>
                              <div>
                                 3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Memo/Hold stones highlight</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Language</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Currency</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Dashboard after login</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Jewellery search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Wishlist & Cart</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Gateway integration*</td>
                            <td>
                              <div>
                              1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Single Jewellery upload via admin panel</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Sign up approval feature for website user</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Price Visibility on Login/Signup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Blog Page</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Social Media feed Integration</td>
                            <td>
                              <div>
                               Instagram
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Reviews Integration</td>
                            <td>
                              <div>
                             Google & Offline
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Website Cloud Backup</td>
                            <td>
                              <div>
                             Bi-Monthly
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                 <Row>
                <Col>
                
                   <ul className="bwn_b2b_note_container bw_green_djdinr_list_style ">
                      <li>For any additional Diamonds/Gemstones/Fancy Color Stones Supplier Integration One Time Charge will be {window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹15000" :"£199"} Each.</li>
                      <li>For any additional Jewellery Supplier Integration One Time Charge will be {window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹25000" :"£399"} Onwards.</li>
                      <li>For any additional Payment Gateway Integration One Time Charge will be {window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹15000" :"£199"} Each.</li>
                       <li>In House Inventory will be considered as one supplier Your Chosen Plan.</li>
                       <li>Particulars like Rapnet, VDB, Polygon, Idex and Get Diamonds will be cosidered as one supplier each as per your plan.  </li>
                       {window.location.pathname.includes("-gbp") && currency == "INR"  ? <li>Above prices are exclusive of GST.</li> : '' }
                   </ul>
                </Col>
              </Row>
              {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/plans-pricing-seo"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      SEO Lead Generation Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Optimization Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
              </TabPanel>
              {/* Montly tab containe end */}
              {/* Annual tab containe start */}
               <TabPanel
                value={value}
                index={1}
                className="bw_monthly_tab_outer_box"
              >
                <Container className="bw_custome_container_pricing bw_pricing_tab_desktopview">
                  <Row className="w-100 m-auto">
                    <Col className="px-0">
                      <Table className="bw_pricing_montly_table">
                        <thead>
                          <tr className="border-top-0">
                            <th className="text-uppercase bw_pricing_package_heading align-middle w-20">
                              <div className="bw_mont_inner_heading_box bw_hair_salone_headingbox">
                                <h6>
                                  Package <br /> Features{" "}
                                </h6>
                              </div>
                            </th>

                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border text-center">
                                <h6>Platinum</h6>
                                <h4>
                                 <CountUp end={ window.location.pathname.includes("-gbp") && currency == "INR"  ? 192500 : currency == "GBP"  ? 2189   :2750} duration={.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹" : currency == "GBP" ? "£" :"$"} suffix="/"/><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  START
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹100000" : currency == "GBP" ? "£1499" : "$1999"}</h4>
                              </div>
                            </th>
                            <th className="bw_pricing_package_heading_lower text-center  w-20">
                              <div className="bw_mont_price_box_inner_box bw_pricing_upperbox_border  h-auto">
                                <h6>Rhodium</h6>
                                <h4>
                       <CountUp end={window.location.pathname.includes("-gbp") && currency == "INR"  ? 220000 : currency == "GBP" ? 3025 : 3850} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹" : currency == "GBP" ? "£":"$"} suffix="/"/><span>Annum</span>
                                </h4>
                                <Button
                                  className="bw_btn_price_start"
                                  onClick={handleClick}
                                >
                                  STARt
                                </Button>
                                <p>One-Time Setup Fees </p>
                                <h4>{window.location.pathname.includes("-gbp") && currency == "INR" ? "₹125000" : currency == "GBP" ? "£2299" : "$2999"}</h4>
                              </div>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="bw_prcing_package">
                            Custom Homepage Design
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                            Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*

                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                              <div>
                                3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             Jewellery supplier's inventory integration*
                            </td>
                            <td>
                              <div>
                                1 Supplier
                              </div>
                            </td>
                            <td>
                                <div>
                                3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             Memo/Hold stones highlight
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">Multi Language</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                           Multi Currency
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">B2B Dashboard after login</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package">
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">B2B Jewellery search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Wishlist & Cart
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Payment Gateway integration*  </td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                              1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Single Jewellery upload via admin panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                        Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                             Sign up approval feature for website user
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">
                            Price Visibility on Login/Signup
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Blog Page</td>
                            <td>
                             <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Social Media feed Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                            <td>
                              <div>
                                Instagram
                              </div>
                            </td>
                          </tr>
                           <tr className="border-bottom">
                            <td className="bw_prcing_package">Reviews Integration</td>
                            <td>
                              <div>
                                Offline
                              </div>
                            </td>
                            <td>
                              <div>
                               Google & Offline 
                              </div>
                            </td>
                          </tr>
                           <tr className="border-bottom">
                            <td className="bw_prcing_package">Website Cloud Backup</td>
                            <td>
                              <div>
                                  Monthly
                              </div>
                            </td>
                            <td>
                             <div>
                                    Bi-Monthly
                              </div>
                            </td>
                          </tr>
                           <tr className="border-bottom">
                            <td className="bw_prcing_package">SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr className="border-bottom">
                            <td className="bw_prcing_package">Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                        <tbody className="mt-2 border bwn_b2b_new_particul_table">
                          <tr>
                            <td className="bw_prcing_package font-weight-bold">
                                Particular
                            </td>
                            <td className="font-weight-bold" colspan="2">
                              <div>
                                 One Time Charge
                              </div>
                            </td>
                            
                          </tr>
                          <tr>
                            <td className="bw_prcing_package font-weight-bold">
                                For any additional Diamonds/Gemstones/Fancy Color Stones Supplier Integration
                            </td>
                            <td className="font-weight-bold" colspan="2">
                              <div>
                              $250 Each
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package font-weight-bold">
                                For any additional Diamonds/Gemstones/Fancy Color Stones Supplier Integration
                            </td>
                            <td className="font-weight-bold" colspan="2">
                              <div>
                              $250 Each
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package font-weight-bold">
                              For any additional Jewellery Supplier Integration
                            </td>
                            <td className="font-weight-bold" colspan="2">
                              <div>
                            $500 Onwards
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td className="bw_prcing_package font-weight-bold">
                               For any additional Payment Gateway Integration
                            </td>
                            <td colspan="2">
                              <div className="font-weight-bold">
                          $250 Each
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                </Container>
                <Container className="bw_custome_container_pricing">
                  {/* Mobile View of Table */}
                  <div className="bw_djwd_table_mobileview bw_prcing_seo">
                    <Tabs
                      value={valueInner}
                      onChange={handleChangenew}
                      aria-label="simple tabs example"
                      centered
                      className="mb-2 mb-sm-2"
                    >
                      <Tab label="PLATINUM" {...a11yProps(0)} />
                      <Tab label="RHODIUM" {...a11yProps(1)} />
                    </Tabs>

                    <TabPanel value={valueInner} index={0}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>PLATINUM</h6>
                              <h4>
                               <CountUp end={window.location.pathname.includes("-gbp") && currency == "INR"  ? 192500 : currency == "GBP"  ? 2189  :2750} duration={0.5} className="bwn_price_count_up" prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹" : currency == "GBP" ? "£" :"$"} suffix="/"/><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Setup</td>
                            <td>{ window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹100000" : currency == "GBP" ? "£1499" :"$1999"}</td>
                          </tr>
                          <tr>
                            <td>Custom Homepage Design</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                           Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*
                            </td>
                            <td>
                              {" "}
                              <div>
                              1 Supplier

                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Jewellery supplier's inventory integration*</td>
                            <td>
                              <div>
                                 1 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Memo/Hold stones highlight</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Language</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Currency</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Dashboard after login</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Jewellery search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Wishlist & Cart</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Gateway integration*</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Single Jewellery upload via admin panel</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Sign up approval feature for website user</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Price Visibility on Login/Signup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Blog Page</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Social Media feed Integration</td>
                            <td>
                              <div className="bw_pricing_checkicon bw_bg_light_cancel">
                                <ClearIcon />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Reviews Integration</td>
                            <td>
                              <div>
                               Offline
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Website Cloud Backup</td>
                            <td>
                              <div>
                              Monthly
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                    <TabPanel value={valueInner} index={1}>
                      <Table bordered hover responsive className="mt-2 mb-3">
                        <thead>
                          <tr>
                            <th className="bw_table_had_bgColor_light w-50">
                              <h4 className="bw_table_column1_head">
                                PACKAGE <br></br> FEATURES
                              </h4>
                            </th>
                            <th className="bw_table_had_bgColor w-50">
                              <h6>RHODIUM</h6>
                              <h4>
                             <CountUp end={window.location.pathname.includes("-gbp") && currency == "INR"  ? 220000 : currency == "GBP" ? 3025 : 3850} duration={0.5} className="bwn_price_count_up"prefix={window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹" : currency == "GBP" ? "£" :"$"} suffix="/"/><span>Annum</span>
                              </h4>
                              <Button
                                className="bw_btn_price_start bw_pricing_btn_white"
                                onClick={handleClick}
                              >
                                STARt
                              </Button>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Setup</td>
                            <td>{window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹125000" : currency == "GBP" ? "£2299" :"$2999"}</td>
                          </tr>
                          <tr>
                            <td>Custom Homepage Design</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                           Diamond/Gemstone/Fancy Color Stone supplier's live inventory integration*
                            </td>
                            <td>
                              <div>
                                  3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Jewellery supplier's inventory integration*</td>
                            <td>
                              <div>
                                 3 Supplier
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Memo/Hold stones highlight</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Outbound API of website inventory</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Language</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Multi Currency</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Dashboard after login</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                             B2B Diamond search & product page
                            </td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>B2B Jewellery search & product page</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Wishlist & Cart</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Payment Gateway integration*</td>
                            <td>
                              <div>
                              1 Gateway
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Single Jewellery upload via admin panel</td>
                            <td>
                             <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Bulk Diamonds/Gemstones/Fancy Color Stones upload via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Tier Wise Markup on Diamonds/Gemstones/Fancy Color Stones via Admin Panel</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Sign up approval feature for website user</td>
                            <td>
                              {" "}
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Price Visibility on Login/Signup</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Blog Page</td>
                            <td>
                               <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>Social Media feed Integration</td>
                            <td>
                              <div>
                               Instagram
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Reviews Integration</td>
                            <td>
                              <div>
                             Google & Offline
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Website Cloud Backup</td>
                            <td>
                              <div>
                             Bi-Monthly
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>SSL Certificate</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                           <tr>
                            <td>Dedicated Project Manager</td>
                            <td>
                              <div className="bw_pricing_checkicon">
                                <FiCheck />
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    </TabPanel>
                  </div>
                  {/* Mobile View of Table ending */}
                </Container>
                 <Row>
                <Col>
                    <ul className="bwn_b2b_note_container bw_green_djdinr_list_style ">
                      <li>For any additional Diamonds/Gemstones/Fancy Color Stones Supplier Integration One Time Charge will be {window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹15000" :"$250"} Each.</li>
                      <li>For any additional Jewellery Supplier Integration One Time Charge will be {window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹25000" :"$500"} Onwards.</li>
                      <li>For any additional Payment Gateway Integration One Time Charge will be {window.location.pathname.includes("-gbp") && currency == "INR"  ? "₹15000" :"$250"} Each.</li>
                       <li>In House Inventory will be considered as one supplier Your Chosen Plan.</li>
                       <li>Particulars like Rapnet, VDB, Polygon, Idex and Get Diamonds will be cosidered as one supplier each as per your plan.  </li>
                       {window.location.pathname.includes("-gbp") && currency == "INR"  ? <li>Above prices are exclusive of GST.</li> : '' }
                   </ul>
                </Col>
              </Row>
              {/* <Row className="w-100 m-auto">
                <Col>
                  <div className="bw_prcing_btn_box">
                    <NavLink
                      to="/plans-pricing-seo"
                      className="bw_prcing_btn mr-md-3 mb-2"
                    >
                      SEO Lead Generation Pricing
                    </NavLink>
                    <NavLink
                      to="/plans-pricing-smo"
                      className="bw_prcing_btn mb-2"
                    >
                      Social Media Optimization Pricing
                    </NavLink>
                  </div>
                </Col>
              </Row> */}
              </TabPanel>
              {/* Annual tab containe end */}
             
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(B2CGBP);
